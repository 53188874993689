import React, { useEffect } from "react";
import { Select, Button } from "semantic-ui-react";
import { getCurrentReportingPeriod } from "../../../../utils";

const DashboardPeriodSelect = ({
    onPeriodSelected,
    chosenPeriod,
    allowFutureSelection = false,
    noMonths = false,
}) => {
    const [frmYear, setFrmYear] = React.useState(null);
    const [frmPeriod, setFrmPeriod] = React.useState(null);
    const [frmPeriodCurYear, setFrmPeriodCurYear] = React.useState(null);
    const [curPeriod] = React.useState(getCurrentReportingPeriod());

    let PERIODS = [
        "M01",
        "M02",
        "M03",
        "Q1",
        "M04",
        "M05",
        "M06",
        "Q2",
        "M07",
        "M08",
        "M09",
        "Q3",
        "M10",
        "M11",
        "M12",
        "Q4",
    ];

    if (noMonths) {
        PERIODS = PERIODS.filter((i) => i[0] === "Q");
    }

    /* ------------------------------- */
    let years = [];
    let yearmax = new Date().getFullYear() + 1;

    if (allowFutureSelection) yearmax += 10;

    for (let i = 2013; i < yearmax; i++) {
        years.push({ key: i, value: "" + i, text: "" + i });
    }

    let periods = null;
    let periods_cur_year = [];

    // generate the list for periods outside of this year (so all of them)
    periods = PERIODS.map((p) => {
        return { key: p, value: p, text: p };
    });

    // generate the list that only contains the periods for the current year
    const cur_period_index = PERIODS.indexOf(curPeriod.reporting_period);

    PERIODS.map((p, idx) => {
        if (idx > cur_period_index) return;
        periods_cur_year.push({ key: p, value: p, text: p });
    });

    useEffect(() => {
        if (chosenPeriod) {
            setFrmYear(chosenPeriod.reporting_year);
            setFrmPeriod(chosenPeriod.reporting_period);
            setFrmPeriodCurYear(chosenPeriod.reporting_period);
        }
    }, [chosenPeriod]);

    /* ------------------------------- */
    function onYearChange(e, i) {
        setFrmYear(i.value);
    }

    /* ------------------------------- */
    function onPeriodChange(e, i) {
        setFrmPeriod(i.value);
    }

    /* ------------------------------- */
    function onChangePeriod() {
        onPeriodSelected(frmYear, frmPeriod);
    }

    /* ------------------------------- */
    function onClearPeriod() {
        onPeriodSelected(undefined, undefined);
    }

    /* ------------------------------- */
    return (
        <div className="intervention-period-select">
            <div className="label">
                <label>Reporting Period</label>
            </div>
            <div className="controls">
                <Select
                    options={years}
                    value={frmYear}
                    onChange={onYearChange}
                    placeholder="Year"
                    fluid
                />
                <Select
                    options={
                        frmYear === curPeriod.reporting_year &&
                        !allowFutureSelection
                            ? periods_cur_year
                            : periods
                    }
                    value={frmPeriod}
                    onChange={onPeriodChange}
                    placeholder="Period"
                    fluid
                />
                <Button
                    color="violet"
                    icon="right arrow"
                    title="Change Reporting Period"
                    disabled={!(frmPeriod && frmYear)}
                    onClick={onChangePeriod}
                />
                <Button
                    icon="x"
                    title="Reset Reporting Period"
                    disabled={!(frmPeriod && frmYear)}
                    onClick={onClearPeriod}
                />
            </div>
        </div>
    );
};

export default DashboardPeriodSelect;
