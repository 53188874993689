import { AxiosInstance } from "./index";

/********************************************************************
 * Cashes in the old token in exchange for a new one; used with the
 * token keepalive heartbeat.
 */
const list = async () => {
    const response = await AxiosInstance.get("/v1/roles");
    return response;
};

export default {
    list,
};
