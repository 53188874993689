import React, { createContext, useReducer } from "react";

// eslint-disable-next-line
import { Auth } from "../api"; //MAGIC
import { tokenKeepAlive } from "../api/jwt";

const initialState = {
    errorMessage: null,
    showUserLoginDialog: false,
};

const AppContext = createContext(initialState);

const { Provider } = AppContext;

const _appReducer = (state, action) => {
    switch (action.type) {
        case "show-user-login-dialog-timeout":
            clearTimeout(tokenKeepAlive);

            alert("Your login session has expired. Please log in again.");

            return {
                ...state,
                showUserLoginDialog: action.payload,
            };

        case "show-user-login-dialog":
            return {
                ...state,
                showUserLoginDialog: action.payload,
            };

        case "error-show":
            return {
                ...state,
                errorMessage: action.payload,
            };

        case "error-clear":
            return {
                ...state,
                errorMessage: null,
            };

        default:
            throw new Error(`Unknown app context reducer: "${action.type}"`);
    }
};

let appDispatch = null;

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(_appReducer, initialState);

    appDispatch = dispatch;
    /**
     * Displays a one-button alert box to the user; available globally
     * @param {string} message Message to relay to the user.
     * @param {string} header Custom header; defaults to "Error"
     */
    const showError = (message, header = "Error") => {
        dispatch({ type: "error-show", payload: { message, header } });
    };

    return (
        <Provider value={{ state, dispatch, showError }}>{children}</Provider>
    );
};

export { AppContext, AppProvider, appDispatch };
