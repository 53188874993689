import { AxiosInstance } from "./index";

/********************************************************************
 * Get authorized list of cities with optional reporting period range
 * @param {*} reporting_year (optional, but requires reporting_period)
 * @param {*} reporting_period
 */
const list = async (
    reporting_year = undefined,
    reporting_period = undefined
) => {
    var options = {};

    if (reporting_year && reporting_period) {
        options = {
            params: {
                reporting_year,
                reporting_period,
            },
        };
    }
    var response = await AxiosInstance.get("/v1/cities", options);

    return response;
};

/********************************************************************
 * Export program data to excel
 * @param {*} reporting_year (optional, but requires reporting_period)
 * @param {*} reporting_period
 */
const export_program = async (
    reporting_year = undefined,
    reporting_period = undefined
) => {
    var options = { params: { export: 1 }, responseType: "blob" };

    if (reporting_year && reporting_period) {
        options = {
            params: {
                reporting_year,
                reporting_period,
            },
        };
    }
    var response = await AxiosInstance.get("/v1/cities", options);

    return response;
};

/********************************************************************
 * Export city data to excel
 * @param {*} city_uuid
 * @param {*} reporting_year (optional, but requires reporting_period)
 * @param {*} reporting_period
 */
const export_city = async (
    city_uuid,
    reporting_year = undefined,
    reporting_period = undefined
) => {
    var options = { params: { uuid: city_uuid, export: 1 }, responseType: "blob" };

    if (reporting_year && reporting_period) {
        options = {
            params: {
                reporting_year,
                reporting_period,
            },
        };
    }
    var response = await AxiosInstance.get("/v1/cities", options);

    return response;
};

/********************************************************************
 * @param {*} uuid
 * @param {*} reporting_year (optional, but requires reporting_period)
 * @param {*} reporting_period
 */
const get = async (
    uuid,
    reporting_year = undefined,
    reporting_period = undefined
) => {
    var options = { params: { uuid } };

    if (reporting_year && reporting_period) {
        options = {
            ...options,
            params: {
                uuid,
                reporting_year,
                reporting_period,
            },
        };
    }

    var response = await AxiosInstance.get("/v1/cities", options);

    response.data = response.data.cities;

    return response.data[0];
};

/********************************************************************
 *
 * @param {*} uuid
 */
const approvers = async (uuid) => {
    var response = await AxiosInstance.get(`/v1/city/${uuid}/approvers`);
    return response;
};

/********************************************************************
 *
 * @param {*} city_uuid
 */
const reporters = async (city_uuid) => {
    var response = await AxiosInstance.get(`/v1/city/${city_uuid}/reporters`);
    return response;
};

/********************************************************************/
export default {
    list,
    get,
    approvers,
    reporters,
    export_program,
    export_city,
};
