import React from "react";
import { Header } from "semantic-ui-react";
import _ from "lodash";

import { Link } from "react-router-dom";
import QAVDot from "../../ui/QAVDot";
import { UserContext } from "../../../../../state";

const CITY_SUMMARY_TABLE_HEADERS = [
    "Intervention",
    "Intervention Type",
    "Short Description",
    "Indicator Definition",
    "Target",
    "Actual (Variance)",
    "Explanation of Variance vs. Targets",
    "Description of Any Anticipated Changes That May Impact Intervention Progress",
    "Qualitative Assessment of Progress vs. HAU Targets",
];

/* ------------------------------------------------------------------ */
const DashCitySummary = ({ cityData }) => {
    const user = React.useContext(UserContext);

    function getTypes(int) {
        if (!int || _.isEmpty(int)) return "--";
        let types = _.isEmpty(int.types) ? (
            <span>--</span>
        ) : (
            int.types.map((t) => {
                return (
                    <span key={t.uuid}>
                        <Link to={`/dashboard/intervention_type/${t.uuid}`}>
                            {t.name}
                        </Link>
                    </span>
                );
            })
        );
        return types;
    }
    /* ------------------------------------------------------------------ */
    function renderSummaryTable() {
        let int_count = 0;
        let rows = [];
        let divider = [];
        let divider_sec = [];

        CITY_SUMMARY_TABLE_HEADERS.map((i) => {
            divider.push(<span className="divider"></span>);
            divider_sec.push(<span className="divider secondary"></span>);
        });

        if (_.isEmpty(cityData.interventions)) {
            rows.push(
                <span colSpan={16} className="text-center">
                    <Header size="tiny" content="No interventions." />
                </span>
            );
        } else {
            for (let i in cityData.interventions) {
                const int = cityData.interventions[i];

                if (int.current_status !== "Approved") continue;

                int_count++;

                // Put in a dummy indicator if there isn't one
                if (_.isEmpty(int.primary_indicator)) {
                    int.primary_indicator[0] = {
                        name: "--",
                        data_value: "--",
                        variance: "--",
                    };
                }

                let data_value = int.primary_indicator[0].data_value || "--";
                let target_value =
                    int.primary_indicator[0].target_value || "--";
                let variance = int.primary_indicator[0].variance || "--";
                let explanation_of_variance =
                    int.reporting_period.explanation_of_variance || "--";
                let anticipated_changes =
                    int.reporting_period.anticipated_changes || "--";

                if (int.current_status !== "Approved") {
                    data_value = target_value = variance = explanation_of_variance = anticipated_changes =
                        "--";
                }

                rows.push(
                    <React.Fragment key={int.uuid}>
                        <span>
                            {user.hasCap("own_city_indicator:view") ? (
                                <Link
                                    to={`/dashboard/intervention/${int.uuid}`}
                                >
                                    {int.name}
                                </Link>
                            ) : (
                                int.name
                            )}
                        </span>
                        <span>{getTypes(int)}</span>
                        <span>{int.short_description}</span>
                        <span>{int.primary_indicator[0].name}</span>
                        <span className="text-center">{target_value}</span>
                        <span className="text-center">
                            <React.Fragment>
                                <div>
                                    {data_value}{" "}
                                    {isNaN(Number.parseFloat(variance))
                                        ? "(--)"
                                        : `(${variance}%)`}
                                </div>
                            </React.Fragment>
                        </span>
                        <span className="dont-break-out">
                            {explanation_of_variance}
                        </span>
                        <span className="dont-break-out">
                            {anticipated_changes}
                        </span>

                        <span className="text-center centered last-column">
                            <QAVDot
                                value={
                                    int.reporting_period &&
                                    int.reporting_period.qualitative_indicator
                                }
                                targetIntUUID={int.uuid}
                            />
                        </span>
                    </React.Fragment>
                );

                rows.push(divider);

                // if we have multiple primary_ints, tack them on to
                // subsequent rows

                if (int.primary_indicator.length > 0) {
                    for (let i = 1; i < int.primary_indicator.length; i++) {
                        let data_value =
                            int.primary_indicator[i].data_value || "--";
                        let target_value =
                            int.primary_indicator[i].target_value || "--";
                        let variance =
                            int.primary_indicator[i].variance || "--";

                        if (int.current_status !== "Approved") {
                            data_value = target_value = variance = "--";
                        }

                        rows.push(
                            <React.Fragment key={"pi_" + i}>
                                <span className="secondary-indicator"></span>
                                <span className="secondary-indicator"></span>
                                <span className="secondary-indicator"></span>
                                <span className="secondary-indicator">
                                    {int.primary_indicator[i].name}
                                </span>
                                <span className="secondary-indicator text-center">
                                    {target_value}
                                </span>
                                <span className="secondary-indicator text-center">
                                    <React.Fragment>
                                        <div>
                                            {data_value}{" "}
                                            {isNaN(Number.parseFloat(variance))
                                                ? "(--)"
                                                : `(${variance}%)`}
                                        </div>
                                    </React.Fragment>
                                </span>
                                <span className="secondary-indicator"></span>
                                <span className="secondary-indicator"></span>
                                <span className="secondary-indicator"></span>
                            </React.Fragment>
                        );

                        rows.push(divider_sec);
                    }
                }
            }
        }

        /* --------------------------------- */
        return !int_count ? (
            <Header as="h2" color="red" textAlign="center">
                No interventions approved in this period.
            </Header>
        ) : (
            <div
                className="dash-city-summary"
                style={{
                    gridTemplateColumns: `repeat(${CITY_SUMMARY_TABLE_HEADERS.length}, 1fr)`,
                }}
            >
                {CITY_SUMMARY_TABLE_HEADERS.map((header, index) => (
                    <span key={index} className="header">
                        {header}
                    </span>
                ))}

                {rows}
            </div>
        );
    }

    /* ------------------------------------------------------------------ */
    return renderSummaryTable();
};

export default DashCitySummary;
