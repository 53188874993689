import React, { useContext, useState } from "react";
import { Auth } from "../../api";
import { AppContext, UserContext } from "../../state";
import { Dropdown, Menu, Confirm } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { genericResponseHandler } from "../../utils";

const UserMenu = () => {
    const user = useContext(UserContext);
    const app = useContext(AppContext);
    const history = useHistory();

    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

    /********************************************************************
     * Has user confirmed logged out? Make it so.
     */
    const onLogoutConfirmed = async () => {
        try {
            setShowLogoutConfirm(false);
            await Auth.logout(user.state);
            user.dispatch({ type: "logout" });
            history.push("/login");
        } catch (err) {
            genericResponseHandler(err, app);
        }
    };

    return (
        <div className="right menu">
            <Menu.Item>
                <Dropdown text={user.state.email} className="link item">
                    <Dropdown.Menu>
                        <Dropdown.Item
                            as={Link}
                            to="/settings"
                            icon="setting"
                            content="Settings"
                        />
                        {user.hasCap("user:edit") && (
                            <Dropdown.Item
                                as={Link}
                                to="/admin/users"
                                icon="users"
                                content="Users"
                            />
                        )}

                        <Dropdown.Divider />

                        <Dropdown.Item
                            onClick={() => setShowLogoutConfirm(true)}
                            icon="log out"
                            content="Logout"
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
            <Confirm
                open={showLogoutConfirm}
                onCancel={() => setShowLogoutConfirm(false)}
                onConfirm={onLogoutConfirmed}
                size="mini"
                icon="no"
                content="Are you sure you want to logout?"
            />
        </div>
    );
    //
};

export default UserMenu;
