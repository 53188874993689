import React, { useEffect } from "react";
import {
    Table,
    Button,
    Modal,
    Icon,
    Form,
    Dropdown,
    Container,
} from "semantic-ui-react";
import { UserContext, AppContext } from "../../../../../state";
import Interventions from "../../../../../api/interventions";
import { genericResponseHandler } from "../../../../../utils";
import { Indicators } from "../../../../../api";

const ApprovalBlock = ({
    intData,
    onApprovalChange,
    qavField,
    chosenPeriod,
}) => {
    const user = React.useContext(UserContext);
    const app = React.useContext(AppContext);
    const [isApprover, setIsApprover] = React.useState(false);
    const [approvalLevel, setApprovalLevel] = React.useState("");
    const [approvalLevelId, setApprovalLevelId] = React.useState(9);
    const [showModalReject, setShowModalReject] = React.useState(false);
    const [showModalAccept, setShowModalAccept] = React.useState(false);
    const [frmRejectReason, setFrmRejectReason] = React.useState("");
    const [frmQavValue, setFrmQavValue] = React.useState(null);

    const APPROVAL_TO_ID = {
        "Site Monitor": 0,
        "Program Officer": 1,
        "ABIF Approver": 2,
    };

    /* ------------------------------------------- */
    useEffect(() => {
        if (
            intData.first_approval_user === user.state.uuid &&
            !intData.reporting_period.first_approval &&
            !intData.reporting_period.first_approval_skipped
        ) {
            setIsApprover(true);
            setApprovalLevel("Site Monitor");
        } else if (
            intData.second_approval_user === user.state.uuid &&
            !intData.reporting_period.second_approval &&
            (intData.reporting_period.first_approval ||
                intData.reporting_period.first_approval_skipped)
        ) {
            setIsApprover(true);
            setApprovalLevel("Program Officer");
        } else if (
            intData.third_approval_user === user.state.uuid &&
            !intData.reporting_period.third_approval &&
            intData.reporting_period.second_approval &&
            (intData.reporting_period.first_approval ||
                intData.reporting_period.first_approval_skipped)
        ) {
            setIsApprover(true);
            setApprovalLevel("ABIF Approver");
        }
    }, [
        intData.first_approval_user,
        intData.reporting_period.first_approval,
        intData.reporting_period.first_approval_skipped,
        intData.reporting_period.second_approval,
        intData.reporting_period.third_approval,
        intData.second_approval_user,
        intData.third_approval_user,
        user.state.uuid,
        chosenPeriod,
    ]);

    /* ------------------------------------------- */
    useEffect(() => {
        setApprovalLevelId(APPROVAL_TO_ID[approvalLevel]);
    }, [approvalLevel, APPROVAL_TO_ID]);

    /* ------------------------------------------- */
    const onApprovalDo = async () => {
        // save qav
        try {
            await Indicators.set_data(
                qavField.uuid,
                frmQavValue,
                undefined,
                chosenPeriod.reporting_year,
                chosenPeriod.reporting_period
            );
            await Interventions.set_approved(
                intData.uuid,
                true,
                undefined,
                chosenPeriod.reporting_year,
                chosenPeriod.reporting_period
            );
            await onApprovalChange();
        } catch (err) {
            genericResponseHandler(err, app);
        }
    };
    /* ------------------------------------------- */
    const onApprovalDoSkipQAV = async () => {
        try {
            await Interventions.set_approved(
                intData.uuid,
                true,
                undefined,
                chosenPeriod.reporting_year,
                chosenPeriod.reporting_period
            );
            await onApprovalChange();
        } catch (err) {
            genericResponseHandler(err, app);
        }
    };

    /* ------------------------------------------- */
    const onReject = () => {
        setFrmRejectReason("");
        setShowModalReject(true);
    };

    /* ------------------------------------------- */
    const onRejectDo = () => {
        Interventions.set_approved(
            intData.uuid,
            false,
            frmRejectReason,
            chosenPeriod.reporting_year,
            chosenPeriod.reporting_period
        ).then(onApprovalChange, (err) => {
            genericResponseHandler(err, app);
        });
    };

    /* ------------------------------------------- */
    function buttonBlock(approval_level_id) {
        if (!isApprover) {
            let pos;
            switch (approval_level_id) {
                case 0:
                    pos = !!intData.reporting_period.first_approval;
                    break;
                case 1:
                    pos = !!intData.reporting_period.second_approval;
                    break;
                case 2:
                    pos = !!intData.reporting_period.third_approval;
                    break;
                default:
                    return;
            }

            let thumbs_down = <React.Fragment></React.Fragment>;

            if (approval_level_id > 0)
                thumbs_down = <Button disabled icon="thumbs down" />;

            if (
                approval_level_id === 2 &&
                intData.third_approval_user === user.state.uuid &&
                !!intData.reporting_period.third_approval
            )
                thumbs_down = (
                    <Button
                        negative
                        icon="thumbs down"
                        title="Reject"
                        onClick={() => onReject()}
                    />
                );

            if (
                approval_level_id === 0 &&
                intData.reporting_period.first_approval_skipped &&
                intData.third_approval_user === user.state.uuid
            ) {
                return (
                    <Button
                        disabled
                        color={
                            intData.reporting_period.first_approval_skipped &&
                            approval_level_id === 0
                                ? "purple"
                                : "green"
                        }
                        icon={
                            intData.reporting_period.first_approval_skipped &&
                            approval_level_id === 0
                                ? "eye slash outline"
                                : "thumbs up"
                        }
                        title="Accepted"
                    />
                );
            }

            if (
                intData.reporting_period.first_approval_skipped &&
                approval_level_id === 0
            ) {
                return (
                    <Button
                        disabled
                        color={
                            intData.reporting_period.first_approval_skipped &&
                            approval_level_id === 0
                                ? "purple"
                                : "green"
                        }
                        icon={
                            intData.reporting_period.first_approval_skipped &&
                            approval_level_id === 0
                                ? "eye slash outline"
                                : "thumbs up"
                        }
                        title="Accepted"
                    />
                );
            }

            if (
                intData.second_approval_user === user.state.uuid &&
                intData.reporting_period.second_approval &&
                approval_level_id === 1
            ) {
                return (
                    <Button.Group>
                        <Button positive={pos} disabled icon="thumbs up" />
                        {thumbs_down}
                    </Button.Group>
                );
            }

            return (
                <Button.Group>
                    <Button positive={pos} disabled icon="thumbs up" />
                    {thumbs_down}
                </Button.Group>
            );
        }
        // Not our turn
        if (approval_level_id !== approvalLevelId) {
            // But we're later in the process...
            if (approval_level_id > approvalLevelId) {
                return (
                    <Button.Group>
                        <Button disabled icon="thumbs up" />
                        {approval_level_id > 0 && (
                            <Button disabled icon="thumbs down" />
                        )}
                    </Button.Group>
                );
            }

            // we've already voted (and since we'll never show this if anyone
            // has rejected, we'll never see a vote of 'rejected')
            return (
                <Button.Group>
                    <Button
                        disabled
                        color={
                            intData.reporting_period.first_approval_skipped &&
                            approval_level_id === 0
                                ? "purple"
                                : "green"
                        }
                        icon={
                            intData.reporting_period.first_approval_skipped &&
                            approval_level_id === 0
                                ? "eye slash outline"
                                : "thumbs up"
                        }
                        title="Accepted"
                    />
                    {approval_level_id > 0 && (
                        <Button disabled icon="thumbs down" />
                    )}
                </Button.Group>
            );
        }

        // We're authorized to enter approval verdict
        return (
            <Button.Group>
                <Button
                    positive
                    icon="thumbs up"
                    title="Approve"
                    onClick={() => {
                        if (approvalLevelId === 0) {
                            onApprovalDoSkipQAV();
                        } else {
                            setFrmQavValue(null);
                            setShowModalAccept(true);
                        }
                    }}
                />
                {approval_level_id > 0 && (
                    <Button
                        negative
                        icon="thumbs down"
                        title="Reject"
                        onClick={() => onReject()}
                    />
                )}
            </Button.Group>
        );
    }

    /* ------------------------------------------- */
    return (
        <React.Fragment>
            <Table compact collapsing style={{ float: "right" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Approver</Table.HeaderCell>
                        <Table.HeaderCell>Approved?</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Program Officer</Table.Cell>
                        <Table.Cell>{buttonBlock(1)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>ABIF Approver</Table.Cell>
                        <Table.Cell>{buttonBlock(2)}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Modal
                open={showModalReject}
                closeOnEscape
                onClose={() => setShowModalReject(false)}
            >
                <Modal.Header>
                    <Icon name="thumbs down" color="red" /> Rejecting
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.TextArea
                            label="Reason"
                            placeholder="Reason for rejecting..."
                            onChange={(e) => setFrmRejectReason(e.target.value)}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button
                            negative
                            content="Cancel"
                            icon="cancel"
                            onClick={() => setShowModalReject(false)}
                        />
                        <Button
                            positive
                            content="Continue"
                            icon="thumbs down"
                            disabled={!frmRejectReason}
                            onClick={onRejectDo}
                        />
                    </Button.Group>
                </Modal.Actions>
            </Modal>
            <Modal
                open={showModalAccept}
                closeOnEscape
                onClose={() => setShowModalAccept(false)}
            >
                <Modal.Header>
                    <Icon name="thumbs up" color="green" /> Approving
                </Modal.Header>
                <Modal.Content>
                    {intData.reporting_period.qualitative_indicator ? (
                        <div padding="3rem">
                            Program Officer's Assement:{" "}
                            {intData.reporting_period.qualitative_indicator}
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="qav-field">
                        <Container>
                            <label>
                                {qavField.name.replace("vs. HAU targets", "")}
                            </label>
                            <Dropdown
                                item
                                placeholder="Please choose one: "
                                selection
                                scrolling
                                defaultValue={frmQavValue}
                                options={qavField.indicator_option_type.options.map(
                                    (option) => ({
                                        key: option.uuid,
                                        text: option.name,
                                        value: option.name,
                                    })
                                )}
                                onBlur={() => {
                                    //
                                }}
                                onChange={(e, input) => {
                                    setFrmQavValue(input.value);
                                }}
                            />
                        </Container>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button
                            negative
                            content="Cancel"
                            icon="cancel"
                            onClick={() => setShowModalAccept(false)}
                        />
                        <Button
                            positive
                            content="Approve"
                            icon="thumbs up"
                            disabled={!frmQavValue}
                            onClick={onApprovalDo}
                        />
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    );
};

export default ApprovalBlock;
