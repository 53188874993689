import React, { createContext, useReducer, useEffect } from "react";
import _ from "lodash";
import { appDispatch } from "./AppProvider";
import {
    startTokenKeepAliveHeartbeat,
    tokenKeepAliveHandle,
    isTokenExpired,
} from "../api/jwt";

import config from "../config";

const USER_KEY = `user-${process.env.NODE_ENV}`;

const initialState = {
    isLoggedIn: false,
    userId: null,
};

const localState = JSON.parse(sessionStorage.getItem(USER_KEY));

const UserContext = createContext(localState || initialState);

const { Provider } = UserContext;

const _userReducer = (state, action) => {
    switch (action.type) {
        case "login":
            return {
                ...state,
                ...action.payload.data,
                isLoggedIn: true,
            };

        case "logout":
            clearTimeout(tokenKeepAliveHandle);
            sessionStorage.clear();
            return { ...initialState };

        default:
            throw new Error(`Unknown user context reducer: "${action.type}"`);
    }
};

const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        _userReducer,
        localState || initialState
    );

    if (state.isLoggedIn && !tokenKeepAliveHandle) {
        if (isTokenExpired()) {
            if (config.IS_DEV) console.log("* Token was expired; log in again");
            appDispatch({
                type: "show-user-login-dialog-timeout",
                payload: true,
            });
            //TODO: page should probably reload; odds are we're not going to get a component refresh
        }

        // TODO: if token stale; refresh

        // --

        // TODO: otherwise, the next heartbeat timeout should be adjusted from what's left
        // of the token's life

        // --
        else {
            if (config.IS_DEV)
                console.log("* Starting keepalive for existing token");
            startTokenKeepAliveHeartbeat();
        }
    }

    useEffect(() => {
        sessionStorage.setItem(USER_KEY, JSON.stringify(state));
    }, [state]);

    /**
     * Does the logged in user have this specific cap?
     * @param {*} capname
     */
    const hasCap = (capname) => {
        if (state.isLoggedIn)
            return _.intersection(state.capabilities, [capname]).length > 0;
        return false;
    };

    /**
     * Does the logged in user have any of the provided caps?
     * @param {*} caplist
     */
    const hasCapIn = (caplist) => {
        if (state.isLoggedIn) {
            return _.intersection(state.capabilities, caplist).length > 0;
        }
        return false;
    };

    return (
        <Provider value={{ state, dispatch, hasCap, hasCapIn }}>
            {children}
        </Provider>
    );
};

export { UserContext, UserProvider };
