import { AxiosInstance } from "./index";

/********************************************************************
 * Create an intervention
 */
const create = async (name, city_uuid, long_description, short_description) => {
    var response = await AxiosInstance.post("/v1/intervention", {
        name: name,
        city_uuid: city_uuid,
        long_description: long_description,
        short_description: short_description,
    });
    return response;
};

//"PUT /intervention/:intervention_uuid

/********************************************************************
 * Create an intervention
 */
const update = async (
    int_uuid,
    city_uuid,
    name,
    short_description,
    long_description
) => {
    var response = await AxiosInstance.put(`/v1/intervention/${int_uuid}`, {
        name,
        city_uuid,
        short_description,
        long_description,
    });
    return response;
};

/********************************************************************
 * Get an intervention
 * @param {*} intervention_uuid
 * @param {*} reporting_year Optionally pull a specific reporting period (requires `reporting_period`)
 * @param {*} reporting_period
 */
const get = async (
    intervention_uuid,
    reporting_year = undefined,
    reporting_period = undefined
) => {
    let options = {};
    if (reporting_year && reporting_period) {
        options = { params: { reporting_year, reporting_period } };
    }

    var response = await AxiosInstance.get(
        `/v1/intervention/${intervention_uuid}`,
        options
    );
    return response;
};

/********************************************************************
 * Delete an intervention
 */
const del = async (intervention_uuid) => {
    var response = await AxiosInstance.delete(
        `/v1/intervention/${intervention_uuid}`
    );
    return response;
};

/********************************************************************
 * Get all of the intervention Types
 */
const list_types = async () => {
    var response = await AxiosInstance.get("/v1/intervention/types");
    return response;
};

/********************************************************************
 * Adds a Type to intervention
 * @param {*} intervention_uuid
 * @param {*} type_uuid Indicator type uuid
 *
 * If already assigned, returns 409.
 */
const add_type = async (intervention_uuid, type_uuid) => {
    var response = await AxiosInstance.post(
        `/v1/intervention/${intervention_uuid}/add_type`,
        {
            uuid: type_uuid,
        }
    );
    return response;
};

/**
 * Submit the intervention for approval
 * @param {*} intervention_uuid
 */
const submit = async (
    intervention_uuid,
    reject_approval = false,
    reporting_year = undefined,
    reporting_period = undefined
) => {
    let options = { submit: !reject_approval };

    if (reporting_year && reporting_period) {
        options = { ...options, reporting_year, reporting_period };
    }

    var response = await AxiosInstance.post(
        `/v1/intervention/${intervention_uuid}/submit`,
        options
    );

    return response;
};

/**
 * Submit the intervention for approval
 * @param {*} intervention_uuid
 */
const submit_monthly = async (
    intervention_uuid,
    reporting_year = undefined,
    reporting_period = undefined
) => {
    let options = {};

    if (reporting_year && reporting_period) {
        options = { reporting_year, reporting_period };
    }
    var response = await AxiosInstance.post(
        `/v1/intervention/${intervention_uuid}/submit_month`,
        options
    );

    return response;
};

/**
 * Assigns a user to an intervention as an approver with level id
 * @param {*} intervention_uuid
 * @param {*} user_uuid
 * @param {*} level_name - 'first', 'second', 'third'
 */
const add_approver = async (intervention_uuid, user_uuid, level_name) => {
    var response = await AxiosInstance.post(
        `/v1/intervention/${intervention_uuid}/add_approver`,
        {
            uuid: user_uuid,
            level: level_name,
        }
    );
    return response;
};

/**
 * Passes judgment upon an intervention
 * @param {string} intervention_uuid
 * @param {bool} is_approved
 * @param {string} reason_message Used when submitting a rejection
 */
const set_approved = async (
    intervention_uuid,
    is_approved,
    reason_message = "None",
    reporting_year = undefined,
    reporting_period = undefined
) => {
    var options = {
        approve: is_approved,
        reason: reason_message,
    };

    if (reporting_year && reporting_period) {
        options = {
            ...options,
            reporting_year,
            reporting_period,
        };
    }
    var response = await AxiosInstance.post(
        `/v1/intervention/${intervention_uuid}/approve`,
        options
    );
    return response;
};

//

/**
 * Assign an intervention to a user
 * @param {string} intervention_uuid Intervention UUID
 * @param {string} user_uuid User UUID
 * @param {bool} unassign By default, user is assigned. Flip this true to remove the user.
 */
const assign_user = async (intervention_uuid, user_uuid, unassign = false) => {
    var response = await AxiosInstance.post(
        `/v1/user/${user_uuid}/intervention/assign`,
        {
            uuid: intervention_uuid,
            action: unassign ? "remove" : "add",
        }
    );
    return response;
};

/**
 * Export an excel dump of the intervention
 * @param {string} intervention_uuid Intervention UUID
 */
const export_excel = async (intervention_uuid) => {
    var response = await AxiosInstance.get(
        `/v1/intervention/${intervention_uuid}/excel`,
        {
            responseType: "blob",
        }
    );
    return response;
};

export default {
    create,
    update,
    get,
    del,
    list_types,
    add_type,
    submit,
    submit_monthly,
    add_approver,
    set_approved,
    assign_user,
    export_excel,
};
