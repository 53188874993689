import React from "react";
import _ from "lodash";
import {
    Container,
    Header,
    Grid,
    Loader,
    Segment,
    Button,
    Label,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import numeral from "numeral";

import { sortIntPeriods } from "../../../../../utils";
import { UserContext } from "../../../../../state";

/* ------------------------------------------------------------------ */
const DashIntSummary = ({ intData }) => {
    const user = React.useContext(UserContext);
    const tableRef = React.createRef();
    const history = useHistory();

    /* Drag handler start */
    let pos = { top: 0, left: 0, x: 0, y: 0 };
    let is_mousedown = false;

    const mouseDownHandler = function (e) {
        pos = {
            left: tableRef.current.scrollLeft,
            x: e.clientX,
        };
        is_mousedown = true;
    };

    const mouseMoveHandler = function (e) {
        if (is_mousedown) {
            const dx = e.clientX - pos.x;
            tableRef.current.scrollLeft = pos.left - dx;
        }
    };

    const mouseUpHandler = function () {
        is_mousedown = false;
        tableRef.current.style.cursor = "grab";
        tableRef.current.style.removeProperty("user-select");
    };

    // Adds a grab-scroll handler to the table
    React.useEffect(() => {
        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mousedown", mouseDownHandler);
        document.addEventListener("mouseup", mouseUpHandler);
    }, [tableRef]); // eslint-disable-line

    // This gets called when pushing a history page change, or else it
    // will linger, but the dom element it's hooked to will vanish.
    function unhookHandler() {
        document.removeEventListener("mouseup", mouseUpHandler);
        document.removeEventListener("mousedown", mouseDownHandler);
        document.removeEventListener("mousemove", mouseMoveHandler);
    }

    history.listen(() => {
        unhookHandler();
    });
    /* Drag handler end */

    /* ------------------------------------------------------------------ */
    function renderPeriodEntry(data) {
        let variance = data.value;

        if (data.data_type.coerce_to === "numeric") {
            variance = numeral(variance).format("0,0.[000000000000]");
        }

        if (data.variance) {
            variance += ` (${data.variance}%)`;
        }
        return (
            <div className="period-entry">
                {data.target_value && (
                    <div className="segment">{data.target_value}</div>
                )}
                <div className="segment">{variance || "--"}</div>
            </div>
        );
    }
    /* ------------------------------------------------------------------ */
    function extractTCValue(tc, int_data) {
        if (!int_data || _.isEmpty(int_data)) {
            return "";
        }

        // otherwise just return the specific period content
        for (let i in int_data) {
            if (
                int_data[i].reporting_year === tc.year &&
                int_data[i].reporting_period === tc.period
            ) {
                return renderPeriodEntry(int_data[i]);
            }
        }
        //

        return "";
    }

    /* ------------------------------------------------------------------ */
    function renderSummaryTable() {
        let temporal_columns_row1 = []; // light pink/black top row labels
        let temporal_columns_row2 = []; // darker pink/white second row labels

        const headerOnly = !user.hasCapIn(["own_city_indicator:view"]);

        sortIntPeriods(intData);

        for (let i in intData.reporting_periods) {
            let per = intData.reporting_periods[i];

            if (per.status !== "Approved") continue;
            if (per.period === "All") continue;

            temporal_columns_row1.push({
                year: per.year,
                period: per.period,
                head: (
                    <span class="header secondary temporal-column">
                        {per.year} / {per.period}
                    </span>
                ),
            });
            temporal_columns_row2.push({
                year: per.year,
                period: per.period,
                head: (
                    <span class="header temporal-column">
                        Target/Actual (variance)
                    </span>
                ),
            });
        }

        let rows = [];

        // split up indicators by `indicator_type`
        let type_groups = {};
        for (let i in intData.indicators) {
            const ind = intData.indicators[i];
            if (ind.indicator_type) {
                if (!type_groups[ind.indicator_type.name]) {
                    type_groups[ind.indicator_type.name] = [];
                }

                type_groups[ind.indicator_type.name].push({
                    ...ind,
                    ind_type_uuid: ind.indicator_type.uuid,
                });
            }
        }

        // render each of the groups, along with it's own
        // mini-indicator-type row
        const orderedGroups = {
            "City Pilot details": type_groups["City Pilot details"],
            "City Pilot inputs: human resources":
                type_groups["City Pilot inputs: human resources"],
            /*
            // This section was removed in SMART3-571. It's a temporary
            // measure while the client figures out what they want to see.
            // Commenting it out instead of deleting since it may come back.
            //
                "City Pilot inputs: Steering Committee details":
                    type_groups["City Pilot inputs: Steering Committee details"],
            */
            "Intervention details": type_groups["Intervention details"],
            "Descriptive fields": type_groups["Descriptive fields"],
            Inputs: type_groups["Inputs"],
            "Primary Progress Indicator":
                type_groups["Primary Progress Indicator"],
            "Intermediate outcomes": type_groups["Intermediate outcomes"],
            Activities: type_groups["Activities"],
            Outputs: type_groups["Outputs"],
        };
        for (let i in orderedGroups) {
            if (
                headerOnly &&
                ![
                    "2690717b-c506-4412-a384-fc159bc63aa2",
                    "6bbdd02a-2391-419c-b277-fd4c86df632c",
                    "29d09fd7-c3c6-4f00-a644-2941c67f6de4",
                    "a5010405-c4a9-4c24-9edb-3c1aa9e8d38f",
                ].includes(orderedGroups[i][0].ind_type_uuid)
            ) {
                continue;
            }

            // #### mini-header indicator-type row
            rows = [
                ...rows,
                <span className={"indicator-type-row sticky"}>{i}</span>,
                <span className={"indicator-type-row sticky-2"}></span>,
                <span
                    className={"indicator-type-row sticky-3 baseline"}
                ></span>,
                temporal_columns_row1.map((i) => (
                    <span
                        className={"indicator-type-row temporal-column"}
                    ></span>
                )),
            ];

            // #### indicators
            rows = [
                ...rows,
                orderedGroups[i] &&
                    orderedGroups[i].map((ind) => {
                        let value = ind.current_value;
                        if (value) {
                            switch (ind.data_type.coerce_to) {
                                case "numeric":
                                    value = numeral(value).format(
                                        "0,0.[00000000]"
                                    );
                                    break;
                                case "date":
                                    value = moment(value).format("MM-DD-YYYY");
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            value = "";
                        }
                        return (
                            <React.Fragment>
                                <span
                                    width={6}
                                    className="sticky indicator-name sticky"
                                >
                                    <div>{ind.name}</div>
                                </span>
                                <span
                                    width={10}
                                    className="dont-break-out indicator-value sticky-2"
                                >
                                    {value}
                                </span>
                                <span
                                    width={10}
                                    className="dont-break-out indicator-baseline sticky-3 text-center"
                                >
                                    {ind.baseline && ind.baseline.value && (
                                        <Label
                                            detail={ind.baseline.value}
                                            content="Baseline"
                                            color="brown"
                                            size="tiny"
                                        />
                                    )}
                                </span>
                                {temporal_columns_row1.map((tc) => (
                                    <span className={"text-center"}>
                                        {extractTCValue(tc, ind.data)}
                                    </span>
                                ))}
                            </React.Fragment>
                        );
                    }),
            ];
        }

        return (
            <div class="int-wrapper">
                <div
                    ref={tableRef}
                    class="dash-int-summary"
                    style={{
                        gridTemplateColumns: `250px 250px 120px repeat(${temporal_columns_row1.length}, 12em)`,
                    }}
                >
                    <span class="header secondary sticky"></span>
                    <span class="header secondary sticky-2"></span>
                    <span class="header secondary sticky-3"></span>
                    {temporal_columns_row1.map((tc) => tc.head)}
                    <span class="header sticky">Indicator</span>
                    <span class="header sticky-2">Value</span>
                    <span class="header sticky-3">Baseline</span>
                    {temporal_columns_row2.map((tc) => tc.head)}
                    {rows}
                </div>
            </div>
        );
    }

    /* ------------------------------------------------------------------ */
    return (
        <Container>
            {!intData ? (
                <Segment placeholder>
                    <Loader active content="Loading intervention..." />
                </Segment>
            ) : (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as="h2">
                                {`'${intData.name}' Summary (${intData.reporting_period.year} / ${intData.reporting_period.period})`}
                            </Header>
                            <Button
                                size="tiny"
                                icon="line graph"
                                color="blue"
                                onClick={() => {
                                    unhookHandler();
                                    history.push(
                                        `/intervention/${intData.uuid}`
                                    );
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>{renderSummaryTable()}</Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Container>
    );
};

export default DashIntSummary;
