import React from "react";
import _ from "lodash";

import { Container, Header, Grid } from "semantic-ui-react";
import { Line } from "react-chartjs-2";

import {
    getCSSVar,
    colorGrabber,
    TYPE_TO_PRIMARY_UUUD,
} from "../../../../../utils";

const DashTypeGraphProgressOT = ({ cityList, myType, types, chosenPeriod }) => {
    const [graphData, setGraphData] = React.useState(undefined);
    const [interventions, setInterventions] = React.useState([]);
    const [canRender, setCanRender] = React.useState(false);
    const [yAxisLabel, setYAxisLabel] = React.useState(undefined);

    const baseGraphConfig = {
        labels: [],
        datasets: [],
    };

    /* -------------------------- */
    React.useEffect(() => {
        // iterate city list, extract interventions with intervention type
        let interventions = [];
        for (let i in cityList) {
            let plucked = cityList[i].interventions.filter((int) => {
                int.city = {
                    name: cityList[i].name,
                    uuid: cityList[i].uuid,
                };
                return (
                    int.types.filter((type) => type.uuid === myType.uuid)
                        .length > 0
                );
            });
            interventions = interventions.concat(plucked);
        }
        setInterventions(interventions);
    }, [cityList]); // eslint-disable-line

    /* -------------------------- */
    React.useEffect(() => {
        buildDatasets();
    }, [interventions]); // eslint-disable-line

    /* -------------------------- */
    async function buildDatasets() {
        let datasets = [];
        let labels = [];
        let labels_ex = {};

        // build labels
        for (let i in interventions) {
            for (let j in interventions[i].reporting_periods) {
                const rp = interventions[i].reporting_periods[j];
                if (rp.status !== "Approved") continue;
                if (rp.period === "All") continue;
                if (rp.period[0] === "M") continue;

                const label_name = `${rp.year}/${rp.period}`;
                if (!labels.includes(label_name)) {
                    labels.push(label_name);
                }
            }
        }

        if (_.isEmpty(labels)) {
            return;
        }

        setCanRender(true);

        labels.sort().map((lab) => {
            const [year, period] = lab.split("/");
            labels_ex[lab] = {
                year,
                period,
            };
        });

        for (let i in interventions) {
            // get our PI index for the current type selected
            if (!Object.keys(TYPE_TO_PRIMARY_UUUD).includes(myType.uuid))
                continue;
            if (TYPE_TO_PRIMARY_UUUD[myType.uuid] === undefined) continue;

            const default_ind_uuid = TYPE_TO_PRIMARY_UUUD[myType.uuid];

            let cur_int = interventions[i];

            let pi_index = -1;

            cur_int.primary_indicator.filter((pi, idx) => {
                if (pi.default_indicator_uuid === default_ind_uuid) {
                    pi_index = idx;
                }
            });

            // Intervention doesn't have it. Don't draw it.
            if (pi_index < 0) {
                continue;
            }

            setYAxisLabel(cur_int.primary_indicator[pi_index].name);

            let ds_entry = {
                label: `${interventions[i].city.name}`,
                fill: false,
                borderColor: colorGrabber(interventions[i].city.uuid),
                backgroundColor: colorGrabber(interventions[i].city.uuid),
                data: [],
            };

            // Search through the primary indicator for data matching
            // the our year/period value list
            for (let r in labels_ex) {
                let year = labels_ex[r].year;
                let period = labels_ex[r].period;

                let result =
                    _.find(cur_int.primary_indicator[pi_index].data, {
                        reporting_period: {
                            year,
                            period,
                        },
                    }) || undefined;

                if (result) {
                    ds_entry.data.push(
                        result.reporting_period.status === "Approved"
                            ? result.data_value
                            : null
                    );
                } else {
                    ds_entry.data.push(null);
                }
            }

            datasets.push(ds_entry);
        }

        setGraphData({
            ...baseGraphConfig,
            labels,
            datasets,
        });
    }

    /* -------------------------- */
    return !(canRender && cityList && graphData) || !yAxisLabel ? (
        <React.Fragment></React.Fragment>
    ) : (
        <Container>
            <Header content={myType.name} as="h3" icon="line graph" />
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={1}>
                        <p className="yaxis">{yAxisLabel}</p>
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <Line
                            data={graphData}
                            width={100}
                            height={50}
                            options={{
                                maintainAspectRatio: true,
                                tooltips: {
                                    mode: "x",
                                    // filter: (item) => item.value > 0,
                                },
                                legend: {
                                    onHover: function (e) {
                                        e.target.style.cursor = "pointer";
                                    },
                                },
                                hover: {
                                    onHover: function (e) {
                                        var point = this.getElementAtEvent(e);
                                        if (point.length)
                                            e.target.style.cursor = "pointer";
                                        else e.target.style.cursor = "default";
                                    },
                                },

                                scales: {
                                    xAxes: [
                                        {
                                            ticks: {
                                                display: true,
                                                fontColor: getCSSVar(
                                                    " --abif-neutral"
                                                ),
                                                fontSize: 18,
                                                fontStyle: "bold",
                                            },
                                            gridLines: {
                                                display: true,
                                            },
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            labels: false,
                                            scaleLabel: {
                                                display: false,
                                            },
                                            gridLines: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: true,
                                            },
                                        },
                                    ],
                                },
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default DashTypeGraphProgressOT;
