import React from "react";
import DropdownFromSource from "../../../../ui/DropdownFromSource";
import { AppContext } from "../../../../../state";
import { Indicators } from "../../../../../api";
import { genericResponseHandler } from "../../../../../utils";

const PredefinedIndicatorDropdown = ({
    onSelect,
    placeholder = "Select a predefined indicator...",
    buttonText = "Add Predefined Indicator",
    isInApproval,
}) => {
    const app = React.useContext(AppContext);

    const [data, setData] = React.useState({});

    React.useEffect(() => {
        (async () => {
            try {
                const result = await Indicators.get_default_indicators();

                const processed = result.data
                    .filter(
                        (indicator) => indicator.parent_indicator_uuid === null
                    )
                    .map((indicator) => {
                        return {
                            text: indicator.name,
                            value: indicator.uuid,
                        };
                    });

                setData(processed);
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, []); // eslint-disable-line

    return (
        <DropdownFromSource
            onSelect={onSelect}
            placeholder={placeholder}
            buttonText={buttonText}
            dataSource={data}
            isInApproval={isInApproval}
        />
    );
};

export default PredefinedIndicatorDropdown;
