import React from "react";
import _ from "lodash";
import { UserContext } from "../../state";
import { Redirect } from "react-router-dom";

/**
 * Does a redirect if the user is not logged in, or if they don't have the
 * appropriate caps.
 *
 * Props:
 * - `needCaps` an array of required permissions
 * - `redirectTo` path to switch user to on caps failure; defaults to `/`
 */
const CapGuard = ({ children, needCaps = [], redirectTo = "/" }) => {
    const user = React.useContext(UserContext);

    if (_.isEmpty(needCaps)) {
        throw new Error("CapGuard needCaps property was empty");
    }

    return !user.hasCapIn(needCaps) ? (
        <Redirect to={{ redirectTo }} />
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
};

export default CapGuard;
