import React from "react";
import _ from "lodash";
import {
    Grid,
    Input,
    Button,
    TextArea,
    Dropdown,
    Label,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

import { Indicators } from "../../../../../api";
import { genericResponseHandler } from "../../../../../utils";
import { AppContext, UserContext } from "../../../../../state";
import moment from "moment";
import InputNumeric from "../../../../ui/InputNumeric";

/*****************************************************************************/
const Indicator = ({
    indData: current_ind,
    isInApproval,
    considerCalculated = false,
    reporting_period,
    error = null,
}) => {
    const app = React.useContext(AppContext);
    const user = React.useContext(UserContext);
    const ref = React.createRef(null);

    const [frmSavedValue, setFrmSavedValue] = React.useState(undefined);
    const [frmValue, setFrmValue] = React.useState(undefined);
    const [frmTarget, setFrmTarget] = React.useState(undefined);

    const [isSaving, setIsSaving] = React.useState(false);
    const [inputAttribs, setInputAttribs] = React.useState({});
    const [indData, setIndData] = React.useState(current_ind);
    const [errorMessage, setErrorMessage] = React.useState(error);
    const [dataType, setDataType] = React.useState(
        current_ind.data_type.coerce_to
    );

    /* -------------------------------------- */
    React.useEffect(() => {
        if (indData.data) {
            if (!_.isEmpty(indData.selected_data)) {
                setFrmValue(indData.selected_data[0].value);
                setFrmSavedValue(indData.selected_data[0].value);
                setFrmTarget(indData.selected_data[0].target_value);
            } else {
                setFrmValue(undefined);
                setFrmSavedValue(undefined);
                setFrmTarget(undefined);
            }
        }

        setDataType(indData.data_type.coerce_to);

        let attribs = { type: "text" };

        switch (indData.data_type.coerce_to) {
            case "string":
                break;
            case "numeric":
                attribs.type = "number";
                break;
            case "date":
                attribs.type = "date";
                break;
            case "text": // textarea
                attribs.type = "textarea";
                break;
            default:
                throw new Error(`Can't handle ${indData.data_type.coerce_to}`);
        }
        setInputAttribs(attribs);
    }, [indData]); // eslint-disable-line

    /* -------------------------------------- */
    React.useEffect(() => {
        document.addEventListener("updateIndicator", (e) => {
            if (e.value === indData.uuid) {
                reloadIndicatorData();
            }
        });

        document.addEventListener("updateCalculated", (e) => {
            // Am I a calculated type?
            if (indData.calculation !== null) {
                // does this match my default indicator uuid? Refresh me!
                if (indData.calculation.split("/").includes(e.value)) {
                    reloadIndicatorData();
                }
            } else return;
        });
    }, []); // eslint-disable-line

    /* -------------------------------------- */
    const onValueChange = async (e, i, set) => {
        set(e.target.value);
        setErrorMessage(null);
    };

    /* -------------------------------------- */
    const onDateChange = (e, input, set) => {
        if (input.value) {
            set(moment(input.value).format("MM-DD-YYYY"));
        } else {
            set(input.value);
        }
    };

    /* -------------------------------------- */
    const onIndicatorBlur = () => {
        if (frmSavedValue !== frmValue) onCommitSave();
    };

    /* -------------------------------------- */
    async function onEraseEntry() {
        setIsSaving(true);
        // let erase_value = dataType === "date" ? null : "";

        try {
            await Indicators.set_data(
                indData.uuid,
                null,
                undefined,
                reporting_period.reporting_year,
                reporting_period.reporting_period
            );
            setFrmValue(null);
            setFrmSavedValue(null);

            let el = document.getElementById(`indicator-${indData.uuid}`);
            if (el.value) el.value = null;

            broadcastUpdates();
        } catch (err) {
            genericResponseHandler(err, app);
        }

        setIsSaving(false);
    }

    /* -------------------------------------- */
    function broadcastUpdates() {
        if (indData.parent_indicator_uuid) {
            let customEvent = new Event("updateIndicator");
            customEvent.value = indData.parent_indicator_uuid;
            document.dispatchEvent(customEvent);
        }

        // Let any calculated fields enjoy the fruits of my update
        let customEvent = new Event("updateCalculated");
        customEvent.value = indData.default_indicator_uuid;
        document.dispatchEvent(customEvent);
    }

    /* -------------------------------------- */
    const onCommitSave = () => {
        (async () => {
            try {
                setIsSaving(true);

                let value = frmValue;

                if (typeof frmValue === "string") {
                    value = value.trim();
                } else if (dataType === "numeric") {
                    value = null;
                }

                await Indicators.set_data(
                    indData.uuid,
                    value,
                    undefined,
                    reporting_period.reporting_year,
                    reporting_period.reporting_period
                );

                setFrmSavedValue(value);

                broadcastUpdates();
            } catch (err) {
                genericResponseHandler(
                    err,
                    app,
                    {
                        403: "$MSG",
                    },
                    "Error saving data"
                );
            }
            setIsSaving(false);
        })();
    };

    /* -------------------------------------- */
    const onDropdownChange = async (e, input, set) => {
        set(input.value);
    };

    /* --------------------------------------
     *  This is here, and in InputNumeric.js, because inputs don't always
     *  trigger onChange when values actually change, so any input here
     *  will trigger it now.
     */
    const onKeyDown = (ev) => {
        const ne = new Event("change", { bubbles: true });

        if (ref.current.inputRef && ref.current.inputRef.current) {
            ref.current.inputRef.current.dispatchEvent(ne);
        }
        if (ref.current.ref && ref.current.ref.current) {
            ref.current.ref.current.dispatchEvent(ne);
        }
    };

    /* -------------------------------------- */
    const renderFields = (
        placeholder,
        value,
        setvalue,
        savedval,
        icon = ""
    ) => {
        let output;

        if (!_.isEmpty(current_ind.indicator_option_type)) {
            output = (
                <React.Fragment>
                    <Dropdown
                        selection
                        scrolling
                        pointing="top right"
                        fluid
                        placeholder={placeholder}
                        disabled={
                            isInApproval ||
                            !indData.editable ||
                            considerCalculated
                        }
                        id={`indicator-${indData.uuid}`}
                        value={value}
                        className={`${
                            value !== savedval ? "changed-value" : ""
                        }`}
                        options={current_ind.indicator_option_type.options.map(
                            (option) => ({
                                key: option.uuid,
                                text: option.name,
                                value: option.name,
                            })
                        )}
                        onBlur={onIndicatorBlur}
                        onChange={(e, i) => onDropdownChange(e, i, setvalue)}
                    />
                </React.Fragment>
            );
        } else if (considerCalculated || !indData.editable) {
            output = (
                <React.Fragment>
                    <Input
                        disabled
                        fluid
                        icon={icon}
                        placeholder={placeholder}
                        style={{ opacity: 1 }}
                        defaultValue={value}
                        size="small"
                        onBlur={onIndicatorBlur}
                        className="calculated"
                        error={errorMessage}
                        id={`indicator-${indData.uuid}`}
                    />
                </React.Fragment>
            );
        } else {
            switch (dataType) {
                case "date":
                    let dateval = undefined;
                    if (frmValue) {
                        try {
                            dateval = new Date(
                                frmValue.replace(
                                    /(\d{2})-(\d{2})-(\d{4})/,
                                    "$1/$2/$3"
                                )
                            );
                        } catch (err) {
                            console.log(`Error parsing date: ${frmValue}`);
                        }
                    }
                    output = (
                        <SemanticDatepicker
                            disabled={isInApproval}
                            format="MM-DD-YYYY"
                            clearable={false}
                            datePickerOnly
                            error={value === "Invalid date"}
                            fluid
                            placeholder={placeholder}
                            onChange={(e, i) => onDateChange(e, i, setvalue)}
                            onBlur={onIndicatorBlur}
                            value={dateval}
                            id={`indicator-${indData.uuid}`}
                        />
                    );
                    break;

                case "numeric":
                    output = (
                        <InputNumeric
                            disabled={isInApproval}
                            placeholder={placeholder}
                            className={
                                savedval !== value ? "changed-value" : ""
                            }
                            size="small"
                            icon={icon}
                            defaultValue={value}
                            {...inputAttribs}
                            onBlur={onIndicatorBlur}
                            onChange={(e, i) => onValueChange(e, i, setvalue)}
                            error={errorMessage}
                            id={`indicator-${indData.uuid}`}
                        />
                    );
                    break;

                case "text":
                    output = (
                        <TextArea
                            disabled={isInApproval}
                            placeholder={placeholder}
                            icon={icon}
                            defaultValue={value}
                            {...inputAttribs}
                            onBlur={onIndicatorBlur}
                            onKeyDown={onKeyDown}
                            ref={ref}
                            onChange={(e, i) => onValueChange(e, i, setvalue)}
                            className={
                                "textarea-reporting" +
                                (errorMessage ? " error" : "")
                            }
                            style={{
                                display: "inline",
                            }}
                            id={`indicator-${indData.uuid}`}
                        />
                    );
                    break;
                default:
                    output = (
                        <Input
                            disabled={isInApproval}
                            fluid
                            placeholder={placeholder}
                            icon={icon}
                            className={
                                savedval !== value ? "changed-value" : ""
                            }
                            size="small"
                            defaultValue={value}
                            maxLength={
                                indData.data_type &&
                                indData.data_type.name === "Text field" &&
                                255
                            }
                            {...inputAttribs}
                            onBlur={onIndicatorBlur}
                            onKeyDown={onKeyDown}
                            ref={ref}
                            onChange={(e, i) => onValueChange(e, i, setvalue)}
                            error={errorMessage}
                            id={`indicator-${indData.uuid}`}
                        />
                    );
            }
        }
        return output;
    };

    /* -------------------------------------- */
    const renderInput = () => {
        let output = renderFields(
            "",
            frmValue,
            setFrmValue,
            frmSavedValue,
            "pencil"
        );

        /* -------------------------------------- */
        return (
            <Grid columns={2} stretched>
                <Grid.Column style={{ flex: "1 1 auto" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "1 1 50%" }}>{output}</div>
                    </div>
                    <Label.Group size="medium" style={{ marginTop: "0.5rem" }}>
                        {frmTarget && (
                            <Label
                                color="orange"
                                content="Target"
                                icon="target"
                                detail={frmTarget}
                            />
                        )}
                        {indData.baseline && (
                            <Label
                                color="brown"
                                icon="road"
                                detail={indData.baseline.value}
                                content="Baseline"
                            />
                        )}
                    </Label.Group>
                </Grid.Column>
            </Grid>
        );
    };

    /* -------------------------------------- */
    const renderHeader = () => {
        return (
            <Grid columns={3} stackable verticalAlign="middle">
                <Grid.Column width={8}>
                    <label>{current_ind.name}</label>
                </Grid.Column>
                <Grid.Column
                    width={8}
                    className={!indData.editable ? "historical" : ""}
                >
                    <input
                        className={!indData.editable ? "historical" : ""}
                        disabled
                        type="text"
                        value={current_ind.current_value || "--"}
                    />
                </Grid.Column>
            </Grid>
        );
    };

    /* -------------------------------------- */
    const reloadIndicatorData = () => {
        Indicators.get(
            indData.uuid,
            reporting_period.reporting_year,
            reporting_period.reporting_period
        ).then(
            (result) => {
                setIndData(result.data);
            },
            (err) => {
                genericResponseHandler(err, app);
            }
        );
    };

    /* -------------------------------------- */
    const renderEditable = () => {
        return (
            <Grid
                className="indicator-editable"
                stackable
                verticalAlign="middle"
            >
                <Grid.Column width={8}>
                    <label className={errorMessage ? " error" : ""}>
                        {current_ind.name}{" "}
                        {current_ind.required_field && (
                            <span
                                className={"required"}
                                style={{ action: { color: "red" } }}
                            >
                                *
                            </span>
                        )}
                    </label>
                </Grid.Column>
                <Grid.Column
                    style={{ flex: "1 1 auto" }}
                    className={!indData.editable ? "historical" : ""}
                >
                    <div>{renderInput()}</div>
                    <div>
                        {errorMessage && (
                            <Label
                                pointing="above"
                                content={errorMessage}
                                color="red"
                            />
                        )}
                    </div>
                </Grid.Column>

                <Grid.Column style={{ flex: "0 0 8rem" }}>
                    {!(considerCalculated || !indData.editable) && (
                        <Button.Group>
                            <Button
                                icon="eraser"
                                size="tiny"
                                title="Clear"
                                onClick={onEraseEntry}
                                loading={isSaving}
                            />
                            <Button
                                loading={isSaving}
                                disabled={isInApproval}
                                icon="save"
                                primary
                                onClick={onCommitSave}
                            />
                        </Button.Group>
                    )}
                </Grid.Column>
            </Grid>
        );
    };

    /* -------------------------------------- */

    return !user.hasCapIn([
        "own_city_data:edit",
        "all_city_data:edit",
        "specific_intervention:edit",
    ]) ||
        current_ind.is_header ||
        !current_ind.editable
        ? renderHeader()
        : renderEditable();
};

export default Indicator;
