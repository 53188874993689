import React from "react";
import {
    Container,
    Grid,
    Loader,
    Segment,
    Divider,
    Header,
} from "semantic-ui-react";

import { genericResponseHandler } from "../../../../utils";
import { Cities } from "../../../../api";
import { AppContext } from "../../../../state";

import DashboardPeriodSelect from "../ui/DashboardPeriodSelect";
import DashProgramGraph from "./graph/DashProgramGraph";
import { useParams, useHistory } from "react-router-dom";

/* ------------------------------------------------------------------ */
const DashProgramLevel = () => {
    const app = React.useContext(AppContext);
    const history = useHistory();

    const { reporting_year, reporting_period } = useParams();
    const [cityList, setCityList] = React.useState(null);

    /* ------------------------------------------------------------------ */
    // cities
    React.useEffect(() => {
        setCityList(null);
        Cities.list(reporting_year, reporting_period).then(
            (result) => {
                setCityList(result.data.cities);
            },
            (err) => {
                genericResponseHandler(err, app);
            }
        );
    }, [reporting_year, reporting_period]); // eslint-disable-line

    /* ------------------------------------------------------------------ */
    return !cityList ? (
        <Segment placeholder>
            <Loader active content="Loading program cities..." />
        </Segment>
    ) : (
        <Container>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Header
                            as="h1"
                            icon="sitemap"
                            content="Program Overview"
                        />
                    </Grid.Column>
                    <Grid.Column floated="right" width={6}>
                        <DashboardPeriodSelect
                            chosenPeriod={{ reporting_year, reporting_period }}
                            noMonths={true}
                            onPeriodSelected={(year, period) =>
                                history.push(
                                    `/dashboard/program` +
                                        (year ? `/${year}/${period}` : "")
                                )
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Divider />
                        <DashProgramGraph
                            cityList={cityList}
                            reporting_year={reporting_year}
                            reporting_period={reporting_period}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default DashProgramLevel;
