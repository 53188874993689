import React from "react";
import { Container, Header, Grid, Label } from "semantic-ui-react";
import { Bar } from "react-chartjs-2";

import { getCSSVar, colorGrabber, QAV_KEYS } from "../../../../../utils";
import { useHistory } from "react-router-dom";

/* ------------------------------------------------------------------ */
const DashProgramGraph = ({ cityList, reporting_year, reporting_period }) => {
    const history = useHistory();
    const [graphData, setGraphData] = React.useState({});

    const baseGraphConfig = {
        labels: ["Falling Behind", "Marginal", "On Track"],
        datasets: [],
    };

    /* ------------------------------------------------------------------ */
    // cities
    React.useEffect(() => {
        if (!cityList) return;
        rebuildCityDatasets();
    }, [cityList]); // eslint-disable-line

    /* ------------------------------------------------------------------ */
    async function rebuildCityDatasets() {
        let datasets = cityList.map((city) => {
            return {
                label: city.name,
                backgroundColor: colorGrabber(city.uuid),
                hoverBackgroundColor: colorGrabber(city.uuid),
                data: [
                    city.interventions.filter(
                        (int) =>
                            int.current_status === "Approved" &&
                            int.reporting_period &&
                            int.reporting_period.qualitative_indicator ===
                                QAV_KEYS.BAD
                    ).length, // bar 1 - falling behind
                    city.interventions.filter(
                        (int) =>
                            int.current_status === "Approved" &&
                            int.reporting_period &&
                            int.reporting_period.qualitative_indicator ===
                                QAV_KEYS.MED
                    ).length, // bar 2 - marginal
                    city.interventions.filter(
                        (int) =>
                            int.current_status === "Approved" &&
                            int.reporting_period &&
                            int.reporting_period.qualitative_indicator ===
                                QAV_KEYS.GOO
                    ).length, // bar 3 - on track
                ],
            };
        });

        setGraphData({
            ...baseGraphConfig,
            datasets,
        });
    }

    /* ------------------------------------------------------------------ */
    function onLegendNavigate(e, { text: city_name }) {
        const city = cityList.filter((city) => city.name === city_name);
        if (city.length) {
            history.push(`/dashboard/city/${city[0].uuid}`);
        }
    }

    /* ------------------------------------------------------------------ */
    return !cityList ? (
        <React.Fragment></React.Fragment>
    ) : (
        <Container>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Header
                            content={
                                "City Pilot Program Summary " +
                                (reporting_period
                                    ? `(${reporting_year} / ${reporting_period})`
                                    : "(Current Period)")
                            }
                            as="h2"
                        />
                        <Header
                            style={{ fontStyle: "italic" }}
                            content="Number of interventions by status of progress toward program-level goals"
                            as="h3"
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Bar
                            data={graphData}
                            width={100}
                            height={50}
                            options={{
                                maintainAspectRatio: true,
                                tooltips: {
                                    mode: "x",
                                    filter: (item) => item.value > 0,
                                    callbacks: {
                                        label: function (tool, data) {
                                            let label =
                                                data.datasets[tool.datasetIndex]
                                                    .label || "";
                                            const matchingCity = [];
                                            cityList.map((city) => {
                                                if (
                                                    label.split(",")[0] ===
                                                    city.city_name
                                                )
                                                    matchingCity.push(city);
                                            });
                                            let labelString = "";
                                            let interventionNames = "";
                                            const finalCities = matchingCity;
                                            matchingCity.map((x) => {
                                                x.interventions.map((y) => {
                                                    if (
                                                        tool.xLabel ===
                                                            "Falling Behind" &&
                                                        y.reporting_period &&
                                                        y.reporting_period
                                                            .qualitative_indicator !==
                                                            QAV_KEYS.BAD
                                                    ) {
                                                        return false;
                                                    } else if (
                                                        tool.xLabel ===
                                                            "Marginal" &&
                                                        y.reporting_period &&
                                                        y.reporting_period
                                                            .qualitative_indicator !==
                                                            QAV_KEYS.MED
                                                    ) {
                                                        return false;
                                                    } else if (
                                                        tool.xLabel ===
                                                            "On Track" &&
                                                        y.reporting_period &&
                                                        y.reporting_period
                                                            .qualitative_indicator !==
                                                            QAV_KEYS.GOO
                                                    ) {
                                                        return false;
                                                    }
                                                    interventionNames += `${y.name};  `;
                                                });
                                            });
                                            finalCities.map(
                                                (mCity) =>
                                                    (labelString += `${
                                                        mCity.city_name
                                                    } - ${
                                                        tool.yLabel
                                                    } \n ${interventionNames.slice(
                                                        0,
                                                        -3
                                                    )}`)
                                            );
                                            const finalLabel = labelString;
                                            return finalLabel;
                                        },
                                        afterLabel: function (tool, data) {
                                            return "\n";
                                        },
                                    },
                                },
                                legend: {
                                    onClick: onLegendNavigate,
                                    onHover: function (e) {
                                        e.target.style.cursor = "pointer";
                                    },
                                },
                                hover: {
                                    onHover: function (e) {
                                        var point = this.getElementAtEvent(e);
                                        if (point.length)
                                            e.target.style.cursor = "pointer";
                                        else e.target.style.cursor = "default";
                                    },
                                },
                                scales: {
                                    xAxes: [
                                        {
                                            stacked: true,
                                            ticks: {
                                                display: false,
                                                fontColor: getCSSVar(
                                                    " --abif-neutral"
                                                ),
                                                fontSize: 18,
                                                fontStyle: "bold",
                                            },
                                            gridLines: {
                                                display: false,
                                            },
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            scaleLabel: {},
                                            stacked: true,
                                            labels: true,
                                            gridLines: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: true,
                                                callback: function (value) {
                                                    if (value % 1 === 0) {
                                                        return value;
                                                    }
                                                },
                                            },
                                        },
                                    ],
                                },
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className="graph-label">
                        <Label color="red">Falling Behind</Label>

                        <Label color="yellow">Marginal</Label>
                        <Label color="green">On Track</Label>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default DashProgramGraph;
