import { AxiosInstance } from "./index";

/********************************************************************
 * Creates a new user
 * NOTE: New user does not have role assigned; see `create_with_role`
 *
 * @param {*} name_last
 * @param {*} name_first
 * @param {*} email
 * @param {*} password
 */
const create = async (name_last, name_first, email, password) => {
    const response = await AxiosInstance.post("/v1/user", {
        name_last: name_last,
        name_first: name_first,
        email: email,
        password: password,
    });

    return response;
};

/********************************************************************
 * Calls user create, and then assign_role in one call
 */
const create_with_role = async (
    name_last,
    name_first,
    email,
    role_uuid,
    password = ""
) => {
    const create_result = await create(name_last, name_first, email, password);
    const role_result = await assign_role(create_result.data.uuid, role_uuid);
    return role_result;
};

/********************************************************************
 * Cashes in the old token in exchange for a new one; used with the
 * token keepalive heartbeat.
 */
const list = async () => {
    const response = await AxiosInstance.post("/v1/user/list");
    return response;
};

/********************************************************************
 * Fetches a single user record
 * @param {*} uuid
 */
const get = async (uuid) => {
    const response = await AxiosInstance.get(`/v1/user/${uuid}`);
    return response;
};

/********************************************************************
 * Update a user's record
 * @param {Object} user_data Structure mirrors the one you'd get from logging in.
 */
const update = async (uuid, user_data) => {
    const response = await AxiosInstance.post(`/v1/user/${uuid}/`, user_data);
    return response;
};

/********************************************************************
 *
 * @param {*} uuid User UUID
 * @param {*} city_uuid City UUID
 */
const assign_city = async (uuid, city_uuid) => {
    const response = await AxiosInstance.post(`/v1/user/${uuid}/city/assign`, {
        uuid: city_uuid,
        action: "add",
    });
    return response;
};

/********************************************************************
 * Remove city assignment
 * @param {*} uuid User UUID
 * @param {*} city_uuid City UUID
 */
const remove_city = async (uuid, city_uuid) => {
    const response = await AxiosInstance.post(`/v1/user/${uuid}/city/assign`, {
        uuid: city_uuid,
        action: "remove",
    });
    return response;
};

/********************************************************************
 *
 * @param {*} uuid User UUID
 * @param {*} role_uuid City UUID
 */
const assign_role = async (uuid, role_uuid) => {
    const response = await AxiosInstance.post(`/v1/user/${uuid}/role/assign`, {
        uuid: role_uuid,
        action: "add",
    });

    return response;
};

/********************************************************************
 * Remove city assignment
 * @param {*} uuid User UUID
 * @param {*} role_uuid City UUID
 */
const remove_role = async (uuid, role_uuid) => {
    const response = await AxiosInstance.post(`/v1/user/${uuid}/role/assign`, {
        uuid: role_uuid,
        action: "remove",
    });

    return response;
};

export default {
    create,
    create_with_role,
    list,
    get,
    update,
    assign_role,
    remove_role,
    assign_city,
    remove_city,
};
