import { useState, useEffect, useContext } from "react";
import { genericResponseHandler } from "../utils";
import { AppContext } from "../state";
import { User } from "../api";

const useLoadUser = (user_uuid) => {
    const app = useContext(AppContext);

    const [userData, setUserData] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const result = await User.get(user_uuid);
                setUserData(result.data);
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, [user_uuid]); // eslint-disable-line

    function loadUserData(newUserData) {
        setUserData(newUserData);
    }

    return [userData, loadUserData];
};

export default useLoadUser;
