import React, { useContext } from "react";
import _ from "lodash";
import {
    Divider,
    Table,
    Tab,
    Button,
    Container,
    Header,
} from "semantic-ui-react";

import { genericResponseHandler } from "../../../../../../utils";
import CitiesDropdown from "./CitiesDropdown";
import { AppContext } from "../../../../../../state";
import { User } from "../../../../../../api";

/*****************************************************************************
 * props: userData - expects the user data object from the UserEdit component
 *        onModified - parent callback when city data is updated; will provide
 *                     updated user object
 */
const UserCitiesPane = ({ userData, onModified }) => {
    const app = useContext(AppContext);

    const onRemoveCity = async (city_uuid) => {
        try {
            const result = await User.remove_city(userData.uuid, city_uuid);
            if (onModified) {
                onModified(result.data);
            }
        } catch (err) {
            genericResponseHandler(err, app);
        }
    };

    const onAssignCity = async (city_uuid) => {
        try {
            const result = await User.assign_city(userData.uuid, city_uuid);
            if (onModified) {
                onModified(result.data);
            }
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    409: "User already assigned to this city"
                },
                "Error saving data"
            );
        }
    };

    return (
        <Tab.Pane key={userData}>
            <Table basic="very" striped>
                <Table.Body>
                    {_.isEmpty(userData.cities) ? (
                        <Table.Row>
                            <Table.Cell>
                                <Header
                                    as="h3"
                                    color="red"
                                    content="No cities assigned."
                                    textAlign="center"
                                />
                            </Table.Cell>
                        </Table.Row>
                    ) : (
                        userData.cities.map((city_entry) => {
                            return (
                                <Table.Row key={city_entry.uuid}>
                                    <Table.Cell>
                                        <Header
                                            as="h3"
                                            content={city_entry.name}
                                        />
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">
                                        <Button
                                            icon="trash"
                                            negative
                                            onClick={() =>
                                                onRemoveCity(city_entry.uuid)
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })
                    )}
                </Table.Body>
            </Table>
            <Divider />
            <Container textAlign="right">
                <CitiesDropdown onAddCityClicked={onAssignCity} />
            </Container>
        </Tab.Pane>
    );
};

export default UserCitiesPane;
