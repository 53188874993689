import React, { useContext } from "react";
import _ from "lodash";
import {
    Loader,
    Segment,
    Divider,
    Button,
    Header,
    Grid,
    Tab,
    Container,
    Form,
    Modal,
} from "semantic-ui-react";

import { User } from "../../../../../api";
import { genericResponseHandler } from "../../../../../utils";
import { AppContext } from "../../../../../state/AppProvider";
import useLoadUser from "../../../../../hooks/useLoadUser";

import UserStats from "./UserStats";
import UserRolesPane from "./tab_panes/UserRolesPane";
import UserCitiesPane from "./tab_panes/UserCitiesPane";
import { useHistory } from "react-router-dom";

/*---------------------------------------------------------------------------*/
const UserEdit = ({ match }) => {
    const history = useHistory();
    const app = useContext(AppContext);
    const user_uuid = match.params.uuid;

    const [userData, setUserData] = useLoadUser(user_uuid);
    const [isSavingDetails, setIsSavingDetails] = React.useState(false);
    const [showDetailsSaved, setShowDetailsSaved] = React.useState(false);

    const tabPanes = [
        {
            menuItem: { key: "users", icon: "tags", content: "Roles" },
            render: () => (
                <UserRolesPane
                    key={userData}
                    userData={userData}
                    onModified={(newUserData) => setUserData(newUserData)}
                />
            ),
        },
        {
            menuItem: { key: "cities", icon: "globe", content: "Cities" },
            render: () => (
                <UserCitiesPane
                    userData={userData}
                    onModified={(newUserData) => setUserData(newUserData)}
                />
            ),
        },
    ];

    /* ------------------------------------------------------- */
    const onSaveUser = async () => {
        try {
            setIsSavingDetails(true);
            const result = await User.update(user_uuid, {
                name_first: userData.name_first.trim(),
                name_last: userData.name_last.trim(),
                email: userData.email.trim(),
                active: !!userData.active,
            });
            setUserData(result.data);
            setShowDetailsSaved(true);
        } catch (err) {
            genericResponseHandler(err, app);
        }
        setIsSavingDetails(false);
    };

    /* ------------------------------------------------------- */
    return _.isEmpty(userData) ? (
        <Segment placeholder>
            <Loader content="Loading..." active />
        </Segment>
    ) : (
        <React.Fragment>
            <Segment.Group color="blue" stacked>
                <Segment>
                    <Grid columns={2} divided stackable>
                        <Grid.Column>
                            <Header
                                as="h1"
                                size="huge"
                                icon="user"
                                content={userData.name}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <UserStats userData={userData} />
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as="h2" content="Details" />
                    <Form>
                        <Form.Input
                            label="First Name"
                            value={userData.name_first}
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    name_first: e.target.value,
                                });
                            }}
                        />
                        <Form.Input
                            label="Last Name"
                            value={userData.name_last}
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    name_last: e.target.value,
                                });
                            }}
                        />

                        <Form.Input
                            label="Email"
                            value={userData.email}
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    email: e.target.value,
                                });
                            }}
                        />
                        <Form.Checkbox
                            toggle
                            label="Active"
                            name="active"
                            checked={userData.active}
                            onChange={(e, data) => {
                                setUserData({
                                    ...userData,
                                    active: data.checked,
                                });
                            }}
                        />

                        <Container textAlign="right">
                            <Button
                                primary
                                loading={isSavingDetails}
                                onClick={() => onSaveUser()}
                                content="Save Details"
                            />
                        </Container>
                    </Form>
                    <Divider />
                    <Tab panes={tabPanes} />
                </Segment>
                <Segment textAlign="right">
                    <Button
                        icon="left arrow"
                        content="Back to User List"
                        onClick={() => history.push("/admin/users")}
                    />
                </Segment>
            </Segment.Group>
            <Modal
                open={showDetailsSaved}
                onClose={() => setShowDetailsSaved(false)}
                icon="users"
                size="mini"
                content="User details saved."
                actions={["Okay"]}
            />
        </React.Fragment>
    );
};
//
export default UserEdit;
