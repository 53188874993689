import { AxiosInstance } from "./index";
import _ from "lodash";

/********************************************************************
 * Creates indicator
 * @param {*} options
 *
 * Note - will return 201 on success
 */
const create = async (options, parent_indicator_uuid = null) => {
    var default_options = [
        "name",
        "cadence_type",
        "data_type",
        "intervention",
        "required_baseline",
        "required_field",
        "required_target",
    ].sort();

    if (!_.isEqual(_.keys(options).sort(), default_options)) {
        throw new Error("Missing required option fields for API call");
    }

    var response = await AxiosInstance.post("/v1/indicator", {
        ...options,
    });

    if (parent_indicator_uuid) {
        await create_subindicator(response.data.uuid, parent_indicator_uuid);
    }

    return response;
};

/********************************************************************
 * Get individual indicator
 * @param {*} intervention_uuid
 * @param {*} reporting_year Optionally pull a specific reporting period (requires `reporting_period`)
 * @param {*} reporting_period
 *
 * Note - will return 201 on success
 */
const get = async (
    indicator_uuid,
    reporting_year = undefined,
    reporting_period = undefined
) => {
    let options = {};
    if (reporting_year && reporting_period) {
        options = { params: { reporting_year, reporting_period } };
    }

    var response = await AxiosInstance.get(
        `/v1/indicator/${indicator_uuid}`,
        options
    );
    return response;
};

/********************************************************************
 * Delete indicator
 * @param {*} intervention_uuid
 *
 * Note - will return 201 on success
 */
const delete_indicator = async (indicator_uuid) => {
    var response = await AxiosInstance.delete(
        `/v1/indicator/${indicator_uuid}`
    );
    return response;
};

/********************************************************************
 * Get all of the default indicators
 */
const get_default_indicators = async () => {
    var response = await AxiosInstance.get("/v1/indicator/defaults");
    return response;
};

/********************************************************************
 * Adds default indicator to intervention
 * @param {*} intervention_uuid Intervention UUD
 * @param {*} default_ind_uuid Default indicator UUID
 */
const add_default_indicator = async (intervention_uuid, default_ind_uuid) => {
    var response = await AxiosInstance.post(
        `/v1/intervention/${intervention_uuid}/add_default`,
        {
            uuid: default_ind_uuid,
        }
    );
    return response;
};

/********************************************************************
 * Updates indicator baseline
 * @param {*} intervention_uuid
 * @param {*} value
 *
 * Note - will return 201 on success
 */
const set_baseline = async (indicator_uuid, value = undefined) => {
    var response = await AxiosInstance.post(
        `/v1/indicator/${indicator_uuid}/baseline`,
        {
            value: value,
        }
    );
    return response;
};

/********************************************************************
 * Indicator update data and target values
 * @param {string} indicator_uuid
 * @param {*} data_value (optional, but must be this or target_value)
 * @param {*} target_value (optional,but must be this or data_value)
 *
 * (Optional, but requires both period and year values, otherwise
 *  submission is for current year/period.)
 *
 * @param {string} reporting_year
 * @param {string} reporting_period Q1, M03, etc.
 *
 * Note - will return 201 on success
 */
const set_data = async (
    indicator_uuid,
    data_value = undefined,
    target_value = undefined,
    reporting_year = undefined,
    reporting_period = undefined,
    is_date = true
) => {
    let options = {};

    if (data_value !== undefined) options = { ...options, data_value };
    if (target_value !== undefined) options = { ...options, target_value };

    // Always treat an empty string like we're clearing out the value
    if (data_value === "")
        options = { ...options, data_value: is_date ? "" : null };
    if (target_value === "")
        options = { ...options, target_value: is_date ? "" : null };

    // if we don't get both, it defaults to current reporting period
    if (reporting_year && reporting_period) {
        options = {
            ...options,
            reporting_period,
            reporting_year,
        };
    }

    var response = await AxiosInstance.post(
        `/v1/indicator/${indicator_uuid}/data`,
        options
    );

    return response;
};

/********************************************************************
 * Delete target value
 * @param {string} indicator_uuid
 *
 * (Optional, but requires both period and year values, otherwise
 *  submission is for current year/period.)
 *
 * @param {string} reporting_year
 * @param {string} reporting_period Q1, M03, etc.
 *
 * Note - will return 201 on success
 */
const delete_target = async (
    indicator_uuid,
    reporting_year = undefined,
    reporting_period = undefined
) => {
    let options = {
        target_value: null,
    };

    // if we don't get both, it defaults to current reporting period
    if (reporting_year && reporting_period) {
        options = {
            ...options,
            reporting_period,
            reporting_year,
        };
    }

    var response = await AxiosInstance.post(
        `/v1/indicator/${indicator_uuid}/data`,
        options
    );
    return response;
};

/********************************************************************
 * Create Subindicator
 * @param {*} intervention_uuid
 * @param {*} value
 *
 * Note - will return 201 on success
 */
const create_subindicator = async (indicator_uuid, parent_indicator_uuid) => {
    var response = await AxiosInstance.post(
        `/v1/indicator/${indicator_uuid}/parent`,
        {
            uuid: parent_indicator_uuid,
        }
    );
    return response;
};

export default {
    create,
    get,
    delete_indicator,
    delete_target,
    get_default_indicators,
    add_default_indicator,
    set_data,
    set_baseline,
    create_subindicator,
};
