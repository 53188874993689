import React from "react";
import _ from "lodash";
import { Container, Header, Grid } from "semantic-ui-react";
import { Line } from "react-chartjs-2";

import { getCSSVar, sortIntPeriods } from "../../../../../utils";

const DashIntGraphProgressOverTime = ({ intData }) => {
    const [canGraph, setCanGraph] = React.useState(false);
    const [graphData, setGraphData] = React.useState(undefined);

    const baseGraphConfig = {
        labels: [],
        datasets: [],
    };

    /* ------------------------------- */
    React.useEffect(() => {
        // early bails if we don't have data to chart
        if (_.isEmpty(intData.primary_indicators)) return;
        if (_.isEmpty(intData.reporting_periods)) return;

        setCanGraph(true);

        let periods = [];

        // food for the x-axis
        sortIntPeriods(intData);
        intData.reporting_periods.map((rp) => {
            if (rp.status !== "Approved") return undefined;
            if (rp.period === "All") return undefined;
            if (rp.period[0] === "M") return undefined;
            periods.push(`${rp.year} / ${rp.period}`);
        });

        buildDatasets();
    }, [intData]); // eslint-disable-line

    /* ------------------------------- */
    function buildDatasets() {
        const color_actual = getCSSVar("--abif-jeans");
        const color_target = getCSSVar("--abif-primary-red");
        let datasets = [];

        const y_actual = {
            label: `Actual`,
            fill: false,
            borderColor: color_actual,
            backgroundColor: color_actual,
            data: [],
        };

        const y_target = {
            label: `Target`,
            fill: false,
            borderColor: color_target,
            backgroundColor: color_target,
            data: [],
        };

        const labels = [];

        // build our y-series
        for (let rp in intData.reporting_periods) {
            const reporting_year = intData.reporting_periods[rp].year;
            const reporting_period = intData.reporting_periods[rp].period;

            if (intData.reporting_periods[rp].status !== "Approved") continue;
            if (reporting_period === "All") continue;
            if (reporting_period[0] === "M") continue;

            labels.push(`${reporting_year} / ${reporting_period}`);

            const { value, target_value } =
                _.find(intData.primary_indicators[0].data, {
                    reporting_period,
                    reporting_year,
                }) || {};

            y_actual.data.push(value);
            y_target.data.push(target_value);
        }
        datasets.push(y_actual);
        datasets.push(y_target);

        setGraphData({
            ...baseGraphConfig,
            labels,
            datasets,
        });
    }

    /* ------------------------------- */
    /* ------------------------------- */
    return !(canGraph && intData && graphData) ? (
        <React.Fragment></React.Fragment>
    ) : (
        <Container>
            <Header
                content={
                    intData.primary_indicators[0] &&
                    intData.primary_indicators[0].name
                }
                as="h3"
                icon="line graph"
            />
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={1}>
                        <p className="yaxis">
                            {intData.primary_indicators[0] && intData.primary_indicators[0].name}
                        </p>
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <Line
                            data={graphData}
                            width={100}
                            height={50}
                            options={{
                                maintainAspectRatio: true,
                                tooltips: {
                                    mode: "x",
                                    // filter: (item) => item.value > 0,
                                },
                                legend: {
                                    onHover: function (e) {
                                        e.target.style.cursor = "pointer";
                                    },
                                },
                                hover: {
                                    onHover: function (e) {
                                        var point = this.getElementAtEvent(e);
                                        if (point.length)
                                            e.target.style.cursor = "pointer";
                                        else e.target.style.cursor = "default";
                                    },
                                },

                                scales: {
                                    xAxes: [
                                        {
                                            ticks: {
                                                display: true,
                                                fontColor: getCSSVar(
                                                    " --abif-neutral"
                                                ),
                                                fontSize: 18,
                                                fontStyle: "bold",
                                            },
                                            gridLines: {
                                                display: true,
                                            },
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            labels: false,
                                            scaleLabel: {
                                                display: false,
                                                labelString: ``,
                                            },
                                            gridLines: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: true,
                                            },
                                        },
                                    ],
                                },
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default DashIntGraphProgressOverTime;
