import React from "react";
import _ from "lodash";
import { QAV_KEYS } from "../../../../utils";
import { Label } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../../state";

const QAVDot = ({ value, targetIntUUID }) => {
    const history = useHistory();
    const user = React.useContext(UserContext);

    let color = undefined; // will default to light grey if absent

    if (value === QAV_KEYS.BAD) {
        color = "red";
    } else if (value === QAV_KEYS.MED) {
        color = "yellow";
    } else if (value === QAV_KEYS.GOO) {
        color = "green";
    }

    return (
        <React.Fragment>
            <Label
                className="qavdot"
                title={value}
                color={color}
                circular
                size="massive"
                onClick={
                    user.hasCap("own_city_indicator:view")
                        ? () =>
                              history.push(
                                  `/dashboard/intervention/${targetIntUUID}`
                              )
                        : undefined
                }
            />
        </React.Fragment>
    );
};

export default QAVDot;
