import React from "react";
import _ from "lodash";
import { UserContext } from "../../state";

/**
 * Renders the inner children of the component only if the current logged in
 * user has any of the supplied caps.
 *
 * Props:
 * - `needCaps` an array of required permissions
 */
const IfHaveCapIn = ({ children, needCaps = [] }) => {
    const user = React.useContext(UserContext);

    if (_.isEmpty(needCaps)) {
        throw new Error("IfHaveCapIn needCaps property was empty");
    }

    return (
        <React.Fragment>{user.hasCapIn(needCaps) && children}</React.Fragment>
    );
};

export default IfHaveCapIn;
