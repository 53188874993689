import React from "react";
import DropdownFromSource from "../../../../ui/DropdownFromSource";
import { AppContext } from "../../../../../state";
import { Interventions } from "../../../../../api";
import { genericResponseHandler } from "../../../../../utils";

const DefaultInterventionTypeDropdown = ({
    onSelect,
    placeholder = "Select a default type...",
    buttonText = "Add Default Type",
    isInApproval,
}) => {
    const app = React.useContext(AppContext);

    const [data, setData] = React.useState({});

    React.useEffect(() => {
        (async () => {
            try {
                const result = await Interventions.list_types();
                setData(
                    result.data.map((type) => {
                        return {
                            text: type.name,
                            value: type.uuid,
                        };
                    })
                );
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, []); // eslint-disable-line

    return (
        <DropdownFromSource
            onSelect={onSelect}
            placeholder={placeholder}
            buttonText={buttonText}
            dataSource={data}
            isInApproval={isInApproval}
        />
    );
};

export default DefaultInterventionTypeDropdown;
