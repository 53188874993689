import React from "react";
import ReactDOM from "react-dom";
import { setupAxios } from "./api";

import { AppProvider, UserProvider } from "./state";

import App from "./components/App";

setupAxios();

ReactDOM.render(
    <AppProvider>
        <UserProvider>
            <App />
        </UserProvider>
    </AppProvider>,
    document.querySelector("#root")
);
