import React from "react";
import _ from "lodash";
import { Interventions } from "../../../../api";
import { AppContext, UserContext } from "../../../../state";
import {
    genericResponseHandler,
    STATUS_COLOR,
    QAV_UUID,
} from "../../../../utils";
import {
    Segment,
    Header,
    Grid,
    Table,
    Label,
    Divider,
    Button,
    Container,
    Loader,
    Confirm,
    Modal,
    Icon,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import IndicatorRow from "./IndicatorRow";
import InterventionApi from "../../../../api/interventions";
import ApprovalBlock from "./ui/ApprovalBlock";
import IfHaveCapIn from "../../../ui/IfHaveCapIn";
import IndicatorPeriodSelect from "./ui/InterventionPeriodSelect";

/*****************************************************************************/
const Intervention = ({ intUUID, reporting_year, reporting_period }) => {
    const app = React.useContext(AppContext);
    const user = React.useContext(UserContext);
    const history = useHistory();

    const [intData, setIntData] = React.useState();
    const [qavField, setQavField] = React.useState(null);
    const [chosenPeriod, setChosenPeriod] = React.useState({
        reporting_period,
        reporting_year,
    });
    const [isSaving, setIsSaving] = React.useState(false);
    const [isReadyForApproval, setIsReadyForApproval] = React.useState(false);
    const [openCancelApproval, setOpenCancelApproval] = React.useState(false);
    const [
        showModalValidationError,
        setShowModalValidationError,
    ] = React.useState(false);
    const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const [validationErrors, setValidationErrors] = React.useState({});

    /* ---------------------------------------------------------- */
    // pull in intervention
    React.useEffect(() => {
        (async () => {
            setIntData(undefined);
            try {
                if (chosenPeriod) {
                    var year = chosenPeriod.reporting_year;
                    var period = chosenPeriod.reporting_period;
                }

                // year/period will be "undefined" if !chosenPeriod
                const response = await Interventions.get(intUUID, year, period);

                if (!(reporting_period && reporting_year)) {
                    setChosenPeriod({
                        reporting_year:
                            (response.data.reporting_period &&
                                response.data.reporting_period.year) ||
                            null,
                        reporting_period:
                            (response.data.reporting_period &&
                                response.data.reporting_period.period) ||
                            null,
                    });
                }

                // extract the QAV
                let qav_index = _.findIndex(response.data.indicators, {
                    default_indicator_uuid: QAV_UUID,
                });
                if (qav_index >= 0) {
                    const qav = response.data.indicators.splice(qav_index, 1);
                    setQavField(qav[0]);
                }

                setIntData(response.data);
                setIsReadyForApproval(
                    response.data.current_status !== "Draft" &&
                        response.data.current_status !== "Approved"
                );
                //
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, [intUUID, reporting_period, reporting_year, ignored]); // eslint-disable-line

    /* ---------------------------------------------------------- */
    function processValidationMessages(validation_data) {
        const newval = {};
        validation_data.map((err) => {
            newval[err.uuid] = err.message;
        });
        setValidationErrors(newval);
        setShowModalValidationError(true);
        forceUpdate();
    }

    /* ---------------------------------------------------------- */
    const onSubmissionMonthly = async () => {
        setIsSaving(true);
        try {
            if (chosenPeriod) {
                await InterventionApi.submit_monthly(
                    intUUID,
                    chosenPeriod.reporting_year,
                    chosenPeriod.reporting_period
                );
            } else {
                await InterventionApi.submit_monthly(intUUID);
            }
            setIsReadyForApproval(true);
            setValidationErrors({});
            forceUpdate();
        } catch (err) {
            if (err.response.status === 400) {
                processValidationMessages(err.response.data);
            } else {
                genericResponseHandler(err, app);
            }
        }
        setIsSaving(false);
    };

    /* ---------------------------------------------------------- */
    const onSubmissionQuarterly = async () => {
        setIsSaving(true);
        try {
            if (chosenPeriod) {
                await InterventionApi.submit(
                    intUUID,
                    false,
                    chosenPeriod.reporting_year,
                    chosenPeriod.reporting_period
                );
            } else {
                await InterventionApi.submit(intUUID);
            }
            setIsReadyForApproval(true);
            setValidationErrors({});
            forceUpdate();
        } catch (err) {
            if (err.response.status === 400) {
                processValidationMessages(err.response.data);
            } else {
                genericResponseHandler(err, app);
            }
        }
        setIsSaving(false);
    };

    /* ---------------------------------------------------------- */
    const renderIndicators = () => {
        if (_.isEmpty(intData.indicators))
            return (
                <Table.Row>
                    <Table.Cell colSpan="10" textAlign="center">
                        <Header content="No indicators." color="red" as="h2" />
                    </Table.Cell>
                </Table.Row>
            );

        // get parents
        const parents = intData.indicators.filter(
            (ind) => ind.parent_indicator_uuid === null
        );

        let parent_ids = {};

        // get children
        let children = intData.indicators.filter((ind) => {
            if (ind.parent_indicator_uuid !== null)
                parent_ids[ind.parent_indicator_uuid] = true;

            return ind.parent_indicator_uuid !== null;
        });

        let output = [];

        parents.forEach((parent) => {
            // `isConsiderCalculated` currently goes true if any children
            // claim them as a parent; if not we'll let it go as a regular
            // field

            let isConsiderCalculated = parent_ids[parent.uuid];

            if (isConsiderCalculated) {
                // now we have to see if any of those children actually have
                // data in them. If so, we can disown them and not need to
                // be isConsideredCalculated
                let has_data =
                    intData.indicators.filter((ind) => {
                        if (ind.parent_indicator_uuid === parent.uuid) {
                            return !!ind.current_value;
                        }
                    }).length > 0;

                if (!has_data) isConsiderCalculated = false;
            }

            output.push(
                <IndicatorRow
                    key={parent.uuid}
                    indData={parent}
                    isInApproval={isReadyForApproval}
                    considerCalculated={isConsiderCalculated}
                    error={validationErrors[parent.uuid] || undefined}
                    reporting_period={chosenPeriod}
                />
            );

            // look for any children claiming this indicator to be their parent,
            // attach them next

            var mychildrens = null;
            while (
                !_.isEmpty(
                    (mychildrens = _.remove(
                        children,
                        (child) => child.parent_indicator_uuid === parent.uuid
                    ))
                )
            ) {
                _.each(mychildrens, (child) =>
                    output.push(
                        <IndicatorRow
                            key={child.uuid}
                            indData={child}
                            isInApproval={isReadyForApproval}
                            error={validationErrors[child.uuid] || undefined}
                            reporting_period={chosenPeriod}
                        />
                    )
                );
            }
        });

        return output;
    };

    /* ---------------------------------------------------------- */
    const onCancelApprovalRequested = async () => {
        setIsSaving(true);
        try {
            if (chosenPeriod) {
                await InterventionApi.submit(
                    intUUID,
                    true,
                    chosenPeriod.reporting_year,
                    chosenPeriod.reporting_period
                );
            } else {
                await InterventionApi.submit(intUUID, true);
            }
            setOpenCancelApproval(false);
            setIsReadyForApproval(true);
            forceUpdate();
        } catch (err) {
            genericResponseHandler(err, app);
        }
        setIsSaving(false);
    };

    /* ---------------------------------------------------------- */
    function onPeriodChange(year, period) {
        if (year === undefined || period === undefined) {
            setChosenPeriod(undefined);
            history.push(`/intervention/${intUUID}`);
        } else {
            setChosenPeriod({ reporting_year: year, reporting_period: period });
            history.push(`/intervention/${intUUID}/${year}/${period}`);
        }
        forceUpdate();
    }

    const instructionalText = (
        <div>
            Enter data specific to this period. For example:
            <ul>
                <li>
                    If 10 project champions were recruited last month and 10
                    project champions recruited this month, enter 10 for this
                    month
                </li>
                <li>
                    If there were 3000 adults in catchment area last month and
                    3050 this month, enter 3050 for this month
                </li>
                <li>
                    If there were 20% of people who supported a legislative
                    change last month and 30% this month, then enter 30% for
                    this month
                </li>
            </ul>
        </div>
    );

    /* ---------------------------------------------------------- */
    return (
        <Segment className="reporting-intervention-page">
            {_.isEmpty(intData) ? (
                <Segment placeholder>
                    <Loader active content="Loading intervention data..." />
                </Segment>
            ) : (
                <React.Fragment>
                    <Grid columns={2} stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Header
                                    as="h1"
                                    content={`Intervention Report: ${intData.name}`}
                                    icon="crosshairs"
                                    color="violet"
                                />
                                <Header
                                    as="h4"
                                    content={intData.city.name}
                                    icon="building"
                                />
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Button.Group>
                                    <IfHaveCapIn
                                        needCaps={[
                                            "own_city_intervention:edit",
                                            "all_city_intervention:edit",
                                        ]}
                                    >
                                        <Button
                                            icon="pencil"
                                            title="Intervention Setup"
                                            onClick={() => {
                                                history.push(
                                                    `/intervention/${intData.uuid}/edit`
                                                );
                                            }}
                                        />
                                    </IfHaveCapIn>
                                    <Button
                                        icon="left arrow"
                                        content="Back to My Interventions"
                                        onClick={() => {
                                            history.push("/myinterventions");
                                        }}
                                    />
                                </Button.Group>
                                <br />
                                <br />
                                {!_.isEmpty(intData.types) &&
                                    intData.types.map((type) => (
                                        <Label
                                            key={type.uuid}
                                            content={type.name}
                                            color="green"
                                        />
                                    ))}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Divider fitted />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Label
                                    content={
                                        "Before entering data, please choose the correct year and period for that data and click the purple button with the arrow to load the page for that time."
                                    }
                                    size={"large"}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <IndicatorPeriodSelect
                                    indData={intData}
                                    onPeriodSelected={onPeriodChange}
                                    chosenPeriod={chosenPeriod}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign="right" width={9}>
                                {intData.current_status !== "Draft" ? (
                                    <React.Fragment>
                                        <Container>
                                            <Label
                                                content={
                                                    (intData.current_status ===
                                                    "Reviewed"
                                                        ? "Under "
                                                        : "Is ") +
                                                        (intData.current_status ===
                                                        "Reviewed"
                                                            ? "Review"
                                                            : intData.current_status) ||
                                                    "--"
                                                }
                                                loading={
                                                    isSaving ? true : undefined
                                                }
                                                icon="clock"
                                                size="large"
                                                color={
                                                    STATUS_COLOR[
                                                        intData.current_status
                                                    ]
                                                }
                                            />
                                            {intData.current_status !==
                                                "Draft" && (
                                                <Button
                                                    content="Cancel / Edit"
                                                    loading={isSaving}
                                                    icon="x"
                                                    color="grey"
                                                    size="tiny"
                                                    onClick={() =>
                                                        setOpenCancelApproval(
                                                            true
                                                        )
                                                    }
                                                />
                                            )}
                                        </Container>
                                        <br />
                                        <Container>
                                            {chosenPeriod.reporting_period.includes(
                                                "Q"
                                            ) && (
                                                <ApprovalBlock
                                                    intData={intData}
                                                    qavField={qavField}
                                                    setQavField={setQavField}
                                                    onApprovalChange={() => {
                                                        forceUpdate();
                                                    }}
                                                    chosenPeriod={chosenPeriod}
                                                />
                                            )}
                                        </Container>
                                        <br />
                                    </React.Fragment>
                                ) : (
                                    user.hasCapIn([
                                        "own_city_data:edit",
                                        "all_city_data:edit",
                                        "specific_intervention:edit",
                                    ]) && (
                                        <React.Fragment>
                                            <div className="submission-spacer">
                                                <Button
                                                    className={
                                                        chosenPeriod.reporting_period.includes(
                                                            "M"
                                                        )
                                                            ? ""
                                                            : "hidden"
                                                    }
                                                    content="Submit Monthly Data"
                                                    loading={isSaving}
                                                    icon="th"
                                                    disabled={isSaving}
                                                    color="orange"
                                                    onClick={
                                                        onSubmissionMonthly
                                                    }
                                                />
                                                <Button
                                                    className={
                                                        chosenPeriod.reporting_period.includes(
                                                            "Q"
                                                        )
                                                            ? ""
                                                            : "hidden"
                                                    }
                                                    content="Submit Quarterly Data for Approval"
                                                    loading={isSaving}
                                                    icon="balance scale"
                                                    disabled={isSaving}
                                                    color="blue"
                                                    onClick={
                                                        onSubmissionQuarterly
                                                    }
                                                />
                                            </div>
                                        </React.Fragment>
                                    )
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br />
                    <Container>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Label
                                    content={instructionalText}
                                    size="large"
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Table color="yellow" celled structured striped compact>
                            <Table.Body>{renderIndicators()}</Table.Body>
                        </Table>
                    </Container>
                    <Confirm
                        open={openCancelApproval}
                        header="Are you sure?"
                        content="You are about to take this intervention out of the approval process and revert it to a draft state."
                        confirmButton="Yes, I'm sure."
                        cancelButton="No, leave it."
                        onCancel={() => setOpenCancelApproval(false)}
                        onConfirm={onCancelApprovalRequested}
                        closeOnEscape
                    />
                </React.Fragment>
            )}
            <Modal
                open={showModalValidationError}
                onClose={() => setShowModalValidationError(false)}
            >
                <Modal.Header>
                    <Icon name="x" color="red" /> Validation Error
                </Modal.Header>
                <Modal.Content>
                    The data submitted has validation errors. Please review the
                    data and submit again.
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        icon="checkmark"
                        content="OK"
                        onClick={() => setShowModalValidationError(false)}
                    />
                </Modal.Actions>
            </Modal>
        </Segment>
    );
};

export default Intervention;
