import React from "react";
import { Menu, Dropdown } from "semantic-ui-react";
import { AppContext } from "../../../../state";
import { Interventions, Cities } from "../../../../api";
import { genericResponseHandler } from "../../../../utils";
import { useHistory } from "react-router-dom";

const DashboardMenu = () => {
    const app = React.useContext(AppContext);
    const history = useHistory();

    const [intTypes, setIntTypes] = React.useState([]);
    const [cities, setCities] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            try {
                let response = await Interventions.list_types();
                setIntTypes(response.data);

                response = await Cities.list();
                setCities(
                    response.data.cities.sort((a, b) => {
                        return a.city_name > b.city_name ? 1 : -1;
                    })
                );
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, []); // eslint-disable-line

    return (
        <Menu inverted>
            <Menu.Item onClick={() => history.push(`/`)}>Program</Menu.Item>
            <Dropdown item text="Cities">
                <Dropdown.Menu>
                    {cities.map((type, i) => (
                        <Dropdown.Item
                            key={i}
                            onClick={() =>
                                history.push(`/dashboard/city/${type.uuid}`)
                            }
                        >
                            {type.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown item text="Intervention Types">
                <Dropdown.Menu>
                    {intTypes.map((type, i) => (
                        <Dropdown.Item
                            key={i}
                            onClick={() =>
                                history.push(
                                    `/dashboard/intervention_type/${type.uuid}`
                                )
                            }
                        >
                            {type.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Menu>
    );
};

export default DashboardMenu;
