import config from "./config";
import moment from "moment";
import _ from "lodash";

/********************************************************************
 * Returns a CSS variable defined in the stylesheet
 * This is primarily used for extracting "--colorname" variable contents
 * @param {*} name CSS property name
 */
export const getCSSVar = (name) =>
    getComputedStyle(document.documentElement).getPropertyValue(name);

/********************************************************************
 * Converts a string into word-caps-case.
 * @param {*} string
 */
function capitalize(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/********************************************************************
 *
 * @param {*} city_uuid
 */
export const colorGrabber = (city_uuid) => {
    let color = "";
    switch (city_uuid) {
        case "709cb030-07c6-4d52-90da-c080d16875d6":
            color = getCSSVar("--abif-primary-red");
            break;
        case "783a9219-3a54-436d-a297-1f8a252b7d1d":
            color = getCSSVar("--abif-primary-yellow");
            break;
        case "f7070f11-69fb-45cc-a953-0961f41b3626":
            color = getCSSVar("--abif-primary-pink");
            break;
        case "f730496e-5f65-4d01-aeb9-a221660ae763":
            color = getCSSVar("--abif-secondary-brown");
            break;
        case "c54216bb-bfcc-4cf5-b10f-a2709d9213b0":
            color = getCSSVar("--abif-secondary-gray");
            break;
        case "dec97b99-b02e-48d8-8c59-7e526d6ba22e":
            color = getCSSVar("--abif-secondary-orange");
            break;
        default:
            const colors = [
                "--abif-salmon",
                "--abif-beef",
                "--abif-marzipan",
                "--abif-mauvelous",
                "--abif-mandy",
                "--abif-jeans",
            ];
            color = getCSSVar(
                colors[Math.floor(Math.random() * colors.length)]
            );
    }
    return color;
};

/********************************************************************
 * This is a generic network response handler that will give
 * graceful or aggressive errors depending on the env setting.
 *
 * Example: `genericResponseHandler(err, app, {418:"Earl grey. Hot.", 451: "Too hot."}, "Thirsty Error")`
 *
 * @param {*} err The error response direct from the catch()
 * @param {*} app_context The app context so we can call showError
 * @param {Object?} exceptions An optional object containing overrides for any number of given status codes.
 * @param {string?} heading An optional string given for matching exception codes; defaults to "Connection Problem"
 */
export const genericResponseHandler = (
    err,
    app_context,
    exceptions = {},
    heading = "Error"
) => {
    if (!err || !err.response || !err.response.status) {
        app_context.showError(
            `There was a problem connecting to the server. Notify the administrator.`
        );
        console.error("Server Error", err);
        return;
    }

    const errmsg = capitalize(err.response.data.message);

    console.error("Error response", err.response.data);

    if (typeof exceptions[err.response.status] === "function") {
        app_context.showError(
            exceptions[err.response.status](err, app_context),
            heading
        );
    } else if (typeof exceptions[err.response.status] === "string") {
        app_context.showError(
            exceptions[err.response.status].replace(
                "$MSG",
                errmsg || "Unknown"
            ),
            heading
        );
    } else
        switch (err.response.status) {
            default:
                if (config.IS_DEV) {
                    app_context.showError(
                        `Error (${err.response.status} - ${err.response.statusText} - ${errmsg})`
                    );
                } else {
                    app_context.showError(
                        "An error has occurred; please try again, or notify the administrator."
                    );
                }
                break;
        }
};

/********************************************************************
 *
 * @param {*} ind
 */
export const getTargetValueFromIndicator = (ind) => {
    if (_.isEmpty(ind.targets)) return undefined;
    let dataType = ind.data_type.coerce_to;
    let value = ind.targets[ind.targets.length - 1][`data_${dataType}`];

    if (dataType === "date") {
        value = moment(value).format("MM-DD-YYYY");
    }
    return value;
};

/********************************************************************
 *
 * @param {*} ind
 */
export const getBaselineValueFromIndicator = (ind) => {
    if (_.isEmpty(ind.baseline)) return undefined;
    let dataType = ind.data_type.coerce_to;
    let value = ind.baseline[`data_${dataType}`];

    if (dataType === "date") {
        value = moment(value).format("MM-DD-YYYY");
    }
    return value;
};

/********************************************************************
 * Returns the current reporting period in chosenPeriod object format.
 */
export const getCurrentReportingPeriod = () => {
    const month = new Date().getMonth();
    let period = "Q4";

    if (month < 4) {
        period = "Q1";
    } else if (month < 7) {
        period = "Q2";
    } else if (month < 10) {
        period = "Q3";
    }

    return {
        reporting_year: moment().format("YYYY"),
        reporting_period: period,
    };
};

let last_id = 0;

export const uniq_id = (prefix = "id") => {
    last_id++;
    return `${prefix}${last_id}`;
};

export const sortIntPeriods = (int) => {
    const sortedInt = int.reporting_periods.sort((r1, r2) => {
        const period_names = [
            "M01",
            "M02",
            "M03",
            "Q1",
            "M04",
            "M05",
            "M06",
            "Q2",
            "M07",
            "M08",
            "M09",
            "Q3",
            "M10",
            "M11",
            "M12",
            "Q4",
        ];
        const period_a = `${r1.year}${period_names.indexOf(r1.period)}`;
        const period_b = `${r2.year}${period_names.indexOf(r2.period)}`;
        if (r1.year === r2.year) {
            if (parseInt(period_a) < parseInt(period_b)) {
                return -1;
            }
            if (parseInt(period_a) > parseInt(period_b)) {
                return 1;
            }
        }
        if (period_a < period_b) {
            return -1;
        }
        if (period_a > period_b) {
            return 1;
        }
        // periods must be equal
        return 0;
    });

    return sortedInt;
};

export const sortObjs = (objs) => {
    const sortedObj = objs.sort((r1, r2) => {
        const period_names = [
            "M01",
            "M02",
            "M03",
            "Q1",
            "M04",
            "M05",
            "M06",
            "Q2",
            "M07",
            "M08",
            "M09",
            "Q3",
            "M10",
            "M11",
            "M12",
            "Q4",
        ];
        const period_a = `${r1.label.year}${period_names.indexOf(
            r1.label.period
        )}`;
        const period_b = `${r2.label.year}${period_names.indexOf(
            r2.label.period
        )}`;
        if (r1.label.year === r2.label.year) {
            if (parseInt(period_a) < parseInt(period_b)) {
                return -1;
            }
            if (parseInt(period_a) > parseInt(period_b)) {
                return 1;
            }
        }
        if (period_a < period_b) {
            return -1;
        }
        if (period_a > period_b) {
            return 1;
        }
        // periods must be equal
        return 0;
    });

    return sortedObj;
};

/********************************************************************/
/**************** Constants and such bric-a-brac ********************/
/********************************************************************/

export const STATUS_COLOR = {
    Draft: "grey",
    Submitted: "red",
    Reviewed: "yellow",
    Approved: "green",
};

export const COLOR_POOL = [
    "--abif-primary-red",
    "--abif-primary-yellow",
    "--abif-jeans",
    "--abif-secondary-orange",
    "--abif-primary-pink",
    "--abif-secondary-brown",
    "--abif-secondary-gray",
    "--abif-marzipan",
];

export const QAV_KEYS = {
    BAD: "Missed period target and not on-track",
    MED: "Missed period target but on-track",
    GOO: "Met period target and on-track",
};

export const QAV_UUID = "53350948-fe3b-4066-87c8-c66cadc8d47e";
export const STEER_COUNT_UUID = "5422f2d9-e66a-421c-9261-1ae181c98edc";

export const DATA_TYPE_PERCENTAGE_UUID = "11ced804-bdf0-4dc5-a093-bbe48dd2fd60";

export const CADENCE_QUARTERLY_UUID = "0922d715-b3f5-4448-b011-70d09fec46d9";
export const CADENCE_MONTHLY_UUID = "144b245b-da34-4fc7-b3cb-cf8abd9c5244";
export const CADENCE_FUTURE_YEARS = 10;

/****** INDICATORS ******/

// Number of Steering Committee members
export const IND_NUM_STEERING_MEMBERS_UUID =
    "5422f2d9-e66a-421c-9261-1ae181c98edc";

export const TYPE_TO_PRIMARY_UUUD = {
    "ca1f29ac-d8d8-4687-b150-cdd121c47458":
        "3cce78ab-3b56-4dd5-a02c-ee683c92763c", // BRIEF_INT_ADULT
    "0dc4d20c-a351-4ae3-8924-c02c5cd91357":
        "4bd00b01-7123-4554-93da-488cd4be9db8", // BRIEF_INT_YOUTH
    "341db090-7066-484e-8dfd-2c82caf78243":
        "ded6b5e1-9972-4282-a235-f9df51ffbe10", // EST_PRAC_RESTR
    "a7a2c3b5-7fde-4f4b-b98c-ef15a394bd00":
        "204328dd-4883-4334-bcfb-dd85d2203f10", // FAMILY_BASED
    "955e9131-4b3b-474c-a92a-c5ed71bddd6a":
        "5cc1d419-8627-4d18-9ede-ed4c9a20622e", // RBS_TRAINING
    "75683e10-5947-4756-8d59-41dd185e9397":
        "56b391c8-763d-4c30-bcec-514a1d46499d", // ROAD_SAFETY
    "06625496-c749-4c66-8b03-fb1b5a7444e6":
        "547a4ec8-78df-4cb8-8a66-c911a3e7a2ad", //SOCIAL_MARK
    "2c7306a1-9cd0-457a-aed1-0efca75bafb7": undefined, // VIOLENCE
};
