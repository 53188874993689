import React from "react";
import { Container, Header, Loader, Segment, Grid } from "semantic-ui-react";

import { genericResponseHandler } from "../../../../utils";
import { Interventions, Cities } from "../../../../api";
import { AppContext } from "../../../../state";
import { useParams, useHistory } from "react-router-dom";

import DashboardPeriodSelect from "../ui/DashboardPeriodSelect";

import DashTypeSummary from "./summary/DashTypeSummary";
import DashTypeGraphProgressOT from "./graphs/DashTypeGraphProgressOT";

/* ------------------------------------------------------------------ */
const DashTypeLevel = () => {
    const app = React.useContext(AppContext);

    const history = useHistory();
    const { type_uuid, reporting_year, reporting_period } = useParams();

    const [cityList, setCityList] = React.useState(undefined);

    const [types, setTypes] = React.useState(undefined);
    const [myType, setMyType] = React.useState(undefined);

    // Fetch the list of known intervention types
    React.useEffect(() => {
        (async () => {
            let { data } = await Interventions.list_types();
            setTypes(data);
        })();
    }, []);

    /* ------------------------------------------------------------------ */
    React.useEffect(() => {
        setCityList(null);
        (async () => {
            try {
                const { data } = await Cities.list(
                    reporting_year,
                    reporting_period
                );
                setCityList(data.cities);
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, [type_uuid, reporting_year, reporting_period]); // eslint-disable-line

    /* ------------------------------------------------------------------ */
    React.useEffect(() => {
        if (!types) return;

        // get the details of this type
        let _mytype = types.filter((t) => t.uuid === type_uuid);

        if (_mytype.length > 0) {
            setMyType(_mytype[0]);
        } else {
            // nope out of here
            app.showError(`Unknown int-type UUID passed ${type_uuid}`);
        }
    }, [types, type_uuid]); // eslint-disable-line
    /* ------------------------------------------------------------------ */
    return (
        <Container>
            {!cityList ? (
                <Segment placeholder>
                    <Loader active content="Loading intervention..." />
                </Segment>
            ) : (
                <Container>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Header
                                    as="h1"
                                    icon="tags"
                                    content={`Intervention Type: ${myType.name}`}
                                />
                            </Grid.Column>
                            <Grid.Column floated="right" width={6}>
                                <DashboardPeriodSelect
                                    noMonths={true}
                                    chosenPeriod={{
                                        reporting_year,
                                        reporting_period,
                                    }}
                                    onPeriodSelected={(year, period) => {
                                        history.push(
                                            `/dashboard/intervention_type/${myType.uuid}` +
                                                (year
                                                    ? `/${year}/${period}`
                                                    : "")
                                        );
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <DashTypeGraphProgressOT
                                    cityList={cityList}
                                    myType={myType}
                                    types={types}
                                    chosenPeriod={{
                                        reporting_year,
                                        reporting_period,
                                    }}
                                />
                                <br />
                                <br />
                                <DashTypeSummary
                                    cityList={cityList}
                                    myType={myType}
                                    types={types}
                                    chosenPeriod={{
                                        reporting_year,
                                        reporting_period,
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )}
        </Container>
    );
};

export default DashTypeLevel;
