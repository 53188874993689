import React, { useState, useEffect } from "react";
import { Form as ReactForm } from "semantic-ui-react";
import { Button, Header, Modal } from "semantic-ui-react";
import { Form, Input } from "semantic-ui-react-form-validator";
import { Auth } from "../../../api";
import { genericResponseHandler } from "../../../utils";
import { AppContext } from "../../../state/AppProvider";

const UserSettings = ({ open, onClose }) => {
    const app = React.useContext(AppContext);
    const [email, setEmail] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const onPasswordChangeSubmit = async () => {
        try {
            await Auth.request_reset_password(email);
        } catch (err) {
            genericResponseHandler(err, app);
        }
        setShowDialog(true);
    };

    useEffect(() => {
        if (open) {
            setShowModal(true);
        }
    }, [open]);

    return (
        <div>
            <Modal
                style={{ padding: "40px" }}
                onClose={() => {
                    setShowModal(onClose());
                    setEmail("");
                }}
                size="tiny"
                open={showModal}
            >
                <Form onSubmit={onPasswordChangeSubmit} autoComplete="off">
                    <ReactForm.Group widths="12">
                        <Input
                            label="Email"
                            autoComplete="off"
                            placeholder="Email"
                            validators={["required"]}
                            errorMessages={["Required"]}
                            fluid
                            value={email}
                            onChange={(e) => setEmail(e.target.value.trim())}
                        />
                    </ReactForm.Group>
                    <ReactForm.Group widths="12">
                        <Button disabled={!email} type="submit">
                            Update Password
                        </Button>
                    </ReactForm.Group>
                </Form>
            </Modal>
            <Modal
                size="mini"
                open={showDialog}
                onClose={() => {
                    setShowDialog(false);
                    setShowModal(false);
                    onClose();
                    setEmail("");
                }}
            >
                <Modal.Header>Reset Password</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header>New Password Link</Header>
                        <p>
                            We've sent a password reset link to
                            {" " + email}
                        </p>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default UserSettings;
