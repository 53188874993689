import React, { useContext } from "react";
import {
    Header,
    Form,
    Grid,
    Label,
    Container,
    Button,
    Message,
} from "semantic-ui-react";

import RolesDropdown from "../../../../ui/RolesDropdown";
import { User } from "../../../../../api";
import { genericResponseHandler } from "../../../../../utils";
import { AppContext } from "../../../../../state";

const AdminUserNew = () => {
    const app = useContext(AppContext);

    const [formState, setFormState] = React.useState({});
    const [roleRequiredError, setRoleRequiredError] = React.useState(false);
    const [messageBox, setMessageBox] = React.useState(undefined);

    /*------------------------------*/
    const onFormUpdate = (e, input) => {
        setFormState({
            ...formState,
            [input.name]: e.target.value,
        });
    };

    /*------------------------------*/
    const onSubmit = async () => {
        let hasError = false;

        setRoleRequiredError(false);

        if (!formState.role) {
            setRoleRequiredError(true);
            hasError = true;
        }

        if (!hasError) {
            try {
                await User.create_with_role(
                    formState.lastname.trim(),
                    formState.firstname.trim(),
                    formState.email.trim(),
                    formState.role
                );

                setMessageBox(
                    <Message
                        positive
                        header="Success"
                        content={`User ${formState.email} created.`}
                    />
                );
                setFormState({
                    firstname: "",
                    lastname: "",
                    email: "",
                    role: null,
                });
            } catch (err) {
                genericResponseHandler(
                    err,
                    app,
                    {
                        400: "There was a problem creating that user. (400)",
                        409: "User already exists (409)",
                    },
                    "Oops..."
                );
            }
        }
    };

    /*------------------------------*/
    return (
        <React.Fragment>
            <Header as="h1" icon="user plus" content="New User" />
            <Form onSubmit={onSubmit}>
                <Grid columns={2} stackable>
                    <Grid.Column>
                        <Form.Input
                            label="First Name"
                            placeholder="First Name"
                            name="firstname"
                            value={formState.firstname}
                            required
                            onChange={onFormUpdate}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            label="Last Name"
                            placeholder="Last Name"
                            name="lastname"
                            value={formState.lastname}
                            required
                            onChange={onFormUpdate}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            label="Email"
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={formState.email}
                            required
                            onChange={onFormUpdate}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <RolesDropdown
                            label="Role"
                            placeholder="Role to assign"
                            value={formState.role}
                            onRoleSelected={(role_uuid) => {
                                setFormState({
                                    ...formState,
                                    role: role_uuid,
                                });
                                setRoleRequiredError(false);
                            }}
                        />
                        {roleRequiredError && (
                            <Label
                                content="A role is rquired"
                                basic
                                color="red"
                                pointing="above"
                            />
                        )}
                    </Grid.Column>
                </Grid>
                <br />
                <Container textAlign="right">
                    <Button
                        icon="left arrow"
                        content="Back"
                        onClick={(e) => {
                            // history.go was not playing ball with this form
                            document.location.href = "/admin/users";
                        }}
                    />
                    <Button
                        primary
                        type="submit"
                        icon="plus"
                        content="Add User"
                    />
                </Container>
            </Form>
            {messageBox}
        </React.Fragment>
    );
};

export default AdminUserNew;
