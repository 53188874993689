import React from "react";
import _ from "lodash";

import { Container, Grid, Segment, Loader, Header } from "semantic-ui-react";

import DashCitySummary from "./summary/DashCitySummary";
import DashCityGraph from "./graph/DashCityGraph";
import { AppContext } from "../../../../state";
import { genericResponseHandler } from "../../../../utils";
import { useParams, useHistory } from "react-router-dom";
import { Cities } from "../../../../api";
import DashboardPeriodSelect from "../ui/DashboardPeriodSelect";

const DashCityLevel = () => {
    const app = React.useContext(AppContext);
    const history = useHistory();

    const { city_uuid, reporting_year, reporting_period } = useParams();

    const [cityData, setCityData] = React.useState(null);
    const [cityList, setCityList] = React.useState(null);

    /* ------------------------------------------------------------------ */
    React.useEffect(() => {
        setCityData(null);
        setCityList(null);

        (async () => {
            try {
                const city_response = await Cities.get(
                    city_uuid,
                    reporting_year,
                    reporting_period
                );
                setCityData(city_response);

                const citylist_response = await Cities.list();
                setCityList(citylist_response.data.cities);
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, [city_uuid, reporting_year, reporting_period]); // eslint-disable-line

    /* ------------------------------------------------------------------ */
    return !(cityData && cityList) ? (
        <Segment placeholder>
            <Loader active content="Loading city..." />
        </Segment>
    ) : (
        <Container>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Header
                            icon="globe"
                            content={
                                cityData.name +
                                " Summary " +
                                (reporting_period
                                    ? `(${reporting_year} / ${reporting_period})`
                                    : "(Current Period)")
                            }
                            as="h1"
                        />
                    </Grid.Column>
                    <Grid.Column floated="right" width={6}>
                        <DashboardPeriodSelect
                            noMonths={true}
                            onPeriodSelected={(year, period) =>
                                history.push(
                                    `/dashboard/city/${city_uuid}` +
                                        (year ? `/${year}/${period}` : "")
                                )
                            }
                            chosenPeriod={{
                                reporting_year,
                                reporting_period,
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <DashCitySummary cityData={cityData} />
                        {_.isEmpty(cityData.interventions) ? (
                            ""
                        ) : (
                            <React.Fragment>
                                <br />
                                <br />
                                <DashCityGraph cityData={cityData} />
                            </React.Fragment>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default DashCityLevel;
