import { AxiosInstance } from "./index";
import { setAuthTokens, clearAuthTokens } from "./jwt";

/********************************************************************
 * User login
 * @param {string} email
 * @param {string} password
 */
const login = async (email, password = null, reset_token = null) => {
    let options = {
        email: email.trim(),
    };

    if (password) {
        options = {
            ...options,
            password: password.trim(),
        };
    }

    if (reset_token) {
        options = {
            ...options,
            reset_token: reset_token.trim(),
        };
    }

    var response = await AxiosInstance.post("/v1/auth/login", options);

    setAuthTokens(response.headers);

    return response;
};

/********************************************************************
 * Change the currently logged in user's password.
 * @param {string} old_password The current password
 * @param {string} new_password Unique new password
 */
const change_password = async (old_password, new_password) => {
    old_password = old_password.trim();
    new_password = new_password.trim();

    // One last check before we 'pass' it off...
    if (old_password === new_password) {
        throw new Error("New password cannot be the same as the old password.");
    }

    var response = await AxiosInstance.put("/v1/auth/change", {
        old_password,
        new_password,
    });

    return response;
};

/********************************************************************
 * Request password reset
 * @param {string} email
 * @param {string} request_token
 */
const request_reset_password = async (email) => {
    var response = await AxiosInstance.post("/v1/auth/reset", {
        email: email.trim(),
    });

    return response;
};

/********************************************************************
 * Step three in the 'forgot my password' saga, provides a
 * new password; must be logged in with token given after request
 * code is validated
 *
 * @param {*} new_password The new password.
 */
const new_password_for_reset = async (new_password) => {
    var response = await AxiosInstance.put("/v1/auth/reset", {
        password: new_password.trim(),
    });
    return response;
};

/********************************************************************
 * User logout
 * @param {string} email
 * @param {string} password
 */
const logout = async (user_state) => {
    const response = await AxiosInstance.post("/v1/auth/logout");
    clearAuthTokens();
    return response;
};

/********************************************************************
 * Cashes in the old token in exchange for a new one; used with the
 * token keepalive heartbeat.
 */
const refresh_token = async () => {
    const response = await AxiosInstance.post("/v1/auth/refresh");

    if (response.status === 204) {
        setAuthTokens(response.headers);
    }
    return response;
};

export default {
    login,
    change_password,
    request_reset_password,
    new_password_for_reset,
    logout,
    refresh_token,
};
