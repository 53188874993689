import React from "react";
import _ from "lodash";
import { Loader, Grid, Button, Dropdown } from "semantic-ui-react";

const DropdownFromSource = ({
    onSelect,
    placeholder,
    buttonText,
    dataSource,
    isInApproval,
}) => {
    const [selected, setSelected] = React.useState(undefined);

    const onClick = () => {
        if (selected) {
            if (!onSelect)
                throw new Error(
                    "No onSelect handler for DropdownFromSource-derived component"
                );
            onSelect(selected);
        }
    };

    return (
        <React.Fragment>
            {_.isEmpty(dataSource) ? (
                <Loader active />
            ) : (
                <Grid>
                    <Grid.Row stretched>
                        <Grid.Column width={13}>
                            <Dropdown
                                search
                                compact
                                scrolling
                                selection
                                disabled={isInApproval}
                                options={dataSource}
                                placeholder={placeholder}
                                onChange={(e, data) => setSelected(data.value)}
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Button
                                content={buttonText}
                                primary
                                size="tiny"
                                disabled={!selected || isInApproval}
                                onClick={onClick}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default DropdownFromSource;
