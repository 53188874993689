import React from "react";
import _ from "lodash";
import { Table, Button } from "semantic-ui-react";

import {
    getTargetValueFromIndicator,
    getBaselineValueFromIndicator,
    genericResponseHandler,
    CADENCE_QUARTERLY_UUID,
    CADENCE_MONTHLY_UUID,
    IND_NUM_STEERING_MEMBERS_UUID,
} from "../../../../utils";

import { Indicators } from "../../../../api";
import BasicInputField from "./ui/BasicInputField/BasicInputField";
import { AppContext } from "../../../../state";
import indicators from "../../../../api/indicators";

/*****************************************************************************/
const MyCitiesEditIndicatorRow = ({
    indData: initial_indicator,
    onDelete,
    isInApproval,
    deleteInProgress,
    isConsiderCalculated = false,
    onForceFullReload,
}) => {
    const app = React.useContext(AppContext);

    const [indData, setIndData] = React.useState(initial_indicator);
    const [deleted, setDeleted] = React.useState(false);
    const [isRhythmicCadence, setIsRhythmicCadence] = React.useState(false);
    const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);

    React.useEffect(() => {
        setIsRhythmicCadence(
            indData.cadence_type.uuid === CADENCE_QUARTERLY_UUID ||
                indData.cadence_type.uuid === CADENCE_MONTHLY_UUID
        );
    }, []); // eslint-disable-line

    /* -------------------------------------- */
    React.useEffect(() => {
        document.addEventListener("updateIndicator", (e) => {
            if (e.value === indData.uuid) {
                reloadIndicatorData();
            }
        });
    }, [indData.uuid]); // eslint-disable-line

    /* -------------------------------------- */
    const reloadIndicatorData = async () => {
        try {
            const result = await Indicators.get(indData.uuid);
            setIndData(result.data);
            forceUpdate();
        } catch (err) {
            genericResponseHandler(err, app);
        }
    };

    /* ----------------------------------------------- */
    const toggleButton = () => {
        setDeleted(true);
    };

    /* ----------------------------------------------- */
    function errorHandler403(err, app) {
        if (err.response.data.message === "this period is already approved") {
            return "The current reporting period (or the period you have chosen) has already been approved for this data.";
        }
        return "This field is missing a value.";
    }

    /* ----------------------------------------------- */
    const onDataSave = async (
        value,
        reporting_year = undefined,
        reporting_period = undefined,
        is_greater = false
    ) => {
        const is_date =
            indData.data_type.uuid === "197821bc-0255-4031-a1e0-b387e0e42d20";

        if (typeof value === "string") {
            value = value.trim();
            if (!value) value = "";
        }
        if (!value) value = "";

        try {
            await Indicators.set_data(
                indData.uuid,
                value,
                undefined,
                reporting_year,
                reporting_period,
                is_date
            );

            // If this is a child indicator, then force
            // update of the parent value
            if (indData.parent_indicator_uuid) {
                let customEvent = new Event("updateIndicator");
                customEvent.value = indData.parent_indicator_uuid;
                document.dispatchEvent(customEvent);
            }

            if (
                indData.default_indicator_uuid ===
                    IND_NUM_STEERING_MEMBERS_UUID &&
                is_greater
            ) {
                onForceFullReload();
            }

            return true;
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    403: errorHandler403,
                },
                "Error saving data"
            );
            return false;
        }
    };

    /* ----------------------------------------------- */
    const onTargetSave = async (value, year, period, is_greater = false) => {
        if (typeof value === "string") {
            value = value.trim();
            if (!value) value = "";
        }
        if (!value) value = null;
        try {
            await Indicators.set_data(
                indData.uuid,
                undefined, // just setting the target
                value,
                year,
                period
            );
            reloadIndicatorData();
            return true;
        } catch (err) {
            genericResponseHandler(err, app, {
                403: errorHandler403,
            });
            return false;
        }
    };

    /* ----------------------------------------------- */
    const onBaselineSave = async (value) => {
        if (typeof value === "string") {
            value = value.trim();
            if (!value) value = "";
        }
        if (!value) value = null;
        try {
            await Indicators.set_baseline(indData.uuid, value);
            return true;
        } catch (err) {
            genericResponseHandler(err, app, {
                400: "This field is missing a value.",
            });
            return false;
        }
    };

    /* -------------------------------------- */
    async function deleteTarget(ind_uuid, reporting_year, reporting_period) {
        try {
            await indicators.delete_target(
                ind_uuid,
                reporting_year,
                reporting_period
            );
            reloadIndicatorData();
        } catch (err) {
            genericResponseHandler(err, app);
        }
    }

    /* -------------------------------------- */
    function renderHistoricalTargets() {
        if (!indData.data.length) {
            return <React.Fragment></React.Fragment>;
        }

        const sorted_rows = _.sortBy(indData.data, [
            "reporting_year",
            "reporting_period",
        ]);

        let rows = [];

        // ignore "All" periods
        sorted_rows.map((entry, idx) => {
            if (entry.reporting_period !== "All" && entry.target_value !== null)
                rows.push(
                    <BasicInputField
                        color="orange"
                        key={`sr${idx}`}
                        name={`${entry.reporting_year}/${entry.reporting_period}`}
                        icon="bullseye"
                        indicator={indData}
                        onSave={onTargetSave}
                        onBlur={onTargetSave}
                        initialValue={entry.target_value}
                        isInApproval={isInApproval}
                        historicalDataIndex={entry}
                        onClear={() =>
                            deleteTarget(
                                indData.uuid,
                                entry.reporting_year,
                                entry.reporting_period
                            )
                        }
                    />
                );
        });

        return (
            <React.Fragment>
                {!_.isEmpty(rows) && (
                    <div className="historical-wrapper">{rows}</div>
                )}
            </React.Fragment>
        );
    }

    /* ----------------------------------------------- */
    return indData.is_common ? (
        <React.Fragment></React.Fragment>
    ) : (
        <React.Fragment>
            <Table.Row
                key={indData.uuid}
                className={`indicator-row  ${indData.is_header ? "header" : ""}
                     ${indData.is_common ? "common" : ""} ${
                    indData.parent_indicator_uuid ? "subindicator" : ""
                }`}
            >
                <Table.Cell verticalAlign="middle" width={8}>
                    <b>
                        {indData.name}{" "}
                        {indData.required_field && (
                            <span
                                className={"required"}
                                style={{ action: { color: "red" } }}
                            >
                                *
                            </span>
                        )}
                    </b>
                    <br />
                </Table.Cell>
                <Table.Cell verticalAlign="middle">
                    {(indData.is_header || indData.is_common) && (
                        <BasicInputField
                            name={indData.is_header ? null : "Data"}
                            color="yellow"
                            indicator={indData}
                            onSave={onDataSave}
                            onBlur={onDataSave}
                            ignored={ignored}
                            initialValue={indData.current_value}
                            isInApproval={isInApproval}
                            isConsiderCalculated={isConsiderCalculated}
                        />
                    )}
                    {!(indData.is_header || indData.is_common) &&
                        isRhythmicCadence && (
                            <React.Fragment>
                                <BasicInputField
                                    color="brown"
                                    name="Baseline"
                                    icon="road"
                                    indicator={indData}
                                    onSave={onBaselineSave}
                                    onBlur={onBaselineSave}
                                    ignored={ignored}
                                    initialValue={getBaselineValueFromIndicator(
                                        indData
                                    )}
                                    isInApproval={isInApproval}
                                />
                                <BasicInputField
                                    color="orange"
                                    name="Target"
                                    icon="bullseye"
                                    indicator={indData}
                                    onSave={onTargetSave}
                                    ignored={ignored}
                                    isTarget={true}
                                    initialValue={getTargetValueFromIndicator(
                                        indData
                                    )}
                                    isInApproval={isInApproval}
                                    periodSelect={true}
                                />

                                {renderHistoricalTargets()}
                            </React.Fragment>
                        )}
                </Table.Cell>
                <Table.Cell collapsing verticalAlign="middle" textAlign="right">
                    <Button.Group size="tiny">
                        {!(indData.is_header || indData.is_common) && (
                            <Button
                                negative
                                icon="trash"
                                disabled={
                                    deleted || deleteInProgress || isInApproval
                                        ? true
                                        : false
                                }
                                onClick={() => {
                                    onDelete(indData.uuid);
                                    toggleButton();
                                }}
                            />
                        )}
                    </Button.Group>
                </Table.Cell>
            </Table.Row>
        </React.Fragment>
    );
};

export default MyCitiesEditIndicatorRow;
