import React, { useContext } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";

import { AppContext } from "../../state";

const AlertMessage = () => {
    const app = useContext(AppContext);

    return (
        <Modal
            className="alert-modal"
            dimmer
            open={!!app.state.errorMessage}
            onClose={() => app.dispatch({ type: "error-clear" })}
        >
            <Modal.Header className="alert-modal-heading">
                <Icon name="times rectangle" color="red" />
                <span>
                    {app.state.errorMessage && app.state.errorMessage.header}
                </span>
            </Modal.Header>
            <Modal.Content className="alert-modal-content">
                {app.state.errorMessage && app.state.errorMessage.message}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color="red"
                    onClick={() => app.dispatch({ type: "error-clear" })}
                >
                    OK
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default AlertMessage;
