import React, { useState, useContext } from "react";
import { Icon, Form, Header, Grid, Modal, Image } from "semantic-ui-react";

import { genericResponseHandler } from "../../utils";

import { Auth } from "../../api";

import { AppContext, UserContext } from "../../state";
import UserGenerateResetPassword from "../pages/user/UserGenerateResetPassword";
import { useLocation, useHistory } from "react-router-dom";

const Login = () => {
    const user = useContext(UserContext);
    const app = useContext(AppContext);

    const location = useLocation();
    const history = useHistory();

    const [showResetForm, setShowResetForm] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [frmEmail, setFrmEmail] = useState("");
    const [frmPassword, setFrmPassword] = useState("");
    const [frmToken, setFrmToken] = useState("");
    const [tokenForm, setShowTokenForm] = useState(false);

    let { from } = location.state || { from: { pathname: "/" } };

    /********************************************************************
     * Perform API login call
     */
    const onLogin = async () => {
        try {
            setIsLoading(true);
            const response = await Auth.login(frmEmail, frmPassword);

            user.dispatch({
                type: "login",
                payload: response,
            });

            history.replace(from);
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    400: "A username and password is required.",
                    401: "The username or password was not recognized.",
                    423: "Too many failed attempts. Please reset your password.",
                },
                "There was a problem logging in."
            );
            setIsLoading(false);
        }
    };

    const onTokenLogin = async () => {
        try {
            setIsLoading(true);
            const response = await Auth.login(frmEmail, null, frmToken);

            user.dispatch({
                type: "login",
                payload: response,
            });

            history.push("/resetpassword");
        } catch (err) {
            history.push("/resetpassword");
            genericResponseHandler(
                err,
                app,
                {
                    400: "A username and password is required.",
                    401: "The username or token was not recognized.",
                },
                "There was a problem logging in."
            );
            setIsLoading(false);
        }
    };

    //-------------------------------//
    return (
        <div>
            <Grid
                textAlign="center"
                style={{ height: "100vh" }}
                verticalAlign="middle"
            >
                <Grid.Column style={{ maxWidth: 600 }} textAlign="left">
                    <Image src="/abif.jpg" centered />
                    <br />
                    <Header
                        as="h3"
                        content="Data Management System"
                        textAlign="centered"
                        style={{ color: "var(--abif-primary-red)" }}
                    />
                    <Header icon="user" content="Login" />
                    <Form size="large">
                        <Form.Input
                            label="Email"
                            type="email"
                            value={frmEmail}
                            required
                            placeholder="Email address"
                            onChange={(e) => setFrmEmail(e.target.value)}
                        />
                        <Form.Input
                            label="Password"
                            type="password"
                            placeholder="Password"
                            required
                            value={frmPassword}
                            onChange={(e) => setFrmPassword(e.target.value)}
                        />
                        <Form.Group>
                            <Form.Button
                                style={{ float: "left" }}
                                onClick={() => {
                                    setShowResetForm(true);
                                }}
                                type="button"
                                content="Reset Password"
                                width={16}
                            />
                            <Form.Button
                                style={{ float: "left" }}
                                onClick={() => {
                                    setShowTokenForm(true);
                                }}
                                type="button"
                                content="Login with Token"
                                width={16}
                            />
                            <Form.Button
                                primary
                                onClick={onLogin}
                                disabled={isLoading}
                                style={{ float: "right" }}
                                width={16}
                            >
                                {isLoading ? <Icon name="spinner" /> : "Login"}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid>
            <UserGenerateResetPassword
                open={showResetForm}
                onClose={() => setShowResetForm(false)}
            />
            {tokenForm && (
                <Modal
                    size="tiny"
                    style={{ padding: "20px" }}
                    open={tokenForm}
                    onClose={() => setShowTokenForm(false)}
                >
                    <Form size="large">
                        <Form.Input
                            label="Email"
                            type="email"
                            value={frmEmail}
                            required
                            placeholder="Email address"
                            onChange={(e) => setFrmEmail(e.target.value)}
                        />
                        <Form.Input
                            label="Token"
                            placeholder="Token"
                            required
                            value={frmToken}
                            onChange={(e) => setFrmToken(e.target.value)}
                        />
                        <Form.Group>
                            <Form.Button
                                primary
                                onClick={onTokenLogin}
                                disabled={isLoading}
                                style={{ float: "right" }}
                                width={16}
                            >
                                {isLoading ? <Icon name="spinner" /> : "Login"}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default Login;
