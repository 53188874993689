import React, { useState, useContext } from "react";
import _ from "lodash";
import {
    Divider,
    Table,
    Tab,
    Button,
    List,
    Container,
    Header,
    Accordion,
} from "semantic-ui-react";

import { genericResponseHandler } from "../../../../../../utils";
import RolesDropdown from "../../../../../ui/RolesDropdown";
import { AppContext } from "../../../../../../state";
import { User } from "../../../../../../api";

/*****************************************************************************
 *
 */
const RoleDetailEntry = ({ cap }) => {
    return (
        <List.Item key={cap.capability.name}>
            <List.Header>{cap.capability.name}</List.Header>
            {cap.capability.description}
        </List.Item>
    );
};

const RoleCardBody = ({ role_entry }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Accordion>
            <Accordion.Title
                index={0}
                content="Caps"
                active={isOpen}
                onClick={() => setIsOpen(!isOpen)}
            />
            <Accordion.Content index={0} active={isOpen}>
                <List bulleted>
                    {role_entry.role_capability.map((cap) => (
                        <RoleDetailEntry key={cap.capability.name} cap={cap} />
                    ))}
                </List>
            </Accordion.Content>
        </Accordion>
    );
};

/*****************************************************************************
 * props: userData - expects the user data object from the UserEdit component
 *        onModified - parent callback when role data is updated; will provide
 *                     updated userData object
 */

const UserRolesPane = ({ userData, onModified }) => {
    const app = useContext(AppContext);

    const onRemoveRole = async (role_uuid) => {
        try {
            const result = await User.remove_role(userData.uuid, role_uuid);
            if (onModified) {
                onModified(result.data);
            }
        } catch (err) {
            genericResponseHandler(err, app);
        }
    };

    const onAssignRole = async (role_uuid) => {
        try {
            const result = await User.assign_role(userData.uuid, role_uuid);
            if (onModified) {
                onModified(result.data);
            }
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    409: "This role is already assigned to this user.",
                },
                "Error"
            );
        }
    };

    return (
        <Tab.Pane key={userData}>
            <Table basic="very" striped>
                <Table.Body>
                    {_.isEmpty(userData.roles) ? (
                        <Table.Row>
                            <Table.Cell>
                                <Header
                                    as="h3"
                                    color="red"
                                    content="No roles assigned."
                                    textAlign="center"
                                />
                            </Table.Cell>
                        </Table.Row>
                    ) : (
                        userData.roles.map((role) => {
                            return (
                                <Table.Row key={role.uuid}>
                                    <Table.Cell>
                                        <Header as="h3" content={role.name} />
                                        {_.isEmpty(role.role_capability) ? (
                                            "No capabilities"
                                        ) : (
                                            <RoleCardBody role_entry={role} />
                                        )}
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">
                                        <Button
                                            icon="trash"
                                            negative
                                            onClick={() =>
                                                onRemoveRole(role.uuid)
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })
                    )}
                </Table.Body>
            </Table>
            <Divider />
            <Container textAlign="right">
                <RolesDropdown onRoleSelected={onAssignRole} useButtonSelect />
            </Container>
        </Tab.Pane>
    );
};

export default UserRolesPane;
