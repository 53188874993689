import React, { useState } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import { Form as ReactForm } from "semantic-ui-react";
import { Form, Input } from "semantic-ui-react-form-validator";
import { Auth } from "../../../api";
import { genericResponseHandler } from "../../../utils";
import ValidationRules from "../../../validation_rules";
import { AppContext } from "../../../state/AppProvider";

const UserSettings = () => {
    const app = React.useContext(AppContext);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordAgain, setNewPasswordAgain] = useState("");
    const [showDialog, setShowDialog] = useState(false);

    ValidationRules.usePasswordRules(Form);

    Form.addValidationRule("isSameAsNewPassword", (value) => {
        return newPassword === value;
    });
    Form.addValidationRule("mustNotMatchOldPassword", (value) => {
        return value !== oldPassword;
    });

    const onPasswordChangeSubmit = async () => {
        try {
            await Auth.change_password(oldPassword, newPassword);
            setShowDialog(true);
            Auth.logout();
        } catch (err) {
            genericResponseHandler(err, app);
        }
    };

    return (
        <div>
            <Form onSubmit={onPasswordChangeSubmit} autoComplete="off">
                <ReactForm.Group widths="equal">
                    <Input
                        label="Current password"
                        autoComplete="off"
                        placeholder="Current password"
                        validators={["required"]}
                        errorMessages={["Required"]}
                        type="password"
                        fluid
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value.trim())}
                    />
                </ReactForm.Group>
                <ReactForm.Group widths="equal">
                    <Input
                        label="New password"
                        autoComplete="new-password"
                        placeholder="New password"
                        fluid
                        type="password"
                        value={newPassword}
                        validators={[
                            "minStringLength:10",
                            "mustNotMatchOldPassword",
                            "isValidPassword",
                            "required",
                        ]}
                        errorMessages={[
                            "Must be at least 10 characters long",
                            "New password must be different from old password",
                            ValidationRules.ERROR_MSG_INVALID_PASS,
                            "Required",
                        ]}
                        onChange={(e) => setNewPassword(e.target.value.trim())}
                    />
                    <Input
                        label="Confirm new password"
                        autoComplete="new-password"
                        placeholder="Confirm new password"
                        fluid
                        type="password"
                        validators={["isSameAsNewPassword", "required"]}
                        errorMessages={["New passwords must match", "Required"]}
                        value={newPasswordAgain}
                        show={newPassword}
                        onChange={(e) =>
                            setNewPasswordAgain(e.target.value.trim())
                        }
                    />
                    <Button
                        disabled={
                            !(oldPassword && newPassword && newPasswordAgain)
                        }
                        type="submit"
                    >
                        Update Password
                    </Button>
                </ReactForm.Group>
                <div style={{ padding: "10px" }}>
                    <p>
                        A valid password must have at least 10 characters, with
                        at least one upper and lowercase letter, and one numeric
                        digit.
                    </p>
                </div>
            </Form>
            <Modal
                open={showDialog}
                onClose={() => {
                    window.location.replace("/login");
                    setShowDialog(false);
                }}
            >
                <Modal.Header>Change Password</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header>You Have Changed your password</Header>
                        <p>
                            Your password has been changed. You will be logged
                            out. Please log in with your new password.
                        </p>
                        <Button
                            onClick={() => {
                                window.location.replace("/login");
                            }}
                        >
                            Okay
                        </Button>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default UserSettings;
