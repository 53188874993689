import React, { useEffect } from "react";
import { Select, Button } from "semantic-ui-react";

const IndicatorPeriodSelect = ({ onPeriodSelected, chosenPeriod }) => {
    const [frmYear, setFrmYear] = React.useState(null);
    const [frmPeriod, setFrmPeriod] = React.useState(null);

    /* ------------------------------- */
    let years = [];
    for (let i = 2013; i < new Date().getFullYear() + 10; i++) {
        years.push({ key: i, value: "" + i, text: "" + i });
    }

    let periods = null;

    periods = [
        "M01",
        "M02",
        "M03",
        "Q1",
        "M04",
        "M05",
        "M06",
        "Q2",
        "M07",
        "M08",
        "M09",
        "Q3",
        "M10",
        "M11",
        "M12",
        "Q4",
    ].map((p) => {
        return { key: p, value: p, text: p };
    });

    useEffect(() => {
        if (chosenPeriod) {
            setFrmYear(chosenPeriod.reporting_year);
            setFrmPeriod(chosenPeriod.reporting_period);
        }
    }, [chosenPeriod]);

    /* ------------------------------- */
    function onYearChange(e, i) {
        setFrmYear(i.value);
    }

    /* ------------------------------- */
    function onPeriodChange(e, i) {
        setFrmPeriod(i.value);
    }

    /* ------------------------------- */
    function onChangePeriod() {
        onPeriodSelected(frmYear, frmPeriod);
    }

    /* ------------------------------- */
    function onClearPeriod() {
        onPeriodSelected(undefined, undefined);
    }

    /* ------------------------------- */
    return (
        <div className="intervention-period-select">
            <div className="label">
                <label>Reporting Data For</label>
            </div>
            <div className="controls">
                <Select
                    options={years}
                    value={frmYear}
                    onChange={onYearChange}
                    placeholder="Year"
                    fluid
                />
                <Select
                    options={periods}
                    value={frmPeriod}
                    onChange={onPeriodChange}
                    placeholder="Period"
                    fluid
                />
                <Button
                    color="violet"
                    icon="right arrow"
                    title="Change Reporting Period"
                    disabled={!(frmPeriod && frmYear)}
                    onClick={onChangePeriod}
                />
                <Button
                    icon="x"
                    title="Reset Reporting Period"
                    disabled={!(frmPeriod && frmYear)}
                    onClick={onClearPeriod}
                />
            </div>
        </div>
    );
};

export default IndicatorPeriodSelect;
