import React from "react";
import {
    Container,
    Header,
    Loader,
    Segment,
    Grid,
    Divider,
} from "semantic-ui-react";

import { genericResponseHandler } from "../../../../utils";
import { Interventions } from "../../../../api";
import { AppContext } from "../../../../state";
import { useParams } from "react-router-dom";
import CapGuard from "../../../ui/CapGuard";

import DashIntSummary from "./summary/DashIntSummary";
import DashIntGraphProgressOverTime from "./graphs/DashIntGraphProgressOverTime";

/* ------------------------------------------------------------------ */
const DashInterventionLevel = () => {
    const app = React.useContext(AppContext);
    const params = useParams();

    const [intData, setIntData] = React.useState(undefined);

    /* ------------------------------------------------------------------ */
    React.useEffect(() => {
        setIntData(undefined);
        Interventions.get(params.int_uuid).then(
            (response) => {
                setIntData(response.data);
            },
            (err) => {
                genericResponseHandler(err, app);
            }
        );
    }, [params]); // eslint-disable-line

    /* ------------------------------------------------------------------ */
    return (
        <CapGuard needCaps={["own_city_indicator:view"]}>
            <Container>
                {!intData ? (
                    <Segment placeholder>
                        <Loader active content="Loading intervention..." />
                    </Segment>
                ) : (
                    <React.Fragment>
                        <Grid columns={1}>
                            <Grid.Column>
                                <Header
                                    content="Intervention Level"
                                    as="h1"
                                    icon="crosshairs"
                                />
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        <DashIntGraphProgressOverTime intData={intData} />
                        <Divider />
                        <DashIntSummary intData={intData} />
                    </React.Fragment>
                )}
            </Container>
        </CapGuard>
    );
};

export default DashInterventionLevel;
