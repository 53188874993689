import React from "react";
import { Container, Header, Grid, Segment, Loader } from "semantic-ui-react";
import _ from "lodash";

import { Link } from "react-router-dom";
import QAVDot from "../../ui/QAVDot";

const TYPE_SUMMARY_TABLE_HEADERS = [
    "City",
    "Short Description",
    "Indicator Definition",
    "Target",
    "Actual (Variance)",
    "Explanation of Variance vs. Targets",
    "Description of Any Anticipated Changes That May Impact Intervention Progress",
    "Qualitative Assessment of Progress vs. HAU Targets",
];

/* ------------------------------------------------------------------ */
const DashTypeSummary = ({ cityList, myType, types, chosenPeriod }) => {
    const [interventions, setInterventions] = React.useState([]);

    React.useEffect(() => {
        // iterate city list, extract interventions with intervention type
        let interventions = [];
        for (let i in cityList) {
            let plucked = cityList[i].interventions.filter((int) => {
                int.city = { name: cityList[i].name, uuid: cityList[i].uuid };
                return (
                    int.types.filter((type) => type.uuid === myType.uuid)
                        .length > 0
                );
            });
            interventions = interventions.concat(plucked);
        }
        setInterventions(interventions);
    }, [cityList]); // eslint-disable-line

    /* ------------------------------------------------------------------ */
    function renderSummaryTable() {
        let rows = [];
        let divider = [];
        let divider_sec = [];

        TYPE_SUMMARY_TABLE_HEADERS.map((i) => {
            divider.push(<span className="divider"></span>);
            divider_sec.push(<span className="divider secondary"></span>);
        });

        let approved_count = 0;
        for (let i in interventions) {
            const int = interventions[i];
            if (int.current_status !== "Approved") continue;

            approved_count++;

            // Put in a dummy indicator if there isn't one
            if (_.isEmpty(int.primary_indicator)) {
                int.primary_indicator[0] = {
                    name: "--",
                    data_value: "--",
                    variance: "--",
                };
            }

            let data_value = int.primary_indicator[0].data_value || "--";
            let target_value = int.primary_indicator[0].target_value || "--";
            let variance = int.primary_indicator[0].variance || "--";
            let explanation_of_variance =
                int.reporting_period.explanation_of_variance || "--";
            let anticipated_changes =
                int.reporting_period.anticipated_changes || "--";

            if (int.current_status !== "Approved") {
                data_value = target_value = variance = explanation_of_variance = anticipated_changes =
                    "--";
            }

            rows.push(
                <React.Fragment key={int.uuid}>
                    <span>
                        <Link to={`/dashboard/city/${int.city.uuid}`}>
                            {int.city.name}
                        </Link>
                    </span>
                    <span className="dont-break-out">
                        {int.short_description}
                    </span>
                    <span>{int.primary_indicator[0].name}</span>
                    <span className="text-center">{target_value}</span>
                    <span className="text-center">
                        <React.Fragment>
                            <div>
                                {data_value}{" "}
                                {isNaN(Number.parseFloat(variance))
                                    ? "(--)"
                                    : `(${variance}%)`}
                            </div>
                        </React.Fragment>
                    </span>
                    <span className="dont-break-out">
                        {explanation_of_variance}
                    </span>
                    <span className="dont-break-out">
                        {anticipated_changes}
                    </span>
                    <span className="centered">
                        <QAVDot
                            value={
                                int.reporting_period &&
                                int.reporting_period.qualitative_indicator
                            }
                            targetIntUUID={int.uuid}
                        />
                    </span>
                </React.Fragment>
            );

            rows.push(divider);

            // if we have multiple primary_ints, tack them on to
            // subsequent rows

            if (int.primary_indicator.length > 0) {
                for (let i = 1; i < int.primary_indicator.length; i++) {
                    let data_value =
                        int.primary_indicator[i].data_value || "--";
                    let target_value =
                        int.primary_indicator[i].target_value || "--";
                    let variance = int.primary_indicator[i].variance || "--";

                    if (int.current_status !== "Approved") {
                        data_value = target_value = variance = "--";
                    }
                    rows.push(
                        <React.Fragment
                            key={`sec-${int.primary_indicator[i].uuid}-${i}`}
                        >
                            <span className="secondary-indicator"></span>
                            <span className="secondary-indicator"></span>
                            <span className="secondary-indicator">
                                {int.primary_indicator[i].name}
                            </span>
                            <span className="secondary-indicator text-center">
                                {target_value}
                            </span>
                            <span className="secondary-indicator text-center">
                                <React.Fragment>
                                    <div>
                                        {data_value}{" "}
                                        {isNaN(Number.parseFloat(variance))
                                            ? "(--)"
                                            : `(${variance}%)`}
                                    </div>
                                </React.Fragment>
                            </span>
                            <span className="secondary-indicator"></span>
                            <span className="secondary-indicator"></span>
                            <span className="secondary-indicator"></span>
                        </React.Fragment>
                    );

                    rows.push(divider_sec);
                }
            }
        }
        if (approved_count === 0) {
            setInterventions([]);
        }
        /* --------------------------------- */
        return (
            <div
                className="dash-type-summary"
                style={{
                    gridTemplateColumns: `repeat(${TYPE_SUMMARY_TABLE_HEADERS.length}, 1fr)`,
                }}
            >
                {TYPE_SUMMARY_TABLE_HEADERS.map((header, index) => (
                    <span key={index} className="header">
                        {header}
                    </span>
                ))}
                {rows}
            </div>
        );
    }

    /* ------------------------------------------------------------------ */
    return !(myType && interventions) ? (
        <Segment placeholder>
            <Loader active>Building list of interventions...</Loader>
        </Segment>
    ) : _.isEmpty(interventions) ? (
        <Header as="h2" textAlign="center" color="red">
            No approved interventions for this type in this reporting period.
        </Header>
    ) : (
        <Container>
            <Grid relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Header
                            icon="table"
                            content={
                                "Summary " +
                                (chosenPeriod.reporting_period
                                    ? `(${chosenPeriod.reporting_year} / ${chosenPeriod.reporting_period})`
                                    : "(Current Period)")
                            }
                            as="h3"
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>{renderSummaryTable()}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default DashTypeSummary;
