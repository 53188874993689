import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { User } from "../../../../api";
import {
    Loader,
    Button,
    Header,
    Table,
    Label,
    Container,
    Segment,
} from "semantic-ui-react";

import { genericResponseHandler } from "../../../../utils";
import { AppContext } from "../../../../state/AppProvider";
import { useHistory } from "react-router-dom";

const AdminUserList = () => {
    const app = useContext(AppContext);
    const history = useHistory();

    const [userData, setUserData] = useState([]);

    /* ------------------------------------------------- */
    useEffect(() => {
        (async () => {
            try {
                const result = await User.list();
                setUserData(result.data);
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, []); // eslint-disable-line

    /* ------------------------------------------------- */
    const createUserRow = (user) => {
        return (
            <Table.Row key={user.uuid} className="user-list-row">
                <Table.Cell onClick={() => onEditUser(user)}>
                    <b>{user.name}</b> &lt;<code>{user.email}</code>&gt;
                    <br />
                    {user.roles.map((role) => (
                        <Label
                            key={role.uuid}
                            size="tiny"
                            color={
                                role.name.includes("Admin") ? "orange" : "grey"
                            }
                        >
                            {role.name}
                        </Label>
                    ))}
                </Table.Cell>
                <Table.Cell textAlign="right" width={2}>
                    <Button
                        icon="pencil"
                        title="Edit user"
                        onClick={() => onEditUser(user)}
                    />
                </Table.Cell>
            </Table.Row>
        );
    };

    /* ------------------------------------------------- */
    const onEditUser = (user) => {
        history.push(`/admin/user/${user.uuid}`);
    };

    /* ------------------------------------------------- */
    return (
        <Container>
            {_.isEmpty(userData) ? (
                <Segment placeholder>
                    <Loader content="Loading users..." active />
                </Segment>
            ) : (
                <React.Fragment>
                    <Header as="h1" icon="users" content="Users" />
                    <Table compact="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>User</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {userData.map((user) => createUserRow(user))}
                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell width={3} textAlign="right">
                                    <Button
                                        primary
                                        content="Add New User"
                                        icon="plus"
                                        onClick={() =>
                                            history.push("/admin/newuser")
                                        }
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </React.Fragment>
            )}
        </Container>
    );
};

export default AdminUserList;
