import React, { useContext } from "react";
import { Container, Segment, Header } from "semantic-ui-react";
import { Redirect } from "react-router-dom";

import { UserContext } from "../../../state";

import UserChangePassword from "./UserChangePassword";

const UserSettings = () => {
    const user = useContext(UserContext);

    if (!user.state.isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Header as="h1" icon="wrench" content="User Settings" />
            <Segment.Group raised>
                <Segment color="teal" raised>
                    <Header as="h3" icon="key" content="Change Password" />
                    <UserChangePassword />
                </Segment>
            </Segment.Group>
        </Container>
    );
};

export default UserSettings;
