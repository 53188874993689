import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Form, Button, Loader, Segment } from "semantic-ui-react";
import { genericResponseHandler } from "../../utils";
import Roles from "../../api/roles";
import { AppContext } from "../../state";

const RolesDropdown = ({
    onRoleSelected,
    useButtonSelect,
    placeholder = "Role",
    label = "",
    value,
}) => {
    const app = useContext(AppContext);

    const [dropdownOptions, setDropdownOptions] = useState({});
    const [selected, setSelected] = useState(null);

    /* ------------------------------------------------- */
    useEffect(() => {
        (async () => {
            try {
                const result = await Roles.list();
                //TODO: How about we not include the roles that are already added?
                setDropdownOptions(
                    _.map(result.data.roles, (role) => ({
                        key: role.uuid,
                        text: role.name,
                        value: role.uuid,
                    }))
                );
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, []); // eslint-disable-line

    /* ------------------------------------------------- */
    const onDropdownChange = (e, { value }) => {
        setSelected(value);
        if (!useButtonSelect && onRoleSelected) {
            onRoleSelected(value);
        }
    };

    /* ------------------------------------------------- */
    const onAddRole = () => {
        if (onRoleSelected && selected) {
            onRoleSelected(selected);
        }
    };

    /* ------------------------------------------------- */
    return (
        <React.Fragment>
            {!_.isEmpty(dropdownOptions) && (
                <Form.Dropdown
                    options={dropdownOptions}
                    selection
                    label={label}
                    value={value}
                    placeholder={placeholder}
                    onChange={onDropdownChange}
                />
            )}
            {useButtonSelect && (
                <React.Fragment>
                    <br />
                    <Button
                        icon="plus"
                        content="Assign Role"
                        positive
                        onClick={onAddRole}
                        loading={_.isEmpty(dropdownOptions)}
                        disabled={_.isEmpty(dropdownOptions)}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default RolesDropdown;
