import React from "react";
import _ from "lodash";
import {
    Loader,
    Table,
    Grid,
    Label,
    Button,
    Header,
    Container,
    Form,
    Segment,
    Icon,
    Modal,
} from "semantic-ui-react";

import DefaultInterventionTypeDropdown from "./ui/DefaultInterventionTypeDropdown";

import {
    genericResponseHandler,
    QAV_UUID,
    IND_NUM_STEERING_MEMBERS_UUID,
} from "../../../../utils";
import { useParams, useHistory } from "react-router-dom";
import { Interventions, Indicators } from "../../../../api";

import { AppContext } from "../../../../state";

import CapGuard from "../../../../components/ui/CapGuard";
import MyCitiesEditIndicatorRow from "./MyCitiesEditIndicatorRow";
import CreateCustomIndicator from "./ui/CreateCustomIndicator";
import PredefinedIndicatorDropdown from "./ui/PredefinedIndicatorDropdown";
import AssignApprovers from "./ui/AssignApprovers";
import AssignUsers from "./ui/AssignUsers";

/*****************************************************************************/
const MyCitiesEditIntervention = () => {
    const app = React.useContext(AppContext);
    const history = useHistory();

    const { city_uuid, int_uuid } = useParams();

    const [isReadyForApproval, setIsReadyForApproval] = React.useState(false);
    const [intData, setIntData] = React.useState(null);

    const [showBasicEdit, setShowBasicEdit] = React.useState(false);
    const [frmBasicEdit, setFrmBasicEdit] = React.useState({});
    const [deleteInProgress, setDeleteInProgress] = React.useState(false);
    const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const [ignoredApprover, forceUpdateApprover] = React.useReducer(
        (x) => x + 1,
        0
    );

    React.useEffect(() => {
        setIntData(undefined);
        (async () => {
            try {
                const int_result = await Interventions.get(int_uuid);

                let qav_index = _.findIndex(int_result.data.indicators, {
                    default_indicator_uuid: QAV_UUID,
                });

                if (qav_index >= 0) {
                    int_result.data.indicators.splice(qav_index, 1);
                }

                setIntData(int_result.data);

                setIsReadyForApproval(
                    int_result.data.current_status !== "Draft" &&
                        int_result.data.current_status !== "Approved"
                );
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, [int_uuid, ignored, city_uuid]); // eslint-disable-line

    /* ---------------------------------------------------------- */
    function onForceFullReload() {
        forceUpdate();
    }

    /* ---------------------------------------------------------- */
    const onDeleteIndicator = async (indicator_uuid) => {
        try {
            await Indicators.delete_indicator(indicator_uuid);
            const result = await Interventions.get(int_uuid);
            setIntData(result.data);
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    403: "Cannot remove indicators with existing data.",
                },
                "Error"
            );
        }
        setDeleteInProgress(false);
    };

    /* ---------------------------------------------------------- */
    const onAddDefaultInt = async (type_uuid) => {
        try {
            const result = await Interventions.add_type(int_uuid, type_uuid);
            setIntData(result.data);
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    409: "This type is already assigned to the intervention.",
                },
                "Error"
            );
        }
    };

    /* ---------------------------------------------------------- */
    const onAddPredefIndicator = async (predef_uuid) => {
        try {
            const result = await Indicators.add_default_indicator(
                int_uuid,
                predef_uuid
            );
            setIntData(result.data);
        } catch (err) {
            genericResponseHandler(err, app);
        }
    };

    /* ---------------------------------------------------------- */
    const onCreateCustomInd = () => {
        forceUpdate();
    };

    /* ---------------------------------------------------------- */
    const renderIndicators = () => {
        if (_.isEmpty(intData.indicators))
            return (
                <Table.Row>
                    <Table.Cell colSpan="10" textAlign="center">
                        <Header content="No indicators." color="red" as="h2" />
                    </Table.Cell>
                </Table.Row>
            );

        // get parents
        const parents = intData.indicators.filter(
            (ind) => ind.parent_indicator_uuid === null
        );

        let parent_ids = {};

        // get children
        let children = intData.indicators.filter((ind) => {
            if (ind.parent_indicator_uuid !== null)
                parent_ids[ind.parent_indicator_uuid] = true;

            return ind.parent_indicator_uuid !== null;
        });

        let output = [];

        parents.forEach((parent) => {
            // `isConsiderCalculated` currently goes true if any children
            // claim them as a parent; if not we'll let it go as a regular
            // field

            let isConsiderCalculated = parent_ids[parent.uuid];

            if (isConsiderCalculated) {
                // now we have to see if any of those children actually have
                // data in them. If so, we can disown them and not need to
                // be isConsideredCalculated
                let has_data =
                    intData.indicators.filter((ind) => {
                        if (ind.parent_indicator_uuid === parent.uuid) {
                            return !!ind.current_value;
                        }
                    }).length > 0;

                if (!has_data) isConsiderCalculated = false;

                if (
                    parent.default_indicator_uuid ===
                    IND_NUM_STEERING_MEMBERS_UUID
                ) {
                    isConsiderCalculated = false;
                }
            }

            output.push(
                <MyCitiesEditIndicatorRow
                    key={parent.uuid}
                    indData={parent}
                    isInApproval={isReadyForApproval}
                    onForceFullReload={onForceFullReload}
                    isConsiderCalculated={isConsiderCalculated}
                    deleteInProgress={deleteInProgress}
                    onDelete={(indicator_uuid) => {
                        setDeleteInProgress(true);
                        onDeleteIndicator(indicator_uuid);
                    }}
                />
            );

            // look for any children claiming this indicator to be their parent,
            // attach them next

            var mychildrens = null;
            while (
                !_.isEmpty(
                    (mychildrens = _.remove(
                        children,
                        (child) => child.parent_indicator_uuid === parent.uuid
                    ))
                )
            ) {
                _.each(mychildrens, (child) => {
                    output.push(
                        <MyCitiesEditIndicatorRow
                            key={child.uuid}
                            indData={child}
                            isInApproval={isReadyForApproval}
                            deleteInProgress={deleteInProgress}
                            onDelete={(indicator_uuid) => {
                                setDeleteInProgress(true);
                                onDeleteIndicator(indicator_uuid);
                            }}
                        />
                    );
                });
            }
        });

        return output;
    };

    /* ---------------------------------------------------------- */
    async function onSaveIntBasic() {
        try {
            await Interventions.update(
                intData.uuid,
                intData.city.uuid,
                frmBasicEdit.name,
                frmBasicEdit.short_description,
                frmBasicEdit.long_description
            );

            setShowBasicEdit(false);
            forceUpdate();
        } catch (err) {
            genericResponseHandler(err, app);
        }
    }

    /* ---------------------------------------------------------- */
    function onAssignedUser(user) {
        intData.users.push(user);
        forceUpdateApprover();
    }

    /* ---------------------------------------------------------- */
    function onRemoveAssignedUser(rm_user) {
        intData.users = intData.users.filter(
            (user) => user.uuid !== rm_user.uuid
        );
    }

    function onClickEditBasic() {
        setFrmBasicEdit({
            name: intData.name,
            short_description: intData.short_description,
            long_description: intData.long_description,
        });
        setShowBasicEdit(true);
    }

    /* ---------------------------------------------------------- */
    return (
        <CapGuard
            needCaps={[
                "own_city_intervention:edit",
                "all_city_intervention:edit",
            ]}
            redirectTo="/"
        >
            {!intData ? (
                <Segment placeholder>
                    <Loader active content="Loading intervention data..." />
                </Segment>
            ) : (
                <React.Fragment>
                    <Grid columns={2}>
                        <Grid.Column width={11}>
                            <Header as="h1" color="red">
                                {`Setup: "${intData.name}"`}
                                <br />
                                <Button
                                    icon="pencil"
                                    content="Edit"
                                    size="tiny"
                                    onClick={onClickEditBasic}
                                />
                            </Header>
                            {!_.isEmpty(intData.types) &&
                                intData.types.map((type) => (
                                    <Label
                                        key={type.uuid}
                                        content={type.name}
                                        color="green"
                                        size="tiny"
                                    />
                                ))}
                        </Grid.Column>
                        <Grid.Column textAlign="right" width={5}>
                            <Button.Group>
                                <Button
                                    icon="chart line"
                                    color="blue"
                                    onClick={() => {
                                        history.push(
                                            `/intervention/${intData.uuid}`
                                        );
                                    }}
                                />
                                <Button
                                    content={`Back to My Interventions`}
                                    icon="left arrow"
                                    onClick={() =>
                                        history.push(`/myinterventions`)
                                    }
                                />
                            </Button.Group>
                        </Grid.Column>
                    </Grid>

                    <Container>
                        <Form>
                            <Grid columns={1}>
                                <Grid.Column textAlign="right"></Grid.Column>
                            </Grid>
                        </Form>

                        <Table color="yellow" compact="very" celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Indicator Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Value / Target / Baseline
                                    </Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{renderIndicators()}</Table.Body>
                        </Table>

                        <DefaultInterventionTypeDropdown
                            onSelect={onAddDefaultInt}
                            isInApproval={isReadyForApproval}
                        />

                        <PredefinedIndicatorDropdown
                            onSelect={onAddPredefIndicator}
                            ignored={ignored}
                            isInApproval={isReadyForApproval}
                        />

                        <br />

                        <CreateCustomIndicator
                            onCreateCustomInd={onCreateCustomInd}
                            indicators={intData.indicators}
                            intUUID={int_uuid}
                            isInApproval={isReadyForApproval}
                        />

                        <AssignApprovers
                            intervention={intData}
                            ignoredApprover={ignoredApprover}
                            isInApproval={isReadyForApproval}
                        />

                        <AssignUsers
                            intData={intData}
                            cityUUID={intData.city.uuid}
                            onAssignedUser={onAssignedUser}
                            onRemoveAssignedUser={onRemoveAssignedUser}
                        />
                    </Container>
                </React.Fragment>
            )}
            <Container textAlign="right">
                <Button.Group>
                    <Button
                        icon="chart line"
                        color="blue"
                        onClick={() => {
                            history.push(`/intervention/${intData.uuid}`);
                        }}
                    />
                    <Button
                        content={`Back to My Interventions`}
                        icon="left arrow"
                        onClick={() => history.push(`/myinterventions`)}
                    />
                </Button.Group>
            </Container>
            <Modal open={showBasicEdit} onClose={() => setShowBasicEdit(false)}>
                <Modal.Header>
                    <Icon name="target" />
                    Intervention Details Edit
                </Modal.Header>
                <Modal.Content>
                    {intData && (
                        <Form>
                            <Form.Input
                                label="Intervention Name"
                                defaultValue={intData.name}
                                maxLength={30}
                                onChange={(e) =>
                                    setFrmBasicEdit({
                                        ...frmBasicEdit,
                                        name: e.target.value,
                                    })
                                }
                            />
                            <Form.Input
                                label="Short Description"
                                defaultValue={intData.short_description}
                                onChange={(e) =>
                                    setFrmBasicEdit({
                                        ...frmBasicEdit,
                                        short_description: e.target.value,
                                    })
                                }
                            />
                            <Form.TextArea
                                label="Long Description"
                                defaultValue={intData.long_description}
                                onChange={(e) =>
                                    setFrmBasicEdit({
                                        ...frmBasicEdit,
                                        long_description: e.target.value,
                                    })
                                }
                            />
                        </Form>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button
                            negative
                            icon="cancel"
                            content="Cancel"
                            onClick={() => setShowBasicEdit(false)}
                        />
                        <Button
                            positive
                            icon="checkmark"
                            content="Save"
                            onClick={onSaveIntBasic}
                        />
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        </CapGuard>
    );
};

export default MyCitiesEditIntervention;
