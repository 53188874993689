import React from "react";
import { Container, Header, Grid, Label } from "semantic-ui-react";
import { Bar } from "react-chartjs-2";

import { getCSSVar, colorGrabber, QAV_KEYS } from "../../../../../utils";

const DashCityGraph = ({ cityData }) => {
    const [graphData, setGraphData] = React.useState({});
    const baseGraphConfig = {
        labels: ["Falling Behind", "Marginal", "On Track"],
        datasets: [],
    };

    /* -------------------------- */
    React.useEffect(() => {
        rebuildCityDatasets(cityData);
    }, [cityData]); // eslint-disable-line

    /* -------------------------- */
    async function rebuildCityDatasets(cities) {
        let datasets = [
            {
                label: cityData.name,
                backgroundColor: colorGrabber(cityData.uuid),
                hoverBackgroundColor: colorGrabber(cityData.uuid),
                data: [
                    cityData.interventions.filter(
                        (int) =>
                            int.current_status === "Approved" &&
                            int.reporting_period &&
                            int.reporting_period.qualitative_indicator ===
                                QAV_KEYS.BAD
                    ).length, // bar 1 - falling behind
                    cityData.interventions.filter(
                        (int) =>
                            int.current_status === "Approved" &&
                            int.reporting_period &&
                            int.reporting_period.qualitative_indicator ===
                                QAV_KEYS.MED
                    ).length, // bar 2 - marginal
                    cityData.interventions.filter(
                        (int) =>
                            int.current_status === "Approved" &&
                            int.reporting_period &&
                            int.reporting_period.qualitative_indicator ===
                                QAV_KEYS.GOO
                    ).length, // bar 3 - on track
                ],
            },
        ];

        setGraphData({
            ...baseGraphConfig,
            datasets,
        });
    }

    /* -------------------------- */
    return !(cityData && graphData) ? (
        <React.Fragment></React.Fragment>
    ) : (
        <Container>
            <Header
                content="Number of interventions by status of progress toward program-level goals"
                as="h3"
                icon="crosshairs"
            />
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Bar
                            data={graphData}
                            width={100}
                            height={50}
                            options={{
                                maintainAspectRatio: true,
                                tooltips: {
                                    mode: "x",
                                    filter: (item) => item.value > 0,
                                    callbacks: {
                                        label: function (tool, data) {
                                            const labels = data.datasets.filter(
                                                (d) => d.data[tool.index] > 0
                                            );
                                            const matchingCity = [];
                                            labels.map((label) => {
                                                if (
                                                    label.label.split(
                                                        ","
                                                    )[0] === cityData.city_name
                                                )
                                                    matchingCity.push(cityData);
                                            });
                                            let labelString = "";
                                            let interventionNames = "";
                                            matchingCity.map((x) =>
                                                x.interventions.map((y) => {
                                                    if (
                                                        tool.xLabel ===
                                                            "Falling Behind" &&
                                                        y.reporting_period &&
                                                        y.reporting_period
                                                            .qualitative_indicator !==
                                                            QAV_KEYS.BAD
                                                    ) {
                                                        return false;
                                                    } else if (
                                                        tool.xLabel ===
                                                            "Marginal" &&
                                                        y.reporting_period &&
                                                        y.reporting_period
                                                            .qualitative_indicator !==
                                                            QAV_KEYS.MED
                                                    ) {
                                                        return false;
                                                    } else if (
                                                        tool.xLabel ===
                                                            "On Track" &&
                                                        y.reporting_period &&
                                                        y.reporting_period
                                                            .qualitative_indicator !==
                                                            QAV_KEYS.GOO
                                                    ) {
                                                        return false;
                                                    }
                                                    interventionNames += `${y.name};  `;
                                                })
                                            );
                                            matchingCity.map(
                                                (mCity) =>
                                                    (labelString += `${
                                                        mCity.city_name
                                                    } - ${
                                                        tool.yLabel
                                                    } \n ${interventionNames.slice(
                                                        0,
                                                        -3
                                                    )}`)
                                            );
                                            const finalLabel = labelString;
                                            return finalLabel;
                                        },
                                    },
                                },
                                legend: {
                                    onHover: function (e) {
                                        e.target.style.cursor = "pointer";
                                    },
                                },
                                hover: {
                                    onHover: function (e) {
                                        var point = this.getElementAtEvent(e);
                                        if (point.length)
                                            e.target.style.cursor = "pointer";
                                        else e.target.style.cursor = "default";
                                    },
                                },

                                scales: {
                                    xAxes: [
                                        {
                                            stacked: true,
                                            ticks: {
                                                display: false,
                                                fontColor: getCSSVar(
                                                    " --abif-neutral"
                                                ),
                                                fontSize: 18,
                                                fontStyle: "bold",
                                            },
                                            gridLines: {
                                                display: false,
                                            },
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            scaleLabel: {},
                                            stacked: true,
                                            labels: true,
                                            gridLines: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: true,
                                                callback: function (value) {
                                                    if (value % 1 === 0) {
                                                        return value;
                                                    }
                                                },
                                            },
                                        },
                                    ],
                                },
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className="graph-label">
                        <Label color="red">Falling Behind</Label>

                        <Label color="yellow">Marginal</Label>
                        <Label color="green">On Track</Label>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default DashCityGraph;
