import React from "react";
import { Accordion, Icon, Form, Segment, Container } from "semantic-ui-react";
import { genericResponseHandler } from "../../../../../utils";
import { AppContext } from "../../../../../state";
import Indicators from "../../../../../api/indicators";

const CreateCustomIndicator = ({
    intUUID,
    indicators,
    isInApproval,
    onCreateCustomInd,
}) => {
    const app = React.useContext(AppContext);

    const [isOpen, setIsOpen] = React.useState(false);
    const [formState, setFormState] = React.useState({});
    const [formParentID, setFormParentID] = React.useState(null); // total hack

    /*------------------------------*/
    const onFormUpdate = (e, input) => {
        setFormState({
            ...formState,
            [input.name]:
                (e.target && e.target.value && e.target.value.trim()) ||
                input.value ||
                input.checked,
        });
    };

    /*------------------------------*/
    const onSubmit = async (e) => {
        const defaultOptions = {
            intervention: intUUID,
            required_baseline: false,
            required_field: false,
            required_target: false,
        };
        try {
            await Indicators.create(
                {
                    ...defaultOptions,
                    ...formState,
                },
                formParentID
            );

            if (onCreateCustomInd) {
                onCreateCustomInd();
                setIsOpen(false);
            }
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    409: "Custom indicator already exists.",
                },
                "Error"
            );
        }
    };

    return (
        <Accordion>
            <Accordion.Title
                active={isOpen}
                index={0}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h4>
                    <Icon name="dropdown" />
                    <Icon name="chart bar" color="violet" />
                    Custom Indicator
                </h4>
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
                <Segment raised color="violet">
                    <Form onSubmit={onSubmit}>
                        <Form.Group widths="equal">
                            <Form.Input
                                label="Name"
                                name="name"
                                required
                                onChange={onFormUpdate}
                                disabled={isInApproval}
                            />
                            <Form.Dropdown
                                selection
                                search
                                required
                                onChange={onFormUpdate}
                                name="data_type"
                                label="Data Type"
                                disabled={isInApproval}
                                options={[
                                    {
                                        text: "Numeric, #",
                                        value:
                                            "b70b0c27-3162-4bf0-9841-55f9cfac0ef2",
                                    },
                                    {
                                        text: "Numeric, $",
                                        value:
                                            "918d6e9d-64d3-4d50-8cd8-e52dcb6d858b",
                                    },
                                    {
                                        text: "Percentage, %",
                                        value:
                                            "11ced804-bdf0-4dc5-a093-bbe48dd2fd60",
                                    },
                                    {
                                        text: "Text field",
                                        value:
                                            "2b0d2c1a-da6a-44e0-a32d-15ccf89e3b2c",
                                    },
                                    {
                                        text: "Open text field",
                                        value:
                                            "40c37c28-f3b3-4f60-85c7-1b9987c97fe7",
                                    },
                                    {
                                        text: "Date: MM-DD-YYYY",
                                        value:
                                            "197821bc-0255-4031-a1e0-b387e0e42d20",
                                    },
                                ]}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Dropdown
                                search
                                required
                                label="Cadence"
                                selection
                                name="cadence_type"
                                onChange={onFormUpdate}
                                disabled={isInApproval}
                                options={[
                                    {
                                        text: "Project start",
                                        value:
                                            "5a27f9a5-4e7a-44af-b6f0-8dfe75a6b27d",
                                    },
                                    {
                                        text:
                                            "Project start and when changes occur",
                                        value:
                                            "244452a1-53fe-45b2-86cf-103e6c048736",
                                    },
                                    {
                                        text: "Monthly",
                                        value:
                                            "144b245b-da34-4fc7-b3cb-cf8abd9c5244",
                                    },
                                    {
                                        text: "Quarterly",
                                        value:
                                            "0922d715-b3f5-4448-b011-70d09fec46d9",
                                    },
                                ]}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Dropdown
                                selection
                                search
                                onChange={(e, i) => {
                                    setFormParentID(i.value);
                                }}
                                name="parent_indicator"
                                label="Parent Indicator"
                                disabled={isInApproval}
                                options={[
                                    ...[{ text: "None", value: null }],
                                    ...indicators.filter(i => !i.is_common && !i.is_header).map((int) => {
                                        return {
                                            text: int.name,
                                            value: int.uuid,
                                        };
                                    }),
                                ]}
                            />
                        </Form.Group>
                        <Form.Group grouped>
                            <Form.Checkbox
                                name="required_field"
                                label="Required"
                                onChange={onFormUpdate}
                                disabled={isInApproval}
                            />
                        </Form.Group>

                        <Container textAlign="right">
                            <Form.Button
                                primary
                                type="submit"
                                disabled={isInApproval}
                                content="Create Custom Indicator"
                            />
                        </Container>
                    </Form>
                </Segment>
                <br />
            </Accordion.Content>
        </Accordion>
    );
};

export default CreateCustomIndicator;
