import React from "react";
import { Table } from "semantic-ui-react";
import Indicator from "./ui/Indicator";

/**
 *
 * @param {*} props - indData, considerCalculated, restrictByPeriod
 */
const IndicatorRow = (props) => {
    // FIXME: these logical rendering shorthands are causing
    // spurious 'false' and 'true' class names; this "works" and doesn't
    // functionally break anything, but it's wrong.

    return (
        <Table.Row
            key={props.indData.uuid}
            className={`indicator-row ${
                props.considerCalculated ? "calculated" : ""
            }
            ${!props.indData.parent_indicator_uuid || "subindicator"} ${
                props.indData.is_header && "header"
            }`}
        >
            <Table.Cell>
                <Indicator {...props} />
            </Table.Cell>
        </Table.Row>
    );
};

export default IndicatorRow;
