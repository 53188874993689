import "../../../../styles/pages/Admin.scss";

import React from "react";

import CapGuard from "../../../ui/CapGuard";

import Intervention from "./Intervention";

import { useParams } from "react-router-dom";

const Report = () => {
    const { int_uuid, reporting_year, reporting_period } = useParams();

    /* ------------------------------------------------ */
    return (
        <CapGuard
            needCaps={[
                "own_city_data:view",
                "all_city_data:view",
                "specific_intervention:view",
            ]}
            redirectTo="/"
        >
            <Intervention
                intUUID={int_uuid}
                reporting_year={reporting_year}
                reporting_period={reporting_period}
            />
        </CapGuard>
    );
};
export default Report;
