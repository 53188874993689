import React, { useState } from "react";
import Moment from "react-moment";
import { Button, Table, Accordion } from "semantic-ui-react";

const UserStats = ({ userData }) => {
    const [isActive, setActive] = useState(false);

    return (
        <Accordion>
            <Accordion.Title
                index={0}
                active={isActive}
                onClick={() => setActive(!isActive)}
            >
                <Button icon="bug" />
            </Accordion.Title>
            <Accordion.Content index={0} active={isActive}>
                <Table definition textAlign="right" compact="very">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Created</Table.Cell>
                            <Table.Cell>
                                <Moment
                                    format="YYYY-MM-DD @ HH:mm"
                                    title={userData.date_created}
                                >
                                    {userData.date_created}
                                </Moment>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Modified</Table.Cell>
                            <Table.Cell>
                                <Moment
                                    format="YYYY-MM-DD @ HH:mm"
                                    title={userData.date_updated}
                                >
                                    {userData.date_updated}
                                </Moment>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>UUID</Table.Cell>
                            <Table.Cell>{userData.uuid}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Email Verified?</Table.Cell>
                            <Table.Cell>
                                {userData.email_verified ? "Yes" : "No"}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Failed Logins</Table.Cell>
                            <Table.Cell>{userData.failed_logins}</Table.Cell>
                        </Table.Row>
                        {userData.date_reset_sent && (
                            <Table.Row>
                                <Table.Cell>Reset Sent</Table.Cell>
                                <Table.Cell>
                                    <Moment
                                        format="YYYY-MM-DD @ HH:mm"
                                        title={userData.date_reset_sent}
                                    >
                                        {userData.date_reset_sent}
                                    </Moment>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Accordion.Content>
        </Accordion>
    );
};

export default UserStats;
