import React from "react";
import "../styles/App.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, Sticky } from "semantic-ui-react";

import Header from "./Header";
import Main from "./Main";
import AlertMessage from "./dialogs/AlertMessage";
import Login from "./pages/Login";
import LoginGuard from "./ui/LoginGuard";

const App = () => {
    return (
        <Container>
            <Router>
                <Switch>
                    <Route path="/login/:return_url" exact component={Login} />
                    <Route path="/login" exact component={Login} />
                    <Route>
                        <LoginGuard>
                            <Sticky>
                                <Header />
                            </Sticky>
                            <Main />
                            <br />
                            <br />
                        </LoginGuard>
                    </Route>
                </Switch>
            </Router>
            <AlertMessage />
        </Container>
    );
};

export default App;
