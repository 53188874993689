import React from "react";
import {
    Accordion,
    Icon,
    Segment,
    Header,
    Grid,
    Button,
    Dropdown,
    Table,
    Loader,
} from "semantic-ui-react";

import _ from "lodash";

import { genericResponseHandler } from "../../../../../utils";
import { AppContext } from "../../../../../state";
import { Cities, Interventions } from "../../../../../api";

const AssignUsers = ({
    intData,
    cityUUID,
    onAssignedUser,
    onRemoveAssignedUser,
}) => {
    const app = React.useContext(AppContext);

    const [isOpen, setIsOpen] = React.useState(false);

    const [userList, setUserList] = React.useState();
    const [assignees, setAssignees] = React.useState();
    const [frmUserUUID, setFrmUserUUID] = React.useState();

    const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);

    /*------------------------------*/
    React.useEffect(() => {
        Cities.reporters(cityUUID).then(
            (response) => {
                setUserList(
                    response.data.reporters.map((user) => {
                        return {
                            key: user.uuid,
                            text: user.name,
                            value: user.uuid,
                        };
                    })
                );

                setAssignees(intData.users);
            },
            (err) => {
                genericResponseHandler(err, app);
            }
        );
    }, [ignored, intData.users, cityUUID]); // eslint-disable-line

    /*------------------------------*/
    const onAssignmentClick = (e, o) => {
        Interventions.assign_user(intData.uuid, frmUserUUID, false).then(
            () => {
                const selected_user = userList.filter(
                    (user) => user.key === frmUserUUID
                );
                onAssignedUser({
                    uuid: selected_user[0].key,
                    email: selected_user[0].text,
                    name: selected_user[0].key,
                });
                forceUpdate();
            },
            (err) => {
                genericResponseHandler(
                    err,
                    app,
                    {
                        409: "User is already assigned this intervention.",
                    },
                    "Error"
                );
            }
        );
    };

    /*------------------------------*/
    const onDeleteUserAssignment = (user_uuid) => {
        // unassign
        Interventions.assign_user(intData.uuid, user_uuid, true).then(
            () => {
                const selected_user = userList.filter(
                    (user) => user.key === user_uuid
                );
                onRemoveAssignedUser({
                    uuid: selected_user[0].key,
                    email: selected_user[0].text,
                    name: selected_user[0].key,
                });

                forceUpdate();
            },
            (err) => {
                genericResponseHandler(err, app);
            }
        );
    };

    /*------------------------------*/
    return (
        <Accordion>
            <Accordion.Title
                active={isOpen}
                index={0}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h4>
                    <Icon name="dropdown" />
                    <Icon name="users" color="orange" />
                    {" Users"}
                </h4>
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
                <Segment.Group>
                    <Segment raised color="orange">
                        <Table compact basic="very" striped>
                            <Table.Body>
                                {!assignees ? (
                                    <Table.Row>
                                        <Table.Cell>
                                            <Loader active />
                                        </Table.Cell>
                                    </Table.Row>
                                ) : _.isEmpty(assignees) ? (
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header
                                                as="h3"
                                                content="No users assigned."
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ) : (
                                    assignees.map((ass) => (
                                        <Table.Row key={ass.uuid}>
                                            <Table.Cell>
                                                <Header
                                                    as="h4"
                                                    content={ass.email}
                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign="right">
                                                <Button
                                                    color="red"
                                                    icon="trash"
                                                    onClick={() =>
                                                        onDeleteUserAssignment(
                                                            ass.uuid
                                                        )
                                                    }
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                )}
                            </Table.Body>
                        </Table>
                    </Segment>
                    <Segment>
                        <Grid columns={2} stretched>
                            <Grid.Column width={12}>
                                {userList && (
                                    <Dropdown
                                        selection
                                        placeholder="Select qualified user..."
                                        onChange={(event, data) => {
                                            setFrmUserUUID(data.value);
                                        }}
                                        options={userList}
                                    />
                                )}
                            </Grid.Column>

                            <Grid.Column width={4}>
                                <Button
                                    disabled={!frmUserUUID}
                                    content="Assign User"
                                    primary
                                    onClick={onAssignmentClick}
                                />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Segment.Group>
                <br />
            </Accordion.Content>
        </Accordion>
    );
};

export default AssignUsers;
