import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import UserMenu from "./usermenu/UserMenu";
import { UserContext } from "../state/UserProvider";

const Header = () => {
    const user = useContext(UserContext);

    const loggedInItems = (
        <React.Fragment>
            {user.hasCapIn([
                "own_city_summary:view",
                "all_city_summary:view",
                "specific_intervention:view",
            ]) && (
                <NavLink
                    activeClassName="active"
                    className="item"
                    to="/myinterventions"
                >
                    <Menu.Item icon="crosshairs" content="My Interventions" />
                </NavLink>
            )}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Menu
                pointing
                secondary
                style={{ backgroundColor: "var(--background)" }}
            >
                <NavLink activeClassName="active" className="item" to="/" exact>
                    <Menu.Item icon="home" content="Home" />
                </NavLink>
                {user.state.isLoggedIn && loggedInItems}
                <UserMenu />
            </Menu>
            <div id="HeaderSpacer"></div>
        </React.Fragment>
    );
};

export default Header;
