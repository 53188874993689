import React, { useEffect } from "react";
import _ from "lodash";
import {
    Container,
    Header,
    Table,
    Loader,
    Label,
    Grid,
    Button,
    Segment,
    Dropdown,
    Icon,
} from "semantic-ui-react";
import { Cities, Interventions } from "../../../api";
import { genericResponseHandler, STATUS_COLOR } from "../../../utils";
import { AppContext, UserContext } from "../../../state";
import { useHistory, useParams } from "react-router-dom";
import IfHaveCapIn from "../../ui/IfHaveCapIn";
import NewInterventionModal from "./dialogs/NewInterventionModal";
import DashboardPeriodSelect from "../dashboard/ui/DashboardPeriodSelect";

/*-----------------------------------------------------------------------*/
const InterventionRow = ({ intervention, onDelete }) => {
    const app = React.useContext(AppContext);
    const history = useHistory();
    const [isDeleted, setIsDeleted] = React.useState(false);

    async function saveExportIntervention() {
        try {
            const response = await Interventions.export_excel(
                intervention.uuid
            );
            const blob = new Blob([response.data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }),
                url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display:none";
            a.href = url;
            a.download = "intervention.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (err) {
            genericResponseHandler(err, app);
        }
    }

    /* --------------------------------------------------- */
    return (
        !isDeleted && (
            <Table.Row key={intervention.uuid}>
                <Table.Cell colSpan={16}>
                    <Grid stackable>
                        <Grid.Column
                            style={{ flex: "1 1 auto" }}
                            verticalAlign="middle"
                        >
                            <Header as="h3" content={intervention.name} />
                            <i>{intervention.short_description}</i>
                        </Grid.Column>
                        <Grid.Column
                            style={{ flex: "0 0 10.4rem" }}
                            verticalAlign="middle"
                        >
                            <IfHaveCapIn
                                needCaps={[
                                    "all_city_summary:view",
                                    "own_city_summary:view",
                                ]}
                            >
                                <Label.Group size="tiny">
                                    <Label
                                        color={
                                            STATUS_COLOR[
                                                intervention.current_status
                                            ]
                                        }
                                        content={intervention.current_status}
                                    />
                                    <IfHaveCapIn
                                        needCaps={["compliance_metrics:view"]}
                                    >
                                        <br />
                                        <Label
                                            content="% On Time"
                                            detail={
                                                100 - intervention.percent_late
                                            }
                                        />
                                        <br />
                                        <Label
                                            content="% Reported"
                                            detail={
                                                intervention.percent_reported ||
                                                "--"
                                            }
                                        />
                                    </IfHaveCapIn>
                                </Label.Group>
                            </IfHaveCapIn>
                        </Grid.Column>
                        <Grid.Column
                            style={{ flex: "0 1 10rem" }}
                            verticalAlign="middle"
                            floated="right"
                            textAlign="right"
                        >
                            <Button.Group>
                                <IfHaveCapIn
                                    needCaps={[
                                        "own_city_data:view",
                                        "all_city_data:view",
                                        "specific_intervention:edit",
                                    ]}
                                >
                                    <Button
                                        icon="chart line"
                                        title="Report"
                                        primary
                                        onClick={() => {
                                            history.push(
                                                `/intervention/${intervention.uuid}`
                                            );
                                        }}
                                    />
                                </IfHaveCapIn>
                                <Dropdown
                                    as={Button}
                                    text="..."
                                    button
                                    icon={null}
                                    direction="left"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={saveExportIntervention}
                                        >
                                            <Icon name="download" />
                                            Export Intervention Data to Excel
                                        </Dropdown.Item>
                                        <IfHaveCapIn
                                            needCaps={[
                                                "own_city_intervention:edit",
                                                "all_city_intervention:edit",
                                            ]}
                                        >
                                            <Dropdown.Item
                                                onClick={() => {
                                                    history.push(
                                                        `/intervention/${intervention.uuid}/edit`
                                                    );
                                                }}
                                            >
                                                <Icon name="pencil" />
                                                Setup Intervention
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={async () => {
                                                    if (
                                                        await onDelete(
                                                            intervention.uuid
                                                        )
                                                    )
                                                        setIsDeleted(true);
                                                }}
                                            >
                                                <Icon
                                                    color="red"
                                                    name="trash"
                                                />
                                                Delete Intervention
                                            </Dropdown.Item>
                                        </IfHaveCapIn>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                        </Grid.Column>
                    </Grid>
                </Table.Cell>
            </Table.Row>
        )
    );
};

/*-----------------------------------------------------------------------*/
const MyInterventions = () => {
    const app = React.useContext(AppContext);
    const user = React.useContext(UserContext);
    const history = useHistory();
    const { reporting_year, reporting_period } = useParams();

    const [cities, setCities] = React.useState([]);
    const [citiesLoaded, setCitiesLoaded] = React.useState(false);
    const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);

    // implementation note: this will contain a city UUID to trigger dialog opening
    const [showNewIntDialog, setShowNewIntDialog] = React.useState(undefined);

    /* --------------------------------------------------- */
    useEffect(() => {
        setCities(null);
        setCitiesLoaded(false);
        (async () => {
            try {
                let cities = await Cities.list(
                    reporting_year,
                    reporting_period
                );

                if (!user.hasCap("all_city_data:view")) {
                    cities.data.cities = cities.data.cities.filter((city) =>
                        _.find(city.users, {
                            uuid: user.state.uuid,
                        })
                    );
                }

                setCities(
                    cities.data.cities.sort((a, b) => {
                        return a.city_name > b.city_name ? 1 : -1;
                    })
                );
                setCitiesLoaded(true);
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, [ignored, reporting_year, reporting_period]); // eslint-disable-line

    /*-----------------------------------------------------------------------*/
    async function saveExportProgramOrCity(
        app,
        city_uuid = undefined,
        city_name = undefined
    ) {
        try {
            let response = undefined;
            let name = "program";

            if (city_uuid) {
                response = await Cities.export_city(
                    city_uuid,
                    reporting_year,
                    reporting_period
                );
                name = city_name
                    .replace(" ", "_")
                    .replace(",", "")
                    .replace(".", "");
            } else {
                response = await Cities.export_program(
                    reporting_year,
                    reporting_period
                );
            }

            const blob = new Blob([response.data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }),
                url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display:none";
            a.href = url;
            a.download = `${name}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (err) {
            genericResponseHandler(err, app);
        }
    }

    /* --------------------------------------------------- */
    const deleteIntervention = async (int_uuid) => {
        try {
            await Interventions.del(int_uuid);
            return true;
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    403: "This intervention already contains populated indicators and cannot be deleted.",
                },
                "Denied"
            );
            return false;
        }
    };

    /*--------------------------------------------------------*/
    const renderCityInterventions = (city) => {
        return (
            <React.Fragment key={city.uuid}>
                <Table.Row style={{ backgroundColor: "#ffffdd" }}>
                    <Table.Cell width={12}>
                        <Label ribbon color="yellow">
                            <Header as="h4" content={city.name} icon="globe" />
                        </Label>
                    </Table.Cell>
                    <Table.Cell style={{ width: "11rem" }}>
                        <IfHaveCapIn needCaps={["compliance_metrics:view"]}>
                            {!_.isEmpty(city.interventions) && (
                                <Label.Group size="tiny">
                                    <Label
                                        content="% On Time"
                                        detail={100 - city.percent_late}
                                    />
                                    <br />
                                    <Label
                                        content="% Reported"
                                        detail={city.percent_reported || "--"}
                                    />
                                </Label.Group>
                            )}
                        </IfHaveCapIn>
                    </Table.Cell>
                    <Table.Cell
                        style={{ width: "10rem" }}
                        textAlign="right"
                        verticalAlign="middle"
                    >
                        <Button.Group>
                            <IfHaveCapIn
                                needCaps={[
                                    "all_city_intervention:edit",
                                    "own_city_intervention:edit",
                                ]}
                            >
                                <Button
                                    icon="plus"
                                    color="violet"
                                    title="Add New Intervention"
                                    onClick={() =>
                                        setShowNewIntDialog(city.uuid)
                                    }
                                />
                            </IfHaveCapIn>
                            <Button
                                icon="download"
                                title="Export City Data to Excel"
                                onClick={() =>
                                    saveExportProgramOrCity(
                                        app,
                                        city.uuid,
                                        city.name
                                    )
                                }
                            />
                        </Button.Group>
                    </Table.Cell>
                </Table.Row>

                {_.isEmpty(city.interventions) ? (
                    <Table.Row
                        key={city.uuid}
                        error={_.isEmpty(city.interventions)}
                    >
                        <Table.Cell colSpan={16}>
                            <i>No interventions accessible.</i>
                        </Table.Cell>
                    </Table.Row>
                ) : (
                    city.interventions.map((int) => (
                        <InterventionRow
                            key={int.uuid}
                            intervention={int}
                            cityUUID={city.uuid}
                            onDelete={(int_uuid) =>
                                deleteIntervention(int_uuid)
                            }
                        />
                    ))
                )}
            </React.Fragment>
        );
    };

    /*--------------------------------------------------------*/
    return !citiesLoaded ? (
        <Segment placeholder>
            <Loader active>Loading cities...</Loader>
        </Segment>
    ) : _.isEmpty(cities) ? (
        <Header textAlign="center" color="red" as="h2">
            There are no cities assigned to you.
        </Header>
    ) : (
        <Container>
            <Grid columns={2}>
                <Grid.Column>
                    <Header
                        as="h1"
                        icon="crosshairs"
                        content="My Interventions"
                    />
                    <Button
                        icon="download"
                        title="Export Program Data to Excel"
                    />
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <IfHaveCapIn needCaps={["compliance_metrics:view"]}>
                        <DashboardPeriodSelect
                            chosenPeriod={{ reporting_year, reporting_period }}
                            onPeriodSelected={(year, period) => {
                                history.push(
                                    `/myinterventions` +
                                        (year ? `/${year}/${period}` : "")
                                );
                            }}
                        />
                    </IfHaveCapIn>
                </Grid.Column>
            </Grid>
            <Table>
                <Table.Body>
                    {_.isEmpty(cities) ? (
                        <React.Fragment></React.Fragment>
                    ) : (
                        cities.map((city) => renderCityInterventions(city))
                    )}
                </Table.Body>
            </Table>
            <NewInterventionModal
                cityUUID={showNewIntDialog}
                onCreated={() => {
                    setShowNewIntDialog(undefined);
                    forceUpdate();
                }}
                onCancel={() => {
                    setShowNewIntDialog(undefined);
                }}
                open={showNewIntDialog !== undefined}
            />
        </Container>
    );
};

export default MyInterventions;
