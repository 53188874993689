import config from "../config";
import jwt from "jsonwebtoken";
import { Auth } from ".";

import { appDispatch } from "../state/AppProvider";

const TOKEN_KEY = `jwt-${process.env.NODE_ENV}`;

/***************************************************************
 *
 */
export const isTokenExpired = () => {
    const tok = getAuthToken();

    if (!tok) return true;

    let decoded = jwt.decode(tok);

    const now = Math.floor(Date.now() / 1000);
    return now > decoded.exp;
};

/***************************************************************
 * Hook outgoing requests to always include our token
 * @param {AxiosInstance} instance Axios instance to hook into
 */
export const insertTokenInterceptor = (instance) => {
    instance.interceptors.request.use((request) => {
        let tok = getAuthToken();

        if (tok) {
            request.headers["Authorization"] = `Bearer ${getAuthToken()}`;
        }

        return request;
    });
};

/***************************************************************
 *
 */
const getTokenRefreshTimeMS = (tok) => {
    let decoded = jwt.decode(tok);
    let refresh_time_ms = (decoded.exp - decoded.iat) * 1000;
    return refresh_time_ms;
};

/***************************************************************/
export let tokenKeepAliveHandle = null;

export const tokenKeepAlive = () => {
    const tok = getAuthToken();

    if (tok) {
        Auth.refresh_token().catch((err, bar) => {
            if (err.request.status === 401) {
                // Can't refresh token, so let's try logging in again
                clearTimeout(tokenKeepAliveHandle);
                appDispatch({
                    type: "show-user-login-dialog-timeout",
                    payload: true,
                });
                return;
            }
        });
    }
};

/***************************************************************
 *
 */
export const startTokenKeepAliveHeartbeat = () => {
    const timeout = getTokenRefreshTimeMS(getAuthToken()) / 2;

    // stop it if it's already running
    if (tokenKeepAliveHandle) {
        clearTimeout(tokenKeepAliveHandle);
    }

    tokenKeepAliveHandle = setTimeout(tokenKeepAlive, timeout);
};

/***************************************************************
 * Extracts token from headers, stores in sessionStorage, and
 * uses it in subsequent API requests. If a token header is not
 * found, the request throws a warning, but is otherwise ignored.
 *
 * @param {Object} headers - An object containing request headers (looking for "x-iina-token")
 */
export const setAuthTokens = (headers) => {
    const tok = headers["x-iina-token"];

    if (tok) {
        sessionStorage.setItem(TOKEN_KEY, JSON.stringify(tok));
        startTokenKeepAliveHeartbeat();
    } else {
        console.warn("A token was expected, but not found in headers", headers);
    }
};

/***************************************************************
 * Removes the current token from sessionStorage
 */
export const clearAuthTokens = () => {
    if (tokenKeepAliveHandle) {
        clearTimeout(tokenKeepAliveHandle);
    }
    sessionStorage.removeItem(TOKEN_KEY);
};

/***************************************************************
 * Returns the current token from sessionStorage
 * @returns {?string} Token from sessionStorage, or null
 */
export const getAuthToken = () => {
    let tok = sessionStorage.getItem(TOKEN_KEY);

    if (tok) {
        return JSON.parse(sessionStorage.getItem(TOKEN_KEY));
    }

    return null;
};

/***************************************************************
 * If we have a token, the user is considered logged in.
 * @returns {boolean} True if logged in.
 */
export const isLoggedIn = () => {
    const token = getAuthToken();
    return !!token;
};
