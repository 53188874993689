import "../../../styles/pages/Admin.scss";
import React, { useContext } from "react";
import { Container } from "semantic-ui-react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import AdminUserList from "./users/AdminUserList";
import AdminUserNew from "./users/new/AdminUserNew";
import UserEdit from "./users/edit/UserEdit";

import { UserContext } from "../../../state";

const Admin = () => {
    const { path } = useRouteMatch();
    const user = useContext(UserContext);

    if (!user.state.isLoggedIn || !user.hasCap("user:edit")) {
        return <Redirect to={"/"} />;
    }

    return (
        <Container id="Admin">
            <Switch>
                <Route exact path={path}>
                    <Redirect to="/admin/users" />
                </Route>
                <Route path={`${path}/newuser`} component={AdminUserNew} />
                <Route path={`${path}/users`} component={AdminUserList} />
                <Route path={`${path}/user/:uuid`} component={UserEdit} />
            </Switch>
        </Container>
    );
};

export default Admin;
