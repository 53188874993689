import React from "react";
import { Input } from "semantic-ui-react";
import _, { reverse } from "lodash";

/**
 * We provide our own input filtering for numeric input types because Safari is
 * living in 2003.
 *
 * @param {*} props - Same props as a typical Semantic Input
 */
const InputNumeric = (props) => {
    const ref = React.createRef(null);

    const onKeyDown = (event) => {
        const allowedKeys = [
            "Backspace",
            "Enter",
            "ArrowLeft",
            "ArrowRight",
            "Home",
            "End",
            "Tab",
            "F12",
        ];

        // This is here, and in Indicator.js, because inputs don't always
        // trigger onChange when values actually change, so any input here
        // will trigger it now.
        ref.current.inputRef.current.dispatchEvent(
            new Event("change", { bubbles: true })
        );

        if (_.includes(allowedKeys, event.key)) return;

        // only allow one decimal point
        if (event.key === ".") {
            if (event.target.value.indexOf(".") === -1) return;
        }

        if (isNaN(event.key)) event.preventDefault();
    };

    const onBlur = (event) => {
        if (!event.target.value)
            event.target.value = props.defaultValue || props.initialValue || "";
    };

    const newprops = { ...props, type: "text" };

    return (
        <Input
            fluid
            ref={ref}
            {...newprops}
            onBlur={(e) => {
                onBlur(e);
                props.onBlur && props.onBlur(e.target.value);
            }}
            onKeyDown={onKeyDown}
        />
    );
};

export default InputNumeric;
