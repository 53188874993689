export default {
    ERROR_MSG_INVALID_PASS:
        "A valid password must have at least 10 characters, with at least one upper and lowercase letter, and one numeric digit.",

    usePasswordRules: (form_object) => {
        form_object.addValidationRule("isValidPassword", (value) => {
            return (
                value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/) &&
                value.length >= 10
            );
        });
    },
};
