export default {
    IS_DEV: process.env.NODE_ENV === "development",
    BASE_URL: process.env.REACT_APP_ABIF_SERVER_HOST,
    covid_sample_json: [
        {
            state: "AK",
            positive: 277,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 1,
            commercialScore: 1,
            grade: "A",
            score: 4,
            negative: 7553,
            pending: null,
            hospitalizedCurrently: null,
            hospitalizedCumulative: 32,
            inIcuCurrently: null,
            inIcuCumulative: null,
            onVentilatorCurrently: null,
            onVentilatorCumulative: null,
            recovered: 85,
            lastUpdateEt: "4/13 00:00",
            checkTimeEt: "4/13 16:43",
            death: 8,
            hospitalized: 32,
            total: 7830,
            totalTestResults: 7830,
            posNeg: 7830,
            fips: "02",
            dateModified: "2020-04-13T04:00:00Z",
            dateChecked: "2020-04-13T20:43:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "f983e175541ad3a4925847864b9e3113301480eb",
        },
        {
            state: "AL",
            positive: 3734,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 0,
            commercialScore: 1,
            grade: "B",
            score: 3,
            negative: 25448,
            pending: null,
            hospitalizedCurrently: null,
            hospitalizedCumulative: 457,
            inIcuCurrently: null,
            inIcuCumulative: 189,
            onVentilatorCurrently: null,
            onVentilatorCumulative: 119,
            recovered: null,
            lastUpdateEt: "4/13 00:00",
            checkTimeEt: "4/13 16:45",
            death: 99,
            hospitalized: 457,
            total: 29182,
            totalTestResults: 29182,
            posNeg: 29182,
            fips: "01",
            dateModified: "2020-04-13T04:00:00Z",
            dateChecked: "2020-04-13T20:45:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "321c872e8e620b39a5e4df126d9fa3251ca6137d",
        },
        {
            state: "AR",
            positive: 1410,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 1,
            commercialScore: 1,
            grade: "A",
            score: 4,
            negative: 19394,
            pending: null,
            hospitalizedCurrently: 74,
            hospitalizedCumulative: 130,
            inIcuCurrently: null,
            inIcuCumulative: 43,
            onVentilatorCurrently: 28,
            onVentilatorCumulative: 39,
            recovered: 391,
            lastUpdateEt: "4/13 13:33",
            checkTimeEt: "4/13 14:58",
            death: 30,
            hospitalized: 130,
            total: 20804,
            totalTestResults: 20804,
            posNeg: 20804,
            fips: "05",
            dateModified: "2020-04-13T17:33:00Z",
            dateChecked: "2020-04-13T18:58:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "0a421ebb1254e16ec9abba9176de7f225ae83f3a",
        },
        {
            state: "AZ",
            positive: 3702,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 0,
            commercialScore: 1,
            grade: "B",
            score: 3,
            negative: 39645,
            pending: null,
            hospitalizedCurrently: 525,
            hospitalizedCumulative: null,
            inIcuCurrently: 286,
            inIcuCumulative: null,
            onVentilatorCurrently: 195,
            onVentilatorCumulative: null,
            recovered: null,
            lastUpdateEt: "4/13 00:00",
            checkTimeEt: "4/13 16:35",
            death: 122,
            hospitalized: null,
            total: 43347,
            totalTestResults: 43347,
            posNeg: 43347,
            fips: "04",
            dateModified: "2020-04-13T04:00:00Z",
            dateChecked: "2020-04-13T20:35:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "e591208feefd6b860d62132d761bf8910e3f6bce",
        },
        {
            state: "CA",
            positive: 22348,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 0,
            commercialScore: 1,
            grade: "B",
            score: 3,
            negative: 168534,
            pending: 13200,
            hospitalizedCurrently: 3015,
            hospitalizedCumulative: null,
            inIcuCurrently: 1178,
            inIcuCumulative: null,
            onVentilatorCurrently: null,
            onVentilatorCumulative: null,
            recovered: null,
            lastUpdateEt: "4/11 00:00",
            checkTimeEt: "4/13 15:42",
            death: 687,
            hospitalized: null,
            total: 204082,
            totalTestResults: 190882,
            posNeg: 190882,
            fips: "06",
            dateModified: "2020-04-11T04:00:00Z",
            dateChecked: "2020-04-13T19:42:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "55622ed914e5c2115eb35d8201984a4132e2ee2d",
        },
        {
            state: "CO",
            positive: 7684,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 1,
            commercialScore: 1,
            grade: "A",
            score: 4,
            negative: 29850,
            pending: null,
            hospitalizedCurrently: null,
            hospitalizedCumulative: 1472,
            inIcuCurrently: null,
            inIcuCumulative: null,
            onVentilatorCurrently: null,
            onVentilatorCumulative: null,
            recovered: null,
            lastUpdateEt: "4/12 00:00",
            checkTimeEt: "4/13 14:33",
            death: 304,
            hospitalized: 1472,
            total: 37534,
            totalTestResults: 37534,
            posNeg: 37534,
            fips: "08",
            dateModified: "2020-04-12T04:00:00Z",
            dateChecked: "2020-04-13T18:33:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "396dfe7ae3a71c17c777381656cb4b64518290e1",
        },
        {
            state: "CT",
            positive: 13381,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 1,
            commercialScore: 1,
            grade: "A",
            score: 4,
            negative: 30928,
            pending: null,
            hospitalizedCurrently: 1760,
            hospitalizedCumulative: null,
            inIcuCurrently: null,
            inIcuCumulative: null,
            onVentilatorCurrently: null,
            onVentilatorCumulative: null,
            recovered: null,
            lastUpdateEt: "4/13 16:00",
            checkTimeEt: "4/13 16:46",
            death: 602,
            hospitalized: null,
            total: 44309,
            totalTestResults: 44309,
            posNeg: 44309,
            fips: "09",
            dateModified: "2020-04-13T20:00:00Z",
            dateChecked: "2020-04-13T20:46:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "b429fb0a32c0889cb55d939240ae69302d37b2d3",
        },
        {
            state: "DC",
            positive: 1955,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 1,
            commercialScore: 1,
            grade: "A",
            score: 4,
            negative: 8979,
            pending: null,
            hospitalizedCurrently: 295,
            hospitalizedCumulative: null,
            inIcuCurrently: 95,
            inIcuCumulative: null,
            onVentilatorCurrently: 31,
            onVentilatorCumulative: null,
            recovered: 507,
            lastUpdateEt: "4/13 00:00",
            checkTimeEt: "4/13 14:46",
            death: 53,
            hospitalized: null,
            total: 10934,
            totalTestResults: 10934,
            posNeg: 10934,
            fips: "11",
            dateModified: "2020-04-13T04:00:00Z",
            dateChecked: "2020-04-13T18:46:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "604592410118fcfb17348522da2b87f9b0f04865",
        },
        {
            state: "DE",
            positive: 1625,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 1,
            commercialScore: 1,
            grade: "A",
            score: 4,
            negative: 10195,
            pending: null,
            hospitalizedCurrently: 201,
            hospitalizedCumulative: null,
            inIcuCurrently: null,
            inIcuCumulative: null,
            onVentilatorCurrently: null,
            onVentilatorCumulative: null,
            recovered: 213,
            lastUpdateEt: "4/12 17:00",
            checkTimeEt: "4/13 16:25",
            death: 35,
            hospitalized: null,
            total: 11820,
            totalTestResults: 11820,
            posNeg: 11820,
            fips: "10",
            dateModified: "2020-04-12T21:00:00Z",
            dateChecked: "2020-04-13T20:25:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "93aefdebaf68194e8750362171d851d4dbc23098",
        },
        {
            state: "FL",
            positive: 20601,
            positiveScore: 1,
            negativeScore: 1,
            negativeRegularScore: 1,
            commercialScore: 1,
            grade: "A",
            score: 4,
            negative: 175606,
            pending: 1789,
            hospitalizedCurrently: null,
            hospitalizedCumulative: 2833,
            inIcuCurrently: null,
            inIcuCumulative: null,
            onVentilatorCurrently: null,
            onVentilatorCumulative: null,
            recovered: null,
            lastUpdateEt: "4/13 10:31",
            checkTimeEt: "4/13 14:50",
            death: 483,
            hospitalized: 2833,
            total: 197996,
            totalTestResults: 196207,
            posNeg: 196207,
            fips: "12",
            dateModified: "2020-04-13T14:31:00Z",
            dateChecked: "2020-04-13T18:50:00Z",
            notes:
                'Please stop using the "total" field. Use "totalTestResults" instead.',
            hash: "163231d3841084f9f41bfe636cf0b7d2fd402802",
        },
    ],
};
