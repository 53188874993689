import React from "react";
import { UserContext } from "../../state";
import { Redirect, useLocation } from "react-router-dom";
import { isTokenExpired } from '../../api/jwt'

/**
 * Redirects if the user is not logged in
 */
const LoginGuard = ({ children }) => {
    const location = useLocation();
    const user = React.useContext(UserContext);

    return (!user.state.isLoggedIn || isTokenExpired()) ? (
        <Redirect to={{ pathname: "/login", state: { from: location } }} />
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
};

export default LoginGuard;
