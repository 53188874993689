import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Dropdown, Button } from "semantic-ui-react";
import { genericResponseHandler } from "../../../../../../utils";
import { Cities } from "../../../../../../api";
import { AppContext } from "../../../../../../state";

const CitiesDropdown = ({ onAddCityClicked }) => {
    const app = useContext(AppContext);

    const [dropdownOptions, setDropdownOptions] = useState({});
    const [selected, setSelected] = useState(null);

    /* ------------------------------------------------- */
    useEffect(() => {
        (async () => {
            try {
                const result = await Cities.list();
                //TODO: How about we not include the cities that are already added?
                setDropdownOptions(
                    _.map(result.data.cities, (city, index) => ({
                        key: city.uuid,
                        text: city.name,
                        value: city.uuid,
                    }))
                );
            } catch (err) {
                genericResponseHandler(err, app);
            }
        })();
    }, []); // eslint-disable-line

    /* ------------------------------------------------- */
    const onDropdownChange = (e, { value }) => {
        setSelected(value);
    };

    /* ------------------------------------------------- */
    const onAddCity = () => {
        if (onAddCityClicked && selected) {
            onAddCityClicked(selected);
        }
    };

    /* ------------------------------------------------- */
    return (
        <React.Fragment>
            {!_.isEmpty(dropdownOptions) && (
                <Dropdown
                    options={dropdownOptions}
                    search
                    selection
                    placeholder="City"
                    onChange={onDropdownChange}
                />
            )}
            <br />
            <br />
            <Button
                icon="plus"
                loading={_.isEmpty(dropdownOptions)}
                disabled={_.isEmpty(dropdownOptions)}
                content="Assign City"
                positive
                onClick={onAddCity}
            />
        </React.Fragment>
    );
};

export default CitiesDropdown;
