import React from "react";
import {
    Accordion,
    Icon,
    Segment,
    Header,
    Grid,
    Button,
    Dropdown,
    Table,
} from "semantic-ui-react";

import _ from "lodash";

import { genericResponseHandler } from "../../../../../utils";
import { AppContext } from "../../../../../state";
import { Cities } from "../../../../../api";
import interventions from "../../../../../api/interventions";

const AssignApprovers = ({ intervention }) => {
    const app = React.useContext(AppContext);

    const [isOpen, setIsOpen] = React.useState(false);

    const [userList, setUserList] = React.useState(undefined);
    const [intData, setIntData] = React.useState(undefined);
    const [assignees, setAssignees] = React.useState(undefined);
    const [frmUserUUID, setFrmUserUUID] = React.useState(undefined);
    const [frmLevel, setFrmLevel] = React.useState(undefined);
    const [isLoading, setIsLoading] = React.useState(false);

    /*------------------------------*/
    React.useEffect(() => {
        setIntData(intervention);

        Cities.approvers(intervention.city.uuid).then((response) => {
            setUserList(
                response.data.approvers.map((user) => {
                    return {
                        key: user.uuid,
                        text: user.name,
                        value: user.uuid,
                    };
                })
            );
        });
    }, [intervention]);

    /*------------------------------*/
    React.useEffect(() => {
        if (!userList || !intData) return;

        setAssignees({
            first: _.find(userList, ["value", intData.first_approval_user]),
            second: _.find(userList, ["value", intData.second_approval_user]),
            third: _.find(userList, ["value", intData.third_approval_user]),
        });
    }, [intData, userList]);

    /*------------------------------*/
    const onAssignmentClick = async () => {
        try {
            setIsLoading(true);
            await interventions.add_approver(
                intData.uuid,
                frmUserUUID,
                frmLevel
            );
            setAssignees({
                ...assignees,
                [frmLevel]: _.find(userList, ["value", frmUserUUID]),
            });
        } catch (err) {
            genericResponseHandler(err, app);
        } finally {
            setIsLoading(false);
        }
    };

    /*------------------------------*/
    return (
        <Accordion>
            <Accordion.Title
                active={isOpen}
                index={0}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h4>
                    <Icon name="dropdown" />
                    <Icon name="checkmark" color="green" />
                    Approvers
                </h4>
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
                <Segment.Group>
                    <Segment raised color="green">
                        {!assignees ? (
                            <Segment placeholder loading />
                        ) : (
                            <Table compact basic="very">
                                <Table.Body>
                                    <React.Fragment>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Header
                                                    as="h4"
                                                    content="Program Officer"
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                {assignees.second ? (
                                                    assignees.second.text
                                                ) : (
                                                    <Header
                                                        as="h5"
                                                        color="red"
                                                        content="Unassigned"
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Header
                                                    as="h4"
                                                    content="ABIF Approver"
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                {assignees.third ? (
                                                    assignees.third.text
                                                ) : (
                                                    <Header
                                                        as="h5"
                                                        color="red"
                                                        content="Unassigned"
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    </React.Fragment>
                                </Table.Body>
                            </Table>
                        )}
                    </Segment>
                    <Segment>
                        <Grid columns={3} stretched>
                            <Grid.Column className="approver-dropdown">
                                {userList && (
                                    <Dropdown
                                        selection
                                        placeholder="Select user..."
                                        onChange={(event, data) =>
                                            setFrmUserUUID(data.value)
                                        }
                                        options={userList}
                                    />
                                )}
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown
                                    selection
                                    placeholder="Select approval level..."
                                    onChange={(event, data) =>
                                        setFrmLevel(data.value)
                                    }
                                    options={[
                                        {
                                            key: "second",
                                            text: "Program Officer",
                                            value: "second",
                                        },
                                        {
                                            key: "third",
                                            text: "ABIF Approver",
                                            value: "third",
                                        },
                                    ]}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Button
                                    disabled={
                                        isLoading || !frmUserUUID || !frmLevel
                                    }
                                    loading={isLoading}
                                    content="Assign Approver"
                                    primary
                                    onClick={() =>
                                        onAssignmentClick(frmUserUUID, frmLevel)
                                    }
                                />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Segment.Group>
                <br />
            </Accordion.Content>
        </Accordion>
    );
};

export default AssignApprovers;
