import axios from "axios";
import { insertTokenInterceptor } from "./jwt";

import config from "../config";
import Auth from "./auth";
import Cities from "./cities";
import User from "./user";
import Roles from "./roles";
import Interventions from "./interventions";
import Indicators from "./indicators";

if (!config.BASE_URL) throw new Error("BASE_URL is not defined");

let AxiosInstance = undefined;

/* ---------------------------------------------------------- */
/* Called from top of index.js */
function setupAxios() {
    const defaultAxiosConfig = {
        baseURL: config.BASE_URL,
        timeout: 180000,
        headers: {
            "x-iina-identifier": "ii.com",
            "Content-Type": "application/json",
            Accept: "*/*",
        },
    };

    AxiosInstance = axios.create(defaultAxiosConfig);

    insertTokenInterceptor(AxiosInstance);

    AxiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (err) => {
            if (err.response.status === 401) {
                sessionStorage.clear();
                setTimeout(() => {
                    window.location = "/";
                }, 1500);
            }
            return Promise.reject(err);
        }
    );
}

export {
    Auth,
    Cities,
    User,
    Roles,
    Interventions,
    Indicators,
    AxiosInstance,
    setupAxios,
};
