import "../styles/Main.scss";
import React from "react";
import { Switch, Route } from "react-router-dom";
import { Container } from "semantic-ui-react";

import Dashboard from "./pages/dashboard/Dashboard";
import Admin from "./pages/admin/Admin";
import Report from "./pages/my_interventions/report/Report";
import UserSettings from "./pages/user/UserSettings";
import UserResetPassword from "./pages/user/UserResetPassword";
import MyInterventions from "./pages/my_interventions/MyInterventions";
import MyCitiesInterventionEdit from "./pages/my_interventions/edit/MyCitiesInterventionEdit";

const Main = () => {
    return (
        <Container id="Main">
            <Switch>
                <Route path="/admin" component={Admin} />
                <Route
                    path="/myinterventions/:reporting_year?/:reporting_period?"
                    component={MyInterventions}
                />
                <Route path="/report" component={Report} />
                <Route path="/entry">Entry</Route>
                <Route path="/settings" component={UserSettings} />
                <Route path="/resetpassword" component={UserResetPassword} />
                <Route
                    path="/intervention/:int_uuid/edit"
                    component={MyCitiesInterventionEdit}
                    exact
                />
                <Route
                    path="/intervention/:int_uuid/:reporting_year/:reporting_period"
                    component={Report}
                />
                <Route path="/intervention/:int_uuid" component={Report} />
                <Route path="/" component={Dashboard} />
            </Switch>
        </Container>
    );
};

export default Main;
