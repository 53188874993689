import React, { useEffect } from "react";
import {
    Input,
    Button,
    Label,
    Table,
    TextArea,
    Dropdown,
    Grid,
    Select,
} from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import InputNumeric from "../../../../../ui/InputNumeric";
import {
    CADENCE_QUARTERLY_UUID,
    CADENCE_MONTHLY_UUID,
    CADENCE_FUTURE_YEARS,
    uniq_id,
} from "../../../../../../utils";

/*****************************************************************************
 * Renders an indicator field based on the data type. Intended for use with
 * data, target, and baseline values.BI_TargetHistory
 * indicator
 * name
 * initialValue
 * onSave
 * color
 */
const BasicInputField = ({
    indicator,
    name = "",
    initialValue,
    isInApproval,
    onSave,
    icon = undefined,
    color,
    isConsiderCalculated = false,
    periodSelect = false,
    historicalDataIndex = undefined,
    onClear = undefined,
}) => {
    const [dataType] = React.useState(indicator.data_type.coerce_to);

    const [fieldValue, setFieldValue] = React.useState(null);
    const [savedValue, setSavedValue] = React.useState(null);
    const [isSaving, setIsSaving] = React.useState(false);
    const [id] = React.useState(uniq_id);

    const [frmTargetYear, setFrmTargetYear] = React.useState(undefined);
    const [frmTargetYearSaved, setFrmTargetYearSaved] = React.useState(
        undefined
    );
    const [frmTargetPeriod, setFrmTargetPeriod] = React.useState(undefined);
    const [frmTargetPeriodSaved, setFrmTargetPeriodSaved] = React.useState(
        undefined
    );
    const [doSaveClick, setDoSaveClick] = React.useReducer((x) => x + 1, 0);
    const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);

    React.useEffect(() => {
        document.addEventListener("saveIfUnsaved", async (e) => {
            setDoSaveClick();
        });
        document.addEventListener("saveIfUnsaved", async (e) => {
            setDoSaveClick();
        });
    }, []); // eslint-disable-line

    /* -------------------------------------- */
    useEffect(() => {
        setFieldValue(initialValue);
        setSavedValue(initialValue);
        if (historicalDataIndex) {
            setFrmTargetYear(historicalDataIndex.reporting_year);
            setFrmTargetYearSaved(historicalDataIndex.reporting_year);
            setFrmTargetPeriod(historicalDataIndex.reporting_period);
            setFrmTargetPeriodSaved(historicalDataIndex.reporting_period);
        }
    }, [initialValue]); // eslint-disable-line

    /* -------------------------------------- */
    function tellIndicatorsToSaveTheirUnsaved() {
        let customEvent = new Event("saveIfUnsaved");
        document.dispatchEvent(customEvent);
    }

    /* -------------------------------------- */
    function isChanged() {
        if (fieldValue === undefined) return false;
        if (savedValue === undefined) return true;

        return (
            fieldValue !== savedValue ||
            frmTargetPeriod !== frmTargetPeriodSaved ||
            frmTargetYear !== frmTargetYearSaved
        );
    }

    /* -------------------------------------- */
    const onDateChange = (e, input) => {
        if (input.value) {
            setFieldValue(moment(input.value).format("MM-DD-YYYY"));
        } else {
            setFieldValue(input.value);
        }
    };

    /* -------------------------------------- */
    React.useEffect(() => {
        onSaveClick();
    }, [doSaveClick]); // eslint-disable-line

    /* -------------------------------------- */
    const onSaveClick = async () => {
        if (!isChanged()) return;

        setIsSaving(true);
        let is_greater = fieldValue > savedValue || savedValue === undefined;

        const success = await onSave(
            fieldValue,
            frmTargetYear,
            frmTargetPeriod,
            is_greater
        );

        if (success) {
            setSavedValue(fieldValue);
            if (periodSelect) {
                setFieldValue(null);
                setSavedValue(null);
                setFrmTargetYear(null);
                setFrmTargetPeriod(null);
                setFrmTargetYearSaved(null);
                setFrmTargetPeriodSaved(null);
                // setFrmTargetYearSaved(frmTargetYear);
                // setFrmTargetPeriodSaved(frmTargetPeriod);
                let el = document.getElementById(id);
                if (el.value) el.value = null;
            }
        }

        setIsSaving(false);
    };

    /* -------------------------------------- */
    async function onEraseEntry() {
        setIsSaving(true);
        const success = await onSave(null, frmTargetYear, frmTargetPeriod);

        if (success) {
            setFieldValue(null);
            setSavedValue(null);

            if (historicalDataIndex || periodSelect) {
                setFrmTargetYearSaved(frmTargetYear);
                setFrmTargetPeriodSaved(frmTargetPeriod);
            }

            let el = document.getElementById(id);
            if (el.value) el.value = null;
        }

        setIsSaving(false);
    }

    /* -------------------------------------- */
    function renderTargetTemporalOffsetControls() {
        let years = [];
        for (
            let i = 2013;
            i <= new Date().getFullYear() + CADENCE_FUTURE_YEARS;
            i++
        ) {
            years.push({ key: i, value: "" + i, text: "" + i });
        }

        let periods = null;

        if (indicator.cadence_type.uuid === CADENCE_QUARTERLY_UUID) {
            periods = ["Q1", "Q2", "Q3", "Q4"].map((p) => {
                return { key: p, value: p, text: p };
            });
        } else if (indicator.cadence_type.uuid === CADENCE_MONTHLY_UUID) {
            periods = [
                "M01",
                "M02",
                "M03",
                "Q1",
                "M04",
                "M05",
                "M06",
                "Q2",
                "M07",
                "M08",
                "M09",
                "Q3",
                "M10",
                "M11",
                "M12",
                "Q4",
            ].map((p) => {
                return { key: p, value: p, text: p };
            });
        } else {
            console.error(
                "Unhandled cadence type for temporal dropdown - WE SHOULD NEVER GET HERE.",
                indicator
            );
            return <React.Fragment></React.Fragment>;
        }
        return (
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Select
                            compact
                            placeholder="Year"
                            options={years}
                            value={frmTargetYear}
                            disabled={historicalDataIndex}
                            onChange={(e, data) => {
                                setFrmTargetYear(data.value);
                            }}
                        />
                        <Select
                            compact
                            placeholder="Period"
                            options={periods}
                            value={frmTargetPeriod}
                            disabled={historicalDataIndex}
                            onChange={(e, data) => {
                                setFrmTargetPeriod(data.value);
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    let guts;

    if (name === null) {
        name = "";
    }

    if (!_.isEmpty(indicator.indicator_option_type)) {
        guts = (
            <div className="input-wrapper">
                <div className="input">
                    <Dropdown
                        selection
                        scrolling
                        fluid
                        id={id}
                        value={fieldValue}
                        disabled={isInApproval}
                        className={`${isChanged() ? "changed-value" : ""}`}
                        options={indicator.indicator_option_type.options.map(
                            (option) => ({
                                key: option.uuid,
                                text: option.name,
                                value: option.name,
                            })
                        )}
                        onChange={(e, input) => setFieldValue(input.value)}
                        onClick={() => tellIndicatorsToSaveTheirUnsaved()}
                    />
                </div>
                {onClear && (
                    <div className="x">
                        <Button
                            icon="x"
                            disabled={isInApproval}
                            onClick={onClear}
                        />
                    </div>
                )}
            </div>
        );
    } else
        switch (dataType) {
            case "date":
                let dateval = undefined;

                if (fieldValue) {
                    try {
                        dateval = new Date(
                            fieldValue.replace(
                                /(\d{2})-(\d{2})-(\d{4})/,
                                "$1/$2/$3"
                            )
                        );
                    } catch (err) {
                        console.log(`Error parsing date: ${fieldValue}`);
                    }
                }

                guts = (
                    <div className="input-wrapper">
                        <div className="input">
                            <SemanticDatepicker
                                style={{ width: "100%", margin: 0 }}
                                format="MM-DD-YYYY"
                                clearable={false}
                                className={`${
                                    isChanged() ? "changed-value" : ""
                                }`}
                                datePickerOnly
                                onChange={onDateChange}
                                id={id}
                                disabled={isInApproval || isConsiderCalculated}
                                onClick={() =>
                                    tellIndicatorsToSaveTheirUnsaved()
                                }
                                error={fieldValue === "Invalid date"}
                                value={dateval}
                            />
                        </div>
                        {onClear && (
                            <div className="x">
                                <Button
                                    icon="x"
                                    disabled={isInApproval}
                                    onClick={onClear}
                                />
                            </div>
                        )}
                    </div>
                );
                break;
            case "text":
                guts = (
                    <div className="input-wrapper">
                        <div className="input">
                            <TextArea
                                style={{ width: "100%", margin: 0 }}
                                className={`${
                                    isChanged() ? "changed-value" : ""
                                }`}
                                placeholder={`${name || ""}...`}
                                id={id}
                                value={fieldValue}
                                onClick={() =>
                                    tellIndicatorsToSaveTheirUnsaved()
                                }
                                disabled={isInApproval || isConsiderCalculated}
                                onChange={(e) => setFieldValue(e.target.value)}
                            />
                        </div>
                        {onClear && (
                            <div className="x">
                                <Button
                                    icon="x"
                                    disabled={isInApproval}
                                    onClick={onClear}
                                />
                            </div>
                        )}
                    </div>
                );
                break;
            case "numeric":
                guts = (
                    <div className="input-wrapper">
                        <div className="input">
                            <InputNumeric
                                style={{ margin: 0 }}
                                className={`${
                                    isChanged() ? "changed-value" : ""
                                }`}
                                placeholder={`${name || ""}...`}
                                value={fieldValue}
                                id={id}
                                ignored={ignored}
                                disabled={isInApproval || isConsiderCalculated}
                                onChange={(e) => setFieldValue(e.target.value)}
                                onClick={() =>
                                    tellIndicatorsToSaveTheirUnsaved()
                                }
                            />
                        </div>
                        {onClear && (
                            <div className="x">
                                <Button
                                    icon="x"
                                    disabled={isInApproval}
                                    onClick={onClear}
                                />
                            </div>
                        )}
                    </div>
                );
                break;
            default:
                guts = (
                    <div className="input-wrapper">
                        <div className="input">
                            <Input
                                style={{ width: "100%", margin: 0 }}
                                className={`${
                                    isChanged() ? "changed-value" : ""
                                }`}
                                type="text"
                                placeholder={`${name || ""}...`}
                                value={fieldValue}
                                ignored={ignored}
                                id={id}
                                onClick={() =>
                                    tellIndicatorsToSaveTheirUnsaved()
                                }
                                onBlur={() => onSaveClick()}
                                disabled={isInApproval || isConsiderCalculated}
                                onChange={(e) => setFieldValue(e.target.value)}
                            />
                        </div>
                        {onClear && (
                            <div className="x">
                                <Button
                                    icon="x"
                                    disabled={isInApproval}
                                    onClick={onClear}
                                />
                            </div>
                        )}
                    </div>
                );
                break;
        }

    return (
        <Table compact basic="very" style={{ margin: 0 }} celled={false}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell width={3} textAlign="right">
                        {name && (
                            <Label
                                icon={icon}
                                color={color}
                                content={name}
                                size="tiny"
                            />
                        )}
                    </Table.Cell>
                    <Table.Cell
                        className={`inner-input ${
                            onClear ? "prior-entry" : ""
                        }`}
                    >
                        {guts}
                    </Table.Cell>
                    {periodSelect && (
                        <Table.Cell width={6}>
                            {renderTargetTemporalOffsetControls()}
                        </Table.Cell>
                    )}
                    <Table.Cell width={2}>
                        <Button.Group>
                            <Button
                                icon="eraser"
                                size="tiny"
                                title="Clear"
                                onClick={onEraseEntry}
                                disabled={isInApproval}
                                loading={isSaving}
                            />
                            <Button
                                color={color}
                                icon="save"
                                size="tiny"
                                title="Save"
                                onClick={onSaveClick}
                                loading={isSaving}
                                disabled={
                                    isInApproval ||
                                    isConsiderCalculated ||
                                    (frmTargetYear && !frmTargetPeriod) ||
                                    (frmTargetPeriod && !frmTargetYear)
                                }
                                onBlur={() => {
                                    onSaveClick();
                                }}
                            />
                        </Button.Group>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

export default BasicInputField;
