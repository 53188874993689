import "../../../styles/pages/Dashboard.scss";
import React from "react";
import { Segment } from "semantic-ui-react";
import { Switch, Route } from "react-router-dom";

import DashProgramLevel from "./program-level/DashProgramLevel";
import DashInterventionLevel from "./intervention-level/DashInterventionLevel";
import DashCityLevel from "./city-level/DashCityLevel";
import DashTypeLevel from "./type-level/DashTypeLevel";
import DashboardMenu from "./menu/DashboardMenu";

/**********************************************************************/
const Dashboard = () => {
    return (
        <Segment id="Dashboard">
            <DashboardMenu />
            <br />
            <Switch>
                <Route exact path={`/`} component={DashProgramLevel} />
                <Route
                    exact
                    path={`/dashboard/program/:reporting_year?/:reporting_period?`}
                    component={DashProgramLevel}
                />
                <Route
                    exact
                    path={`/dashboard/city/:city_uuid/:reporting_year?/:reporting_period?`}
                    component={DashCityLevel}
                />
                <Route
                    path={`/dashboard/intervention_type/:type_uuid/:reporting_year?/:reporting_period?`}
                    component={DashTypeLevel}
                />
                <Route
                    path={`/dashboard/intervention/:int_uuid`}
                    component={DashInterventionLevel}
                />
            </Switch>
        </Segment>
    );
};

export default Dashboard;
