import React from "react";
import { Modal, Form, Icon } from "semantic-ui-react";

import { Interventions } from "../../../../api";

import { genericResponseHandler } from "../../../../utils";
import { AppContext } from "../../../../state";

const NewInterventionModal = ({ onCreated, onCancel, open, cityUUID }) => {
    const app = React.useContext(AppContext);

    const [intName, setIntName] = React.useState("");
    const [shortDesc, setShortDesc] = React.useState("");
    const [longDesc, setLongDesc] = React.useState("");

    /* ---------------------------------------- */
    const onSubmit = async () => {
        try {
            await Interventions.create(
                intName.trim(),
                cityUUID,
                longDesc.trim(),
                shortDesc.trim()
            );
            onCreated();
            clearOut();
        } catch (err) {
            genericResponseHandler(
                err,
                app,
                {
                    400: "All fields required.",
                },
                "Error saving data"
            );
        }
    };

    const clearOut = async () => {
        setIntName("");
        setShortDesc("");
        setLongDesc("");
    };

    /* ---------------------------------------- */
    return (
        <Modal open={open} onClose={onCancel} onCancel={onCancel} dimmer>
            <Modal.Header>
                <Icon name="plus" color="yellow" />
                Create a New Intervention
            </Modal.Header>
            <Modal.Content>
                <Form onSubmit={onSubmit}>
                    <Form.Input
                        placeholder="Name"
                        label="Name"
                        type="text"
                        value={intName}
                        maxLength={30}
                        onChange={(e) => setIntName(e.target.value)}
                        required
                    />
                    <Form.Input
                        placeholder="Short Description"
                        label="Short Description"
                        type="text"
                        value={shortDesc}
                        onChange={(e) => setShortDesc(e.target.value)}
                        required
                    />
                    <Form.TextArea
                        placeholder="Long Description"
                        label="Long Description"
                        value={longDesc}
                        onChange={(e) => setLongDesc(e.target.value)}
                        required
                    />
                    <Form.Group style={{ float: "right" }}>
                        <Form.Button
                            content="Cancel"
                            negative
                            onClick={() => {
                                clearOut();
                                onCancel();
                            }}
                        />
                        <Form.Button content="Create" type="submit" positive />
                    </Form.Group>
                </Form>
            </Modal.Content>
        </Modal>
    );
};

export default NewInterventionModal;
